import type { TCvItem } from "@/api/types";

export type TCvItemAddPart = {
	cvc_id: number;
	items: Omit<TCvItem, "cve_id" | "cve_public">;
};
export type TCvItemAdd = {
	cvc_id: number;
	items: TCvItemAddPart["items"][];
};
export type TCvItemEdit = Omit<TCvItem, "cve_id"> & {
	cvc_id: number;
	cve_id: number | undefined;
};

export function createUniqueId() {
	return Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.slice(0, 7);
}

export abstract class CTypeItems {
	protected abstract readonly items: CFormItem[];
	protected readonly pendingRemoval: string[] = [];
	protected activeItemHash?: string | null;
	abstract addItem(
		item: TCvItem,
		shouldChangeActive: boolean,
	): (typeof this.items)[number];
	abstract updateItem(obj: TCvItem, uniqueId: string): void;
	removeItem(uniqueId: string) {
		// Doesn't remove items, just adds to pending so we hide them & prepare for BackEnd
		const itemObj = this.items.find((item) => item.uniqueId === uniqueId);
		if (itemObj) {
			this.pendingRemoval.push(itemObj.uniqueId);
		}
	}
	abstract getAllAdded(): TCvItemAdd | null;
	abstract getAllEditted(): TCvItemEdit[];
	getRemovedItemsDb() {
		const tempArr: number[] = [];
		for (const item of this.items) {
			if (this.pendingRemoval.includes(item.uniqueId) && item.id) {
				tempArr.push(item.id);
			}
		}
		return tempArr;
	}
	getActiveFirstItem(): (typeof this.getItems)[number] | null {
		if (this.activeItemHash) {
			return (
				this.getItems.find((item) => item.uniqueId === this.activeItemHash) ||
				null
			);
		}
		return this.getItems[0] || null;
	}
	getInactiveItems(): (typeof this.getItems)[number][] {
		return this.getItems.filter(
			(item) => item.uniqueId !== this.getActiveFirstItem()?.uniqueId,
		);
	}
	changeActiveItem(hashId: string) {
		const isCurrentFormEmpty = this.getActiveFirstItem()?.isEmptyForm;
		const currentlyActiveHash = this.activeItemHash;
		this.activeItemHash = hashId;
		// TODO 3? Fix if length === 1 to not remove last item | Is this a bug???
		if (isCurrentFormEmpty && currentlyActiveHash) {
			this.removeItem(currentlyActiveHash);
		}
	}
	abstract get getItems(): CFormItem[];
}
export abstract class CFormItem {
	id: number | undefined = undefined;
	uniqueId = createUniqueId();
	abstract readonly validations: any;
	abstract updateVal(obj: any): void;
	abstract toDb(): any;
	abstract get isEmptyForm(): boolean;
}
