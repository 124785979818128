<script setup lang="ts">
import type {
	TTableColumn,
	TTableRow,
} from "@/components/shared/table/ModernTable.vue";
import { $t } from "@/i18n";
import api from "@/api";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";

definePage({
	name: "MyApplicationsPage",
});

interface IStatuses {
	aps_id: number;
	aps_name: string;
	aps_public: boolean;
}

const router = useRouter();
const search = ref<string>("");
const date = ref<string>("");
const statuses = ref<IStatuses[]>([
	// {
	// 	aps_id: 0,
	// 	aps_name: "Created",
	// 	aps_public: true,
	// },
]);
const load = ref(false);
const manageCallApi = ref<boolean>(true);

const tempColumns = ref<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.myapplications.tableHeaders.listingName"),
	},
	{
		id: "cmp_name",
		label: $t("app.myapplications.tableHeaders.company"),
	},
	{
		id: "job_expires",
		label: $t("app.myapplications.tableHeaders.expy"),
	},
	{
		id: "aps_id",
		label: $t("app.myapplications.tableHeaders.status"),
	},
	{
		id: "muted",
		label: $t("app.settings.notifications"), // $t("app.myapplications.tableHeaders.send"),
	},
	{
		id: "col5",
		label: "", // $t("app.myapplications.tableHeaders.send"),
	},
	// {
	// 	id: "col6",
	// 	label: "",
	// },
]);
// NOTE - col4 values are a placeholder for now
const rows = ref<TTableRow[]>([]);

const tempOptionsFilter = ref([
	{ label: "Filter", name: "test1" },
	{ label: "Option 1", name: "test3" },
	{ label: "Option 2", name: "test5" },
	{ label: "Option 3", name: "test7" },
]);
const selectedOptionFilter = ref({ label: "Filter", name: "test1" });

// const tempOptionsSort = ref([
// 	{ label: "Sort by", name: "test0" },
// 	{ label: "Newest posted", name: "test1" },
// 	{ label: "Oldest posted", name: "test3" },
// 	{ label: "Expiring soon", name: "test5" },
// 	{ label: "Not expiring soon", name: "test7" },
// ]);
const selectedStatus = ref<IStatuses | null>(null);

function formatDate(date: string) {
	if (date) return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}

// getMyApplications()
async function getMyApplications(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}

	type TParam = {
		limit: number;
		offset: number;
		search?: string;
		date?: string;
		aps_id?: number;
	};
	const params: TParam = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value) {
		params.search = search.value;
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value);
	}
	if (selectedStatus.value) {
		params.aps_id = selectedStatus.value.aps_id;
	}
	try {
		const res = await api.getMyApplications(params);
		res.data.data.map((r) => {
			return (r.muted = r.muted === 1 ? true : false);
		});
		rows.value.push(...res.data.data);
		manageCallApi.value = res.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

async function getApplicationStatuses() {
	try {
		// @ts-expect-error
		const res = await api.getApplicationStatuses();
		console.log(res.data.data);
		statuses.value.push(...res.data.data);
	} catch (e) {
		console.log(e);
	}
}

function deselectStatus() {
	selectedStatus.value = null;
}
async function applicationsMute(e: any, data: TTableRow) {
	console.log(e, data);

	try {
		const res = await api.applicationsMute({ app_id: data.app_id });
		console.log(res.data);
	} catch (e) {
		console.log(e);
	}
}

onMounted(async () => {
	await getMyApplications();
	await getApplicationStatuses();
});
watch([search, date, selectedStatus], async () => {
	// set function to call candidates
	await getMyApplications(true);
});

const debug = (columnId: string, row: Record<string, any>, e: Event) => {
	console.log(columnId, row, e);
	void router.push({
		name: "CandidateData",
		params: { id: row.app_id },
	});
};
</script>

<template>
	<div class="page">
		<div class="list-wrapper">
			<div class="table-header">
				<div class="title-wrap">
					<div class="line"></div>
					<div class="title">
						{{ $t("app.myapplications.tableTitle") }}
					</div>
				</div>
				<div class="input-wrap">
					<search-input
						v-if="false"
						placeholder="Type something..."
						:search-value="search"
						@update:search-value="search = $event"
					/>
					<!-- <input type="date" placeholder="Expiry Date" /> -->
					<modern-date-picker
						v-if="false"
						v-model="date"
						:enable-time-picker="false"
						style="width: 150px"
					/>
					<div v-if="false" class="dropdown location-wrap">
						<multiselect-form
							v-model="selectedOptionFilter"
							:options="tempOptionsFilter"
							label="label"
							value-prop="label"
						/>
					</div>
					<div v-if="statuses" class="dropdown sort-wrap">
						<multiselect-form
							v-model="selectedStatus"
							:options="statuses"
							label="aps_name"
							value-prop="aps_id"
							placeholder="All statuses"
							:can-deselect="true"
							@deselect="deselectStatus"
						/>
					</div>
				</div>
			</div>
			<div class="table-comp">
				<modern-table
					v-if="rows.length > 0"
					:columns="tempColumns"
					:rows="rows"
					:is-infinite-scroll="manageCallApi"
					style="max-height: 700px"
					:has-border="false"
					@scroll-more="getMyApplications"
				>
					<template #table-body-cell="slotProps">
						<td v-if="slotProps.column.id === 'aps_id'">
							<!-- NOTE - class handling is a placeholder for now -->
							<div
								class="table-status btn-s"
								:class="{
									transfer: slotProps.row.aps_id === 'TSRL',
									employment: slotProps.row.aps_id === 'EMPL',
									preselection: slotProps.row.aps_id === 'PRES',
									approved: slotProps.row.aps_id === 'SELE',
									pending:
										slotProps.row.aps_id === 'APPL' ||
										slotProps.row.aps_id === null,
									rejected: slotProps.row.aps_id === 'RJCT',
									draft: slotProps.row.aps_id === 'PREP',
								}"
								style="min-width: 140px; width: 140px"
							>
								{{
									slotProps.row.aps_name ? slotProps.row.aps_name : "Created"
								}}
							</div>
						</td>
						<td v-if="slotProps.column.id === 'muted'">
							<div class="mute">
								<label>{{ $t("app.settings.mute") }}</label>
								<SwitchComp
									v-model="slotProps.row.muted"
									@change="applicationsMute($event, slotProps.row)"
								/>
							</div>
						</td>
						<td v-if="slotProps.column.id === 'job_expires'">
							<!-- NOTE - class handling is a placeholder for now -->
							<div>
								{{ formatDate(slotProps.row[slotProps.column.id]) }}
							</div>
						</td>
						<td v-else-if="slotProps.column.id === 'col5'">
							<button
								style="min-width: 92px"
								class="alt-blue btn-s"
								@click="debug(slotProps.column.id, slotProps.row, $event)"
							>
								View
							</button>
						</td>
						<td v-else-if="slotProps.column.id === 'col6'">
							<div class="options">
								<img src="@/assets/image/dots.png" alt="" />
							</div>
						</td>
					</template>
				</modern-table>
				<div v-else-if="load" class="loader-wrap-intern">
					<div class="loader"></div>
				</div>
				<div v-else class="wrapp-no-fund-data-table">
					<img src="@/assets/image/no_job.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.page {
	// padding: 10px;
	height: 100%;
	background-color: $bg-color1;

	.list-wrapper {
		min-height: 850px;
		min-width: 1220px;
		// border: 1px solid #dcdcdc;
		border-radius: 11px;
		padding: 0 30px;

		.table-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.title-wrap {
				width: max-content;
				.line {
					// height: 5px;
					height: 0;
					width: 100%;
					// background: $gradient-line3;
					box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
					border-radius: 0 0 10px 10px;
				}

				.title {
					font-weight: 700;
					font-size: 16px;
					margin-top: 12px;
				}
			}
			.input-wrap {
				display: flex;
				flex-direction: row;
				gap: 15px;
				padding-top: 12px;

				.dropdown {
					padding: 0 10px;
					border-radius: $input-radius;
					color: $text-color;
					font-size: 14px;
					outline: none;
					background-color: transparent;
					text-indent: 10px;
					border: 1px solid;
					border-color: $input-border-color;
					min-height: $input-height;
					min-width: 150px;
					text-overflow: ellipsis;
				}
			}
		}

		.table-comp {
			margin-top: 25px;

			.info-row {
				box-sizing: border-box;
				max-width: 100%;
				padding: 20px 10px;
			}

			.options {
				cursor: pointer;
			}
		}
	}
	.mute {
		display: flex;
		label {
			margin-right: 20px;
		}
	}
}
.btn-s {
	box-sizing: border-box;
	min-width: 115px !important;
	width: fit-content;
	min-height: 29px !important;
	border-radius: 84px;
	padding: 7px 0;
	font-size: 11px;
	line-height: 15px;
	letter-spacing: 0;
	text-align: center;
	white-space: nowrap;
	text-transform: capitalize;
}

.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
