import {
	getLocalStorageReac,
	getSessionStorageReac,
	parseApiError,
} from "@/assets/js/helpers";
import router from "@/router";
import { useToastStore } from "@/store/toast";
import { useTokenStore } from "@/store/token";
import axios, { type AxiosError, type InternalAxiosRequestConfig } from "axios";

const STATUS_CODE = {
	Unathorized: 401,
	Forbidden: 403,
};

// Handles DEV and PROD
export const baseURL = import.meta.env.VITE_APP_ROOT_API;
const instance = axios.create({
	baseURL,
});
const SHOW_DEBUG = false;
let isBusyLogout = false;

instance.interceptors.request.use((config) => {
	handleRequestInterceptor(config);

	return config;
});
instance.interceptors.response.use(
	(response) => {
		const tokenStore = useTokenStore();
		if (tokenStore.isLoggedIn) {
			void tokenStore.setupTokenRenewal(false, false); // Reset timeout on success
		}
		return response;
	},
	(error) => {
		handleResponseInterceptorError(error);
		return Promise.reject(error);
	},
);

function handleRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
	try {
		const tokenSession = getSessionStorageReac("user-token")?.value as string;
		const tokenLocal = getLocalStorageReac("user-token")?.value as string;
		const tokenData = tokenSession || tokenLocal;
		if (tokenData) {
			config.headers.Authorization = `${tokenData}`;
		}
	} catch (err: any) {
		console.error("ERRor", err.message);
	}
}
function handleResponseInterceptorError(error: AxiosError<any, any>) {
	SHOW_DEBUG && console.warn("ERR", error);
	if (error.response?.status === STATUS_CODE.Unathorized) {
		// Forbidden - Logout and return to main page
		if (!isBusyLogout) {
			isBusyLogout = true;
			void useTokenStore().logoutApi();
			useToastStore().openToastWarning(
				"Session expired. Please login to continue",
			);
			setTimeout(() => {
				isBusyLogout = false;
			}, 1000);
		}
	} else if (error.response?.status === STATUS_CODE.Forbidden) {
		console.warn(error.response);
		const errorMessage = parseApiError(error);
		useToastStore().openToastError(errorMessage || "No privileges");
		void router.replace("/manage/job-listing");
	}
}

export { instance as axios };
