<script lang="ts" setup>
import api from "@/api";
import { useRoute, useRouter } from "vue-router";
import { baseURL } from "@/api/config";
import defImg from "@/assets/image/profile.png";
import { useMessageStore } from "@/store/message";
import linkedin from "@/assets/image/socialMedia/in.svg";
import facebook from "@/assets/image/socialMedia/f.svg";
import instagram from "@/assets/image/socialMedia/insta.svg";
import x from "@/assets/image/socialMedia/x.svg";
// import { useTokenStore } from "@/store/token";

// import type { TCvGet } from "@/api/types";

// import { $t } from "@/i18n";

const route = useRoute();
const router = useRouter();
// const tokenStore = useTokenStore();

definePage({
	name: "PublicProfile",
	path: "/manage/public-profile/:id",
});
//  const props = withDefaults(defineProps<{
// 	data: any;
//     showReport?: boolean;
//     wide?: boolean;
// }>(), {
//     showReport: false,
//     wide: false,
// });

const emit = defineEmits(["report"]);
const socialImg = ref({
	linkedin: linkedin,
	facebook: facebook,
	instagram: instagram,
	x: x,
});

const showCV = ref(false);
const cv1 = ref<any>();
const userData = ref<any>();
const message = useMessageStore();

async function getCvData() {
	console.log(route.params);
	try {
		const help = await api.getCV({ usr_id: route.params.id });
		if (help.data.res == "OK") {
			cv1.value = help.data.data;
			// TODO !!Obavezeno promeniti 'as any' u pravilan tip, kada se sredi integracija APIja
			// cv1.value = help.data.data as TCvGet;
			userData.value = cv1.value.find(
				(obj: any) => obj.cvc_name === "Basic info",
			);
		}
	} catch (e) {
		console.log(e);
	}
}

interface IListCountry {
	cnt_code: string;
	cnt_name: string;
}

const countries = ref<IListCountry[]>([]);

function setCountryName(cnt_code: string) {
	if (cnt_code) {
		const help = countries.value.find(
			(item) => item.cnt_code.toLowerCase() === cnt_code.toLowerCase(),
		);
		if (help) {
			return help.cnt_name;
		}
	}
}

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
		console.log(countries.value);
	} catch (e) {
		console.log(e);
	}
}

function getOldImg(id: any) {
	return baseURL + "/users/images-get/" + id;
}
function sendMessage(user: any) {
	// void router.push("/manage/chat-main/" + id);
	message.setSendMsgTo({ usr_id: user.usr_id, usr_name: user.usr_name });
	void router.push({
		name: "ChatMain",
		params: { id: user.usr_id },
	});
}

onMounted(async () => {
	await getCvData();
	await getCountries();
});
</script>
<template lang="pug">
.user-data(v-if="cv1")
	.main(v-if="!showCV")
		.up
			.wrapp-title
				.line
				.w-t(@click="router.back()")
					.arrow-left
					.title.ml-15 {{ $t("app.candidates.publicProfile") }}
		.main-data
			.wrapp-details
				.wrapp-up-data(v-if="userData")
					.wrapp-img
						.img-container
							img(v-if="userData.img_id", :src="getOldImg(userData.img_id)")
							img(v-else, :src="defImg")

					.title-data
						.group
							label {{ $t("app.candidates.name") }}
							.data {{ userData.usr_name }}
						.group
							label {{ $t("app.candidates.country") }}
							.data {{ setCountryName(userData.cnt_code) }}
						.group
							label {{ $t("app.candidates.profession") }}
							.data {{ userData.usr_position }}
					.title-data
						.group(v-if="userData.usr_socialnetworks")
							label {{ $t("app.candidates.socialMedia") }}
							//- .data {{ userData.usr_socialnetworks ? userData.usr_socialnetworks : " " }}
							.data.social
								a(
									v-for="(val, key, index) in userData.usr_socialnetworks",
									:key="index",
									:href="val",
									target="_blank"
								) 
									img(:src="socialImg[key]")

						.group(v-if="userData?.rol_id === 1 || userData?.rol_id === 8")
							label {{ $t("app.candidates.videoBiography") }}
							a.data(
								:href="userData.usr_attributes?.profile_youtube_link",
								target="_blank"
							) {{ userData.usr_attributes?.profile_youtube_link }}

				.group-data
					label {{ $t("app.candidates.aboutMe") }}
					pre.data {{ userData.usr_aboutme }}
				.group-data(
					v-if="userData.usr_attributes?.profile_youtube_link",
					style="margin-top: 30px"
				)
					//- a.btn(
						:href="userData.usr_attributes?.profile_youtube_link",
						target="_blank"
						) Watch the video
				//- .group-data(v-if="userData.usr_attributes?.profile_youtube_link")
					iframe(
						v-if="userData.usr_attributes?.profile_youtube_link",
						width="360",
						height="290",
						:src="userData.usr_attributes?.profile_youtube_link"
					)
					//- iframe(
						v-else,
						width="360",
						height="290",
						src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1")
			.wrapp-action
				button(
					v-if="userData?.rol_id === 1 || userData?.rol_id === 8",
					@click="showCV = true"
				)
					span {{ $t("app.candidates.showCv") }}
				button(@click="sendMessage(userData)")
					span {{ $t("app.joblisting.sendMessage") }}
				.group-data(v-if="false && userData.usr_attributes?.profile_youtube_link")
					a.btn(
						:href="userData.usr_attributes?.profile_youtube_link",
						target="_blank"
					) Watch the video
					//- iframe(
					//- 	v-if="userData.usr_attributes?.profile_youtube_link",
					//- 	width="360",
					//- 	height="290",
					//- 	:src="userData.usr_attributes?.profile_youtube_link"
					//- )
				.wrapp-portifolio-data
					.section(v-if="userData.usr_attributes?.cve_portfolio_cover_letter")
						.title {{ $t("app.cv.coverLetter") }}
						.data-txt {{ userData.usr_attributes?.cve_portfolio_cover_letter }}
					//- .section(v-if="userData.usr_attributes?.profile_youtube_link")
						.title {{ $t("app.cv.portfolio") }}
						a(:href="userData.usr_attributes?.profile_youtube_link", target="_blank") {{ userData.usr_attributes?.profile_youtube_link }}

					//- file-list(
					//- 	:fileList="fList",
					//- 	title="Documents",
					//- 	:showStatus="true",
					//- 	:showAction="tokenStore.isUserCompany",
					//- 	:showRemove="false",
					//- 	@reject="rejectFile",
					//- 	@approved="approvedFile",
					//- 	@open="open"
					//- )

	.main(v-if="showCV")
		.up
			.wrapp-title
				.line
				.w-t(@click="showCV = false")
					.arrow-left
					.title.ml-15 {{ $t("app.candidates.goBackToProfile") }}
		.wrapp-public-cv
			cv-template(:cv1="cv1")
</template>
<style lang="scss" scoped>
.user-data {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;
		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				display: inline;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}

		.wrapp-up-data {
			display: flex;
			// justify-content: space-between;
			margin-bottom: 50px;
			.group {
				margin-bottom: 20px;
				margin-top: 4px;
				max-width: 200px;
				min-width: 130px;
			}

			.wrapp-img {
				width: fit-content;
				// background-image: url('../../assets/image/default2.png');
				// background-repeat: no-repeat;
				// background-position-y: top;
				display: flex;
				justify-content: center;
				// align-items: flex-end;
				position: relative;

				.img-container {
					position: relative;
					background-image: $gradient-line3;
					padding: 5px;
					border-radius: 50%;
					width: 270px;
					height: 270px;
					img {
						// width: 200px;
						// height: 200px;
						width: 260px;
						height: 260px;
						object-fit: cover;
						overflow: hidden;
						border-radius: 50%;
					}
				}
			}
		}
		.title-data {
			margin-left: 40px;
		}
		.main-data {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.data {
				font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 600;
				line-height: 15px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: 9px;
				min-height: 15px;
			}
			pre.data {
				font-family: "Open Sans", sans-serif;
				// font-size: 13px;
				// font-weight: 600;
				// line-height: 15px;
				// letter-spacing: 0;
				// text-align: left;
				// color: $text-color;
				// margin-top: 9px;
				// min-height: 15px;
				text-wrap: wrap;
				max-width: 100% !important;
			}

			.wrapp-details {
				max-width: 774px;
				width: 75%;
				margin-bottom: 20px;
			}
			.wrapp-action {
				width: 20%;
				button {
					margin-bottom: 19px;
				}
				// .group-data{
				// 	min-height: fit-content;
				// 	min-width: fit-content;
				// }
				.wrapp-portifolio-data {
					.section {
						margin-bottom: 50px;
					}
					.title {
						// font-family: Archivo, sans-serif;
						font-size: 16px;
						font-weight: 700;
						line-height: 17px;
						letter-spacing: 0;
						text-align: left;
						color: $text-color;
						margin-bottom: 30px;
					}
					.data-txt {
						font-family: "Open Sans", sans-serif;
						font-size: 13px;
						font-weight: 600;
						line-height: 18px;
						letter-spacing: 0;
						text-align: left;
					}
				}
			}
		}
		.wrapp-public-cv {
			// border-radius: 10px;
			width: 703px;
			border: $border;
			overflow: hidden;
		}
	}
}
.ml-15 {
	margin-left: 15px;
}
.social {
	display: flex;
	a {
		margin-right: 10px;
	}
}

@media only screen and (max-width: 1260px) {
	.reported-data {
		// set style
	}
}
</style>
