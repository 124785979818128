<script setup lang="ts">
import { useCandidateStore } from "@/store/candidate";
import { useCandidateRouteStore } from "@/store/candidateRoute";

definePage({
	name: "ProfileComputerSkills",
});

const candidateStore = useCandidateStore();
const candidateRouteStore = useCandidateRouteStore();

const formItems = computed(() => candidateStore.formUser.computerSkills);
</script>

<template lang="pug">
.page
	.form-wrapper
		modern-form
			template(#logo)
				.logo-placeholder
			template(#contentInner)
				form-comp.form-inputs
					template(v-for="(form, _index) in formItems.getItems", :key="_index")
						.row
							.group
								label Add skills
								multiselect-form(
									v-model="form.cSkillsArr",
									mode="tags",
									:searchable="true",
									:create-option="true",
									:close-on-select="true",
									:options="form.cSkillsArr",
									:append-to-body="true"
								)
						.row(v-if="candidateRouteStore.isProfilePage")
							.group.checkbox.modern-cbox
								label
									input(
										v-model="form.public",
										type="checkbox",
										:true-value="1",
										:false-value="0"
									)
									span Publicly visible
			template(#action)
				slot
	.right-side
		img(src="@/assets/image/auth/reg/1.png")
		//- input-img(
		//- 	:old-image="candidateStore.profileImageSrc",
		//- 	@set-image="onSetImage",
		//- 	@delete="onDeleteImage"
		//- )
</template>

<style lang="scss" scoped>
.page {
	@include candidate-profile-page;
}
</style>
