import type { TStepItem } from "@/components/shared/wizard/WizardSteps.vue";
import { labelToName } from "./helpers";
import type {
	TDegreeItem,
	TLocationTypeItem,
	TEmploymentTypeItem,
	TLangItem,
} from "@/api/types";
import type { RouteRecordName } from "unplugin-vue-router";

export const TYPE_CATS = {
	basic: 1,
	education: 2,
	experience: 3,
	languages: 4,
	skills: 5,
	"computer-skills": 6,
	hobby: 7, // Not used on backend (about me key),
	porfolio: 8, // Not used sometimes,
} as const;

export const getCategoriesArray = (
	prefix: "" | "Profile" = "",
): TStepItem[] => {
	const payload = [
		{
			id: TYPE_CATS.basic,
			label: "Basic info",
			routeName: `${prefix}BasicInfo` as RouteRecordName,
		},
		{
			id: TYPE_CATS.education,
			label: "Education",
			routeName: `${prefix}Education` as RouteRecordName,
		},
		{
			id: TYPE_CATS.experience,
			label: "Experience",
			routeName: `${prefix}Experience` as RouteRecordName,
		},
		{
			id: TYPE_CATS.languages,
			label: "Languages",
			routeName: `${prefix}Languages` as RouteRecordName,
		},
		{
			id: TYPE_CATS.skills,
			label: "Skills",
			routeName: `${prefix}Skills` as RouteRecordName,
		},
		{
			id: TYPE_CATS["computer-skills"],
			label: "Computer Skills",
			routeName: `${prefix}ComputerSkills` as RouteRecordName,
		},
		{
			id: TYPE_CATS.hobby,
			label: "Hobby",
			routeName: `${prefix}Hobby` as RouteRecordName,
		},
		{
			id: TYPE_CATS.porfolio,
			label: "Recommendation and portfolio",
			routeName: `${prefix}Portfolio` as RouteRecordName,
		},
	];

	return payload.map((item) => {
		const parsedData = {
			name: labelToName(item.label),
		};
		return { ...item, ...parsedData };
	});
};

export const langOptions: TLangItem[] = [
	{
		name: "a1",
		label: "A1 Beginner",
	},
	{
		name: "a2",
		label: "A2 Elementary",
	},
	{
		name: "b1",
		label: "B1 Intermediate",
	},
	{
		name: "b2",
		label: "B2 Upper intermediate",
	},
	{
		name: "c1",
		label: "C1 Advanced",
	},
	{
		name: "c2",
		label: "C2 Proficient",
	},
	{
		name: "native",
		label: "Native",
	},
] as const;
export const degreeOptions: TDegreeItem[] = [
	// {
	// 	name: "bachelor",
	// 	label: "Bachelor's Degree",
	// },
	// {
	// 	name: "master",
	// 	label: "Master's Degree",
	// },
	// {
	// 	name: "doctoral",
	// 	label: "Doctoral Degree",
	// },
	// {
	// 	name: "professional",
	// 	label: "Professional Degrees",
	// },
	// {
	// 	name: "specialized",
	// 	label: "Specialized Degrees",
	// },
	// {
	// 	name: "primary",
	// 	label: "Primary Education",
	// },
	// {
	// 	name: "secondary",
	// 	label: "Secundary Education",
	// },
	// {
	// 	name: "tertiary",
	// 	label: "Tertiary Education",
	// },
	{
		name: "elementary",
		label: "Elementary Education",
	},
	{
		name: "secondary",
		label: "Secondary Education",
	},
	{
		name: "faculty-university",
		label: "Faculty-university",
	},
	{
		name: "master",
		label: "Master",
	},
	{
		name: "doctorate-phd",
		label: "Doctorate-phd",
	},
] as const;
export const employmentTypeOptions: TEmploymentTypeItem[] = [
	{
		name: "full-time",
		label: "Full time",
	},
	{
		name: "part-time",
		label: "Part time",
	},
	{
		name: "contract",
		label: "Contract",
	},
	{
		name: "apprenticeship",
		label: "Apprenticeship",
	},
	{
		name: "traineeship",
		label: "Traineeship",
	},
] as const;
export const locationTypeOptions: TLocationTypeItem[] = [
	{
		name: "on-site",
		label: "On site",
	},
	{
		name: "hybrid",
		label: "Hybrid",
	},
	{
		name: "remote",
		label: "Remote",
	},
] as const;
