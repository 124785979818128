import type {
	TCvItem,
	TLocationTypeItem,
	TEmploymentTypeItem,
} from "@/api/types";
import {
	CFormItem,
	CTypeItems,
	type TCvItemAdd,
	type TCvItemAddPart,
	type TCvItemEdit,
} from "./formTypes";
import {
	TYPE_CATS,
	locationTypeOptions,
	employmentTypeOptions,
} from "../constants";
import { DateInstance } from "../dateHelper";
import { CValidate } from "../validations";

export class CExpTypeItems extends CTypeItems {
	protected readonly items: CTypeItem[] = [];

	constructor(...items: TCvItem[]) {
		super();
		if (items?.length) {
			for (const item of items) {
				this.addItem(item);
			}
		} else {
			this.addItem();
		}
	}
	addItem(item?: TCvItem, shouldChangeActive = true): CTypeItem {
		const itemInstance = new CTypeItem(item);
		let resEmploymentType = employmentTypeOptions.find(
			(obj) => obj.name === item?.cve_attributes?.cve_employment_type,
		);
		let resLocationType = locationTypeOptions.find(
			(obj) => obj.name === item?.cve_attributes?.cve_location_type,
		);
		// Set initial degree for new items
		itemInstance.updateEmploymentType(
			resEmploymentType ?? employmentTypeOptions[0],
		);
		itemInstance.updateLocationType(resLocationType ?? locationTypeOptions[0]);
		this.items.push(itemInstance);
		if (shouldChangeActive) {
			this.activeItemHash = itemInstance.uniqueId;
		}
		return itemInstance;
	}
	updateItem(obj: TCvItem, uniqueId: string) {
		const index = this.items.findIndex((item) => item.uniqueId === uniqueId);
		if (index !== -1) {
			this.items[index].updateVal(obj);
		}
	}
	getAllAdded(): TCvItemAdd | null {
		const tempArr: TCvItemAdd = {
			cvc_id: TYPE_CATS.experience,
			items: [],
		};
		for (const item of this.getItems) {
			if (!item.id) {
				const items = item.toDbAdd().items;
				// if (Object.values(items).some(Boolean)) {
				// 	// Only if all values exist, the object should be included
				// 	tempArr.items.push(items);
				// }
				let check = false;
				for (let key in items) {
					if (
						items[key] &&
						((typeof items[key] === "string" && items[key].length > 0) ||
							typeof items[key] !== "string")
					) {
						check = true;
					} else {
						check = false;
						break;
					}
				}

				if (check) {
					tempArr.items.push(items);
				}
			}
		}
		if (tempArr.items.length) {
			return tempArr;
		}
		return null;
	}
	getAllEditted() {
		const tempArr = [];
		for (const item of this.getItems) {
			if (item.id) {
				tempArr.push(item.toDb());
			}
		}
		return tempArr;
	}

	get getItems() {
		return this.items.filter(
			(item) => !this.pendingRemoval.includes(item.uniqueId),
		);
	}
}
class CTypeItem extends CFormItem {
	public: 0 | 1 = 0;
	title = "";
	employmentTypeObj: TEmploymentTypeItem | null = null;
	companyName = "";
	location = "";
	locationTypeObj: TLocationTypeItem | null = null;
	startDate = "";
	endDate = "";
	ongoing: 0 | 1 = 1;
	desc = "";
	readonly validations = {
		title: CValidate.stringR(),
		employmentType: CValidate.stringR(),
		companyName: CValidate.stringR(),
		location: CValidate.stringR(),
		locationType: CValidate.stringR(),
		startDate: CValidate.stringR(),
		endDate: CValidate.stringR(),
		desc: CValidate.stringR(),
	};

	constructor(obj?: TCvItem) {
		super();
		if (obj) {
			this.updateVal(obj);
		}
	}

	updateVal(obj: TCvItem) {
		this.id = obj.cve_id;
		this.public = obj.cve_public ? 1 : 0;
		this.title = obj.cve_title;
		this.companyName = obj.cve_institution;
		this.location = obj.cve_attributes?.cve_location || "";
		this.startDate = obj.cve_attributes?.cve_start_date || "";
		this.endDate = obj.cve_attributes?.cve_end_date || "";
		this.ongoing = obj.cve_attributes?.cve_ongoing ? 1 : 0;
		this.desc = obj.cve_attributes?.cve_description || "";

		// Dropdowns
		if (obj.cve_attributes?.cve_location_type) {
			const item = locationTypeOptions.find(
				(item) => item.name === obj.cve_attributes?.cve_location_type,
			);
			if (item) {
				this.updateLocationType(item);
			}
		}

		// TODO 1 Backend doesnt support?
		if (obj.cve_attributes?.cve_employment_type) {
			const item = employmentTypeOptions.find(
				(item) => item.name === obj.cve_attributes?.cve_employment_type,
			);
			if (item) {
				this.updateEmploymentType(item);
			}
		}
	}
	updateEmploymentType(obj: TEmploymentTypeItem | null) {
		this.employmentTypeObj = obj;
	}
	get employmentType(): string {
		return this.employmentTypeObj?.name || "";
	}
	updateLocationType(obj: TLocationTypeItem | null) {
		this.locationTypeObj = obj;
	}
	get locationType(): string {
		return this.locationTypeObj?.name || "";
	}

	getIsoShort(date: string) {
		const dIns = DateInstance;
		return dIns.getFormattedDate(date, "YYYY-MM-DD");
	}
	toDbAdd(): TCvItemAddPart {
		const data: TCvItemAddPart = {
			cvc_id: TYPE_CATS.experience,
			items: {
				cve_title: this.title,
				cve_institution: this.companyName,
				cve_attributes: {
					cve_location: this.location,
					cve_location_type: this.locationType,
					cve_employment_type: this.employmentType,
					cve_start_date: this.getIsoShort(this.startDate),
					cve_end_date:
						this.endDate && this.endDate !== ""
							? this.getIsoShort(this.endDate)
							: null,
					cve_ongoing: this.ongoing,
					cve_description: this.desc,
				},
			},
		} as const;

		// if (data.items.cve_attributes?.cve_end_date) {
		// 	data.items.cve_attributes.cve_ongoing = 0;
		// }
		if (data.items.cve_attributes?.cve_ongoing) {
			data.items.cve_attributes.cve_end_date = null;
		}
		if (
			data.items.cve_attributes.cve_end_date === null ||
			data.items.cve_attributes.cve_end_date === ""
		) {
			data.items.cve_attributes.cve_ongoing = 1;
		}
		return data;
	}
	toDb(): TCvItemEdit {
		const data: TCvItemEdit = {
			cvc_id: TYPE_CATS.experience,
			cve_id: this.id,
			cve_title: this.title,
			cve_public: this.public ? 1 : (0 as 0 | 1),
			cve_institution: this.companyName,
			cve_attributes: {
				cve_location: this.location,
				cve_location_type: this.locationType, //this.locationType,
				cve_employment_type: this.employmentType, //  this.employmentType,
				cve_start_date: this.getIsoShort(this.startDate),
				cve_end_date:
					this.endDate && this.endDate !== ""
						? this.getIsoShort(this.endDate)
						: null,
				cve_ongoing: this.ongoing,
				cve_description: this.desc,
			},
		};

		// if (data.cve_attributes?.cve_end_date) {
		// 	data.cve_attributes.cve_ongoing = 0;
		// }
		if (data.cve_attributes?.cve_ongoing) {
			data.cve_attributes.cve_end_date = null;
		}
		if (
			data.cve_attributes.cve_end_date === null ||
			data.cve_attributes.cve_end_date === ""
		) {
			data.cve_attributes.cve_ongoing = 1;
		}
		return data;
	}

	get isEmptyForm(): boolean {
		return !(
			this.title ||
			// this.employmentType ||
			this.companyName ||
			this.startDate
		);
	}
}
