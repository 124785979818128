<script setup lang="ts">
import { $t } from "@/i18n";

definePage({
	name: "userCalendar",
});
</script>

<template lang="pug">
.company-listing
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.companies.homePage") }}
			.wrapp-action
				//- .btn(@click="openModal") 
				//- 	span Add new listing
		div
			CalendarComponent
		div
</template>

<style lang="scss" scoped>
.company-listing {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-filters {
			width: calc(100% - 350px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			.dropdown {
				padding: 0 10px 0 2px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}
		.wrapp-action {
			display: flex;
			align-items: center;
			.btn {
				margin-top: 7px;
			}
		}

		// table
		.up-bottom-section {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 20px;
			.wrapp-filters {
				width: auto;
			}
		}
	}
}

.action {
	max-width: 40px;
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 40px !important;
	position: relative;
	padding-right: 22px;

	span {
		cursor: pointer;
	}

	img {
		cursor: pointer;
		margin-left: 18px;
	}

	.show-reports-data {
		width: 149px;
		min-height: 04px;
		box-sizing: border-box;
		padding: 12px 15px 0;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 1px;
		border-radius: 20px;
		background: $background-color;
		border: 1px solid #dfdfe8;
		// background: #e44e75;

		font-size: 15px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.03em;
		text-align: left;

		& > .close-btn {
			margin: 0 0 auto auto;
			display: flex;
			align-self: center;
			padding: 3px;
			justify-content: flex-end;
			position: absolute;
			top: 5px;
			right: 5px;

			.close-icon {
				$size: 13px;

				height: $size;
				width: $size;
				cursor: pointer;

				path {
					fill: #aeadad;
				}

				&:hover {
					path {
						fill: #ffb4c3;
						transition: all 0.3s ease;
					}
				}
			}
		}

		img {
			cursor: pointer;
			margin-left: 18px;
			position: absolute;
		}

		.wrapp-info {
			margin-bottom: 15px;
		}
		.wrapp-info:hover {
			color: $app-accent-color1;
			cursor: pointer;
		}
	}
}
</style>
<style lang="scss">
.company-listing {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
	.search-wrapper {
		// margin-right: 20px;
		margin-top: 10px;
		width: 20%;
		min-width: 150px !important;
		input {
			min-width: 150px !important;
			max-width: 100% !important;
			width: 100% !important;
		}
	}
	input[type="date"],
	.dropdown {
		margin-top: 10px;
	}
}
</style>
