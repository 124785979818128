<script setup lang="ts">
import { getLocalStorageReac } from "./assets/js/helpers";
import { type Ref } from "vue";
import { useDefaultStore } from "./store";
import { useModalStore } from "./store/modal";
import ModernModal from "./components/shared/modal/ModernModal.vue";
import { useRoute } from "vue-router";
import { useTokenStore } from "./store/token";
import { useSocketStore } from "./store/socket";
import { useMessageStore } from "./store/message";

// import { convertToObject } from "typescript";

const store = useDefaultStore();
const modalStore = useModalStore();
const messageStore = useMessageStore();
const route = useRoute();
const appBaseTitle = import.meta.env.VITE_APP_APP_NAME;

const modernModalRef = ref<null | InstanceType<typeof ModernModal>>(null);
const isAppLoading = ref(true);
const appMetaTitle = computed(() => {
	const name = route.meta.name as string;
	if (name) {
		return `${appBaseTitle} - ${(name || "")?.toString()}`;
	}
	return appBaseTitle;
});

function checkSetAppDarkTheme() {
	const settingReac = getLocalStorageReac(
		"app-color-scheme",
		false,
		"auto",
	) as Ref<any>;
	watch(
		settingReac,
		(isSetting: string) => {
			const prefersDark =
				window.matchMedia &&
				window.matchMedia("(prefers-color-scheme: dark)").matches;
			if (isSetting === "dark" || (prefersDark && isSetting !== "light")) {
				document.documentElement.classList.add("dark");
			} else {
				document.documentElement.classList.remove("dark");
			}
		},
		{ immediate: true },
	);
}

watch(
	() => store.isMobileView,
	(val) => {
		if (val) document.body.classList.add("is-mobile");
		else if (!val) document.body.classList.remove("is-mobile");
	},
);

const socketStore = useSocketStore();
const tokenStore = useTokenStore();
// let first_mode = true;

// set chatbot

const chatTypes = ref({
	company: "995b420c94e04a65bf2450124ab61c63",
	btb: "460270fe39d34f15ab9b3cb488a98af2",
	candidate: "9406f19f3e734d50ac3bfe2df6741b2a",
});

function returnChatBotCode() {
	if (tokenStore.isUserB2B) {
		return chatTypes.value.btb;
	} else if (tokenStore.isUserCompany || tokenStore.isUserPresentCompany) {
		return chatTypes.value.company;
	} else {
		return chatTypes.value.candidate;
	}
}

function createChEl() {
	try {
		const script = document.createElement("script");
		script.src = "https://app.manoloremiddi.com/widget-asset.min.js";
		script.id = "myChatBot";
		script.defer = true;
		document.body.append(script);

		console.log("Widget script loaded successfully.");
	} catch (error) {
		console.error("Error loading widget script:", error);
	}
}

function setChatBot() {
	removeChatBot();
	// try {
	// 	// @ts-expect-error
	// 	window.GPTTConfig = {
	// 		uuid: returnChatBotCode(), // "995b420c94e04a65bf2450124ab61c63",
	// 		domain: "app.manoloremiddi.com",
	// 	};

	// 	console.log("GPTTConfig set successfully.");
	// } catch (error) {
	// 	console.error("Error setting GPTTConfig:", error);
	// }
	// // window.addEventListener("load", createChEl);
	// createChEl();

	try {
		// @ts-ignore
		window.GPTTConfig = {
			uuid: returnChatBotCode(), // "995b420c94e04a65bf2450124ab61c63",
			domain: "app.manoloremiddi.com",
		};

		console.log("GPTTConfig set successfully.");
	} catch (error) {
		console.error("Error setting GPTTConfig:", error);
	}
	// @ts-ignore
	if (window.initChatbot) {
		// chat bot min js file is already loaded

		// this function is from an external library file loaded locally
		// because it threw an error that the IMAGE_STYLES constant was already declared by calling it again.
		// To prevent that, we call in loaded logic. It is possible that it will throw an error if the library is changed.

		// @ts-ignore
		window.initChatbot();
	} else {
		createChEl();
	}
}

function removeChatBot() {
	const el = document.querySelector("#myChatBot");
	const el2 = document.querySelector("#chatbot-widget-window-button");
	const el3 = document.querySelector("#chatbot-widget-window-iframe");

	// el ? document.body.removeChild(el) : "";
	// el2 ? document.body.removeChild(el2) : "";
	// el3 ? document.body.removeChild(el3) : "";

	el ? el.remove() : "";
	el2 ? el2.remove() : "";
	el3 ? el3.remove() : "";

	// @ts-expect-error
	delete window.GPTTConfig;
}

watch(
	() => tokenStore.getTokenString,
	(val) => {
		let intervalId: any = null;
		if (!val) {
			intervalId ? clearInterval(intervalId) : "";
			return;
		}
		// if (first_mode) {
		// 	first_mode = false;
		// 	return;
		// }

		socketStore.setOptions({
			autoReconnect: true,
			retries: 5,
			useLogs: true,
			cb: {
				onMessageData(ws, evt, data) {
					console.log(data);
					messageStore.triggerMessageGetAll(null, data, async () => {});
				},
			},
		});

		socketStore.initSocket();
		intervalId = setInterval(() => {
			socketStore.sendMessage(JSON.stringify({ message: "ping" }));
		}, 60_000); // 1min
	},
);

const canonicalLink = computed(() => {
	const baseUrl = `https://${appBaseTitle}.com`;
	return baseUrl + route.path;
});

async function initAppAuth() {
	isAppLoading.value = true;
	await useTokenStore().loadSessionToken();
	isAppLoading.value = false;
}

function onCloseModalComp() {
	if (modernModalRef.value) {
		modernModalRef.value.onClickClose(null, true);
	} else {
		console.error("No modal instance");
	}
}

watch(
	() => tokenStore.isLoggedIn,
	(val) => {
		console.log(val, "token in app");
		if (!val) {
			removeChatBot();
			return;
		}
		setChatBot();
	},
);

onBeforeMount(() => {
	checkSetAppDarkTheme();
});
onMounted(() => {
	void initAppAuth();
	if (tokenStore.isLoggedIn) {
		setChatBot();
	}
});
onUnmounted(() => {
	console.warn(":: Closing APP - HMR");
});
</script>

<template lang="pug">
teleport(to="head")
	title {{ appMetaTitle }}
	link(rel="canonical", :href="canonicalLink")
spinner-block(
	v-if="isAppLoading",
	background="#0000001a",
	color1="#f6a712",
	color2="black"
)
template(v-else)
	router-view.main-application-class(
		:class="{ 'is-mobile': store.isMobileView }"
	)
enon-browser-detect
modern-modal(
	v-if="modalStore.isGlobalModalVisible",
	:key="modalStore.getModalData('comp')",
	ref="modernModalRef",
	:config="modalStore.getModalData('config', {})",
	:form-style="modalStore.getModalData('formStyle', {})",
	@close-modal="modalStore.isGlobalModalVisible = null"
)
	template(#content="{ slotProps }")
		component(
			:is="modalStore.getModalData('comp')",
			v-bind="slotProps",
			@close-modal="onCloseModalComp"
		)
</template>

<style lang="scss">
@import "./assets/css/base.css";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Anton&family=Bakbak+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap";
body {
	font-family: Inter, sans-serif;
	display: flex;
	background-color: var(--color-background-soft);
	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color-thumb;
		border-radius: 5px;
	}

	&.is-mobile {
		overflow-y: hidden;
	}
}

#app {
	width: 100vw;
	// height: 100%;
	* {
		&::-webkit-scrollbar {
			background-color: $scrollbar-color;
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $scrollbar-color-thumb;
			border-radius: 5px;
		}
	}
	.min-w-315 {
		min-width: 315px;
	}
}
#r-viev {
	background-color: $bg-color1;
}
</style>
