import type { TCvItem, TDegreeItem } from "@/api/types";
import {
	CFormItem,
	CTypeItems,
	type TCvItemAdd,
	type TCvItemAddPart,
	type TCvItemEdit,
} from "./formTypes";
import { TYPE_CATS, degreeOptions } from "../constants";
import { DateInstance } from "../dateHelper";
import { CValidate } from "../validations";

export class CEduTypeItems extends CTypeItems {
	protected readonly items: CTypeItem[] = [];

	constructor(...items: TCvItem[]) {
		super();
		if (items?.length) {
			for (const item of items) {
				this.addItem(item);
			}
		} else {
			this.addItem();
		}
	}
	addItem(item?: TCvItem, shouldChangeActive = true): CTypeItem {
		const itemInstance = new CTypeItem(item);
		// Set initial degree for new items

		let res = degreeOptions.find((obj) => obj.name === item?.cve_title);
		itemInstance.updateDegreeObj(res ?? degreeOptions[0]);
		// itemInstance.updateDegreeObj(degreeOptions[0]);
		this.items.push(itemInstance);
		if (shouldChangeActive) {
			this.activeItemHash = itemInstance.uniqueId;
		}
		return itemInstance;
	}
	updateItem(obj: TCvItem, uniqueId: string) {
		const index = this.items.findIndex((item) => item.uniqueId === uniqueId);
		if (index !== -1) {
			this.items[index].updateVal(obj);
		}
	}
	getAllAdded(): TCvItemAdd | null {
		const tempArr: TCvItemAdd = {
			cvc_id: TYPE_CATS.education,
			items: [],
		};
		for (const item of this.getItems) {
			if (!item.id) {
				const items = item.toDbAdd().items;
				// if (Object.values(items).some(Boolean)) {
				// Only if all values exist, the object should be included
				// tempArr.items.push(items);
				// }
				let check = false;
				for (let key in items) {
					if (
						items[key] &&
						((typeof items[key] === "string" && items[key].length > 0) ||
							typeof items[key] !== "string")
					) {
						check = true;
					} else {
						check = false;
						break;
					}
				}

				if (check) {
					tempArr.items.push(items);
				}
			}
		}
		if (tempArr.items.length) {
			return tempArr;
		}
		return null;
	}
	getAllEditted() {
		const tempArr = [];
		for (const item of this.getItems) {
			if (item.id) {
				tempArr.push(item.toDb());
			}
		}
		return tempArr;
	}

	get getItems() {
		return this.items.filter(
			(item) => !this.pendingRemoval.includes(item.uniqueId),
		);
	}
}
class CTypeItem extends CFormItem {
	public: 0 | 1 = 0;
	degreeObj: TDegreeItem | null = null;
	school = "";
	fieldStudy = "";
	startDate = "";
	endDate = "";
	ongoing: 0 | 1 = 1;
	readonly validations = {
		school: CValidate.stringR(),
		degree: CValidate.stringR(),
		public: CValidate.stringR(),
		// fieldStudy: CValidate.stringR(),
		startDate: CValidate.stringR(),
		endDate: CValidate.stringR(),
		desc: CValidate.stringR(),
	};

	constructor(obj?: TCvItem) {
		super();
		if (obj) {
			this.updateVal(obj);
		}
	}

	updateVal(obj: TCvItem) {
		this.id = obj.cve_id;
		this.public = obj.cve_public ? 1 : 0;
		this.school = obj.cve_institution;
		this.fieldStudy = obj.cve_attributes?.cve_field || "";
		this.startDate = obj.cve_attributes?.cve_start_date || "";
		this.endDate = obj.cve_attributes?.cve_end_date || "";
		this.ongoing = obj.cve_attributes?.cve_ongoing ? 1 : 0;
		// this.desc = obj.cve_attributes?.cve_description || "";

		// Degree dropdown
		if (obj.cve_title) {
			const item = degreeOptions.find((item) => item.name === obj.cve_title);
			if (item) {
				this.updateDegreeObj(item);
			}
		}
	}
	updateDegreeObj(obj: TDegreeItem | null) {
		this.degreeObj = obj;
	}
	get degree(): string {
		return this.degreeObj?.name || "";
	}

	getIsoShort(date: string) {
		const dIns = DateInstance;
		return dIns.getFormattedDate(date, "YYYY-MM-DD");
	}
	toDbAdd(): TCvItemAddPart {
		const data: TCvItemAddPart = {
			cvc_id: TYPE_CATS.education,
			items: {
				cve_title: this.degree,
				cve_institution: this.school,
				cve_attributes: {
					cve_field: this.fieldStudy,
					cve_start_date: this.getIsoShort(this.startDate),
					cve_end_date:
						this.endDate && this.endDate !== ""
							? this.getIsoShort(this.endDate)
							: null,
					cve_ongoing: this.ongoing,
				},
			},
		};

		// if (data.items.cve_attributes?.cve_end_date) {
		// 	data.items.cve_attributes.cve_ongoing = 0;
		// }

		if (data.items.cve_attributes?.cve_ongoing) {
			data.items.cve_attributes.cve_end_date = null;
		}
		if (
			data.items.cve_attributes.cve_end_date === null ||
			data.items.cve_attributes.cve_end_date === ""
		) {
			data.items.cve_attributes.cve_ongoing = 1;
		}
		return data;
	}
	toDb(): TCvItemEdit {
		const data: TCvItemEdit = {
			cvc_id: TYPE_CATS.education,
			cve_id: this.id,
			cve_title: this.degree,
			cve_public: this.public ? 1 : (0 as 0 | 1),
			cve_institution: this.school,
			cve_attributes: {
				cve_field: this.fieldStudy,
				cve_start_date: this.getIsoShort(this.startDate),
				cve_end_date:
					this.endDate && this.endDate !== ""
						? this.getIsoShort(this.endDate)
						: null,

				cve_ongoing: this.ongoing,
				// cve_description: this.desc,
			},
		};

		// if (data.cve_attributes?.cve_end_date) {
		// 	data.cve_attributes.cve_ongoing = 0;
		// }

		if (data.cve_attributes?.cve_ongoing) {
			data.cve_attributes.cve_end_date = null;
		}
		if (
			data.cve_attributes.cve_end_date === null ||
			data.cve_attributes.cve_end_date === ""
		) {
			data.cve_attributes.cve_ongoing = 1;
		}
		return data;
	}

	get isEmptyForm(): boolean {
		// return !(this.degree || this.school || this.fieldStudy || this.startDate);
		return !(this.school || this.fieldStudy || this.startDate);
	}
}
