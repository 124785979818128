<script setup lang="ts">
import { useRouter } from "vue-router";
import { $t } from "@/i18n";
import type { TCompany } from "@/assets/js/types";
import { useTokenStore } from "@/store/token";
import { useToastStore } from "@/store/toast";
import { baseURL } from "@/api/config";
import { openAddNewUser } from "@/components/modals/modalDefinitions";
import api from "@/api";

definePage({
	name: "CompanyProfile",
	// path: "/company/company-profile/:id"
});

interface IListCountry {
	cnt_code: string;
	cnt_name: string;
}

const toastStore = useToastStore();
const router = useRouter();
const tokenStore = useTokenStore();
const isEdit = ref(false);
const company = ref<TCompany | null>(null);
const form = ref<TCompany | null>(null);
const newImage = ref<any>();
const deleteImage = ref(false);
const refreshList = ref(false);

const selectedCountry = ref<IListCountry | null>(null);
const countries = ref<IListCountry[] | []>([]);

async function getCompanySingle(id: number | undefined) {
	if (!id) {
		// router.back();
		// return;
	}
	try {
		const res = await api.getCompanySingle({ cmp_id: id, view: "company" });
		console.log(res.data.data);
		company.value = JSON.parse(JSON.stringify(res.data.data));
		form.value = JSON.parse(JSON.stringify(res.data.data));
		selectedCountry.value = {
			cnt_code: form.value.cnt_code,
			cnt_name: form.value.cnt_name,
		};
	} catch (e) {
		console.log(e);
	}
}

async function checkParams() {
	const fd = new FormData();
	//  @ts-expect-error
	fd.append("cmp_id", form.value?.cmp_id);

	let isEdit = false;
	for (const property in form.value) {
		console.log(form.value[property] + "!==" + company.value[property]);

		if (
			form.value[property] !== company.value[property] &&
			property !== "img_id" &&
			property !== "cnt_code" &&
			property !== "cnt_name"
		) {
			isEdit = true;
			fd.append(property, form.value[property]);
		}
		if (
			property === "cnt_code" &&
			company.value[property] !== selectedCountry.value.cnt_code
		) {
			isEdit = true;
			fd.append(property, selectedCountry.value.cnt_code);
		}
	}

	if (newImage.value?.file) {
		fd.append("image", newImage.value.file);
		isEdit = true;
	} else if (deleteImage.value) {
		// @ts-expect-error
		fd.append("delete_image", 1);
		isEdit = true;
	}
	console.log(isEdit);

	if (isEdit) {
		await save(fd);
	} else {
		toastStore.openToastError("Nothing changed.");
	}
}

async function save(params: any) {
	try {
		const res = await api.editCompany(params);
		console.log(res.status);
		if (res.status === 200) {
			toastStore.openToastSuccess("Successfully edited the data.");
		}
		isEdit.value = false;
		await getCompanySingle(tokenStore.userData?.cmp_id);
	} catch (e) {
		console.log(e);
		toastStore.openToastError("Error");
	}
}

function edit() {
	console.log("edit");
	isEdit.value = !isEdit.value;
}

async function cancel() {
	await getCompanySingle(tokenStore.userData?.cmp_id);
	isEdit.value = false;
}

function addNewEmployee() {
	console.log("addNewEmployee");
	openAddNewUser({ cmpId: tokenStore.userData?.cmp_id }, confirmAdd);
}

function confirmAdd() {
	// refresh list
	refreshList.value = !refreshList.value;
}

function getImg(id: any) {
	return id ? baseURL + "/users/images-get/" + id : undefined;
}
function setImageX(e: any) {
	console.log(e);
	// //   e.file | e.base64
	newImage.value = e;
	deleteImage.value = false;
}

function deleteImgX() {
	console.log("set delete");
	deleteImage.value = true;
	newImage.value = null;
	if (form.value) {
		form.value.img_id = null;
	}

	// if (newImage.value) {
	// 	newImage.value = null;
	// } else {
	// 	deleteImage.value = true;
	//     // @ts-expect-error
	//     form.value?.img_id = null;
	// }
}

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

onMounted(async () => {
	// console.log("company profile");
	// console.log(tokenStore.userData?.cmp_id);
	// console.log("lllllllllllllllllllll");
	await getCompanySingle(tokenStore.userData?.cmp_id);
	await getCountries();
});
</script>
<template lang="pug">
.company-profile-page
	.wrappUp
		.wrapp-title
			.line
			.w-t(@click="router.back()")
				.arrow-left
				.title {{ $t("app.companies.profile") }}
		.wrapp-right
			button(v-if="!isEdit && tokenStore.can('cmp-edit')", @click="edit")
				span {{ $t("app.companies.edit") }}
			button(
				v-if="!isEdit && tokenStore.can('usr-add', 'usr-edit') && !tokenStore.isUserB2B",
				@click="addNewEmployee"
			)
				span {{ $t("app.companies.addNewEmployee") }}
			button(v-if="isEdit", @click="cancel")
				span {{ $t("app.companies.cancel") }}
			button(v-if="isEdit && tokenStore.can('cmp-edit')", @click="checkParams")
				span {{ $t("app.companies.saveChanges") }}
	.wrappData(v-if="company")
		.first
			.wrappCompany
				label.logo {{ $t("app.companies.companyLogo") }}
				.wrapp-data(v-if="!isEdit")
					.wrappImg(v-if="company.img_id")
						img(:src="getImg(company.img_id)")
					.wrappImg(v-else)
						img(src="@/assets/image/company.svg")
					//- .company-title {{ company?.cmp_name }}
					//- .company-title {{ data.cmp_name ? data.cmp_name : namedata.cmp_name }} // namedata doesn't exist
				.wrapp-data(v-else-if="form")
					input-img(
						:oldImage="getImg(form.img_id)",
						:aspectRatio="1 / 1",
						xBackground="#E44E75",
						width="120px",
						height="120px",
						frameWidth="2px",
						imgStyle="width:40px",
						@set-image="setImageX",
						@delete="deleteImgX"
					)
			.wrappDescription
				label {{ $t("app.companies.companyName") }}
				input(v-if="isEdit && form", v-model="form.cmp_name", type="text")
				.description(v-else) {{ company.cmp_name }}
			.wrappDescription.longWrapp
				label {{ $t("app.companies.description") }}
				textarea.long(v-if="isEdit && form", v-model="form.cmp_description")
				.description(v-else) {{ company.cmp_description }}
		.first
			.wrappDescription
				label {{ $t("app.companies.country") }}
				//- input(v-if="isEdit && form", v-model="form.cnt_name", type="text")
				.dropdown(v-if="isEdit && form")
					multiselect-form(
						v-model="selectedCountry",
						:options="countries",
						label="cnt_name",
						value-prop="cnt_code",
						placeholder="All countries"
					)
				.description(v-else) {{ company.cnt_name }}
			.wrappDescription
				label {{ $t("app.companies.registrationNumber") }}
				input(v-if="isEdit && form", v-model="form.cmp_regnumber", type="text")
				.description(v-else) {{ company.cmp_regnumber }}
			.wrappDescription
				label {{ $t("app.companies.email") }}
				input(
					v-if="isEdit && form",
					v-model="form.usr_email",
					type="text",
					disabled
				)
				.description(v-else) {{ company.usr_email }}
			.wrappDescription
				label {{ $t("app.companies.directorOwner") }}
				input(
					v-if="isEdit && form",
					v-model="form.usr_name",
					type="text",
					disabled
				)
				.description(v-else) {{ company.usr_name }}
		.first.last
			.wrappEmployees
				.titleEmp {{ $t("app.companies.employeesAndRoles") }}
			employees-and-Roles(:cmpId="company.cmp_id", :refresh="refreshList")
</template>
<style lang="scss">
.company-profile-page {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;

	label {
		color: $text-color-light;
		font-size: 0.7rem;
		display: inline-block;
	}
	.logo {
		margin-bottom: 15px;
	}
	button {
		max-height: 34px;
	}

	.wrappUp {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		align-items: center;
		width: 100%;
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				display: inline;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-right {
			button {
				margin-left: 20px;
				margin-bottom: 10px;
			}
		}
	}
	// end wrapp up

	.wrappData {
		display: flex;
		flex-wrap: wrap;
		// height: 52px ;
	}
	.first {
		box-sizing: border-box;
		width: 28% !important;
		padding-right: 50px;
		min-width: 250px;

		.wrappCompany {
			.wrapp-data {
				display: flex;
				align-items: center;
				.wrappImg {
					// width: 70px;
					// height: 70px;
					width: 120px;
					height: 120px;
					border-radius: 50%;
					// background: red;background
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					border: 1px solid rgba(0, 0, 0, 0.128);
					margin-right: 44px;
					img {
						// height: 130%;
						height: 100%;
						object-fit: cover;
					}
				}
				.company-title {
					font-family: "Open Sans", sans-serif;
					font-size: 16px;
					font-weight: 700;
					line-height: 22px;
					letter-spacing: 0;
					text-align: left;
				}
			}
		}
		.wrappDescription {
			margin-top: 20px;
			margin-bottom: 26px;
			display: flex;
			flex-direction: column;
			height: 52px;
			.long {
				// height: 495px;
				height: 395px;
			}
		}
		.longWrapp {
			min-height: 395px;
			.long {
				// height: 495px;
				height: 395px;
			}
		}
		.wrappEmployees {
			.titleEmp {
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				margin-bottom: 26px;
			}
		}
	}
	.last {
		padding-right: 0;
		// width: 39% !important;
		width: 44% !important;
	}
	.dropdown {
		padding: 0 10px;
		border-radius: $input-radius;
		// color: $text-color;
		// font-size: 14px;
		outline: none;
		// background-color: transparent;
		// text-indent: 10px;
		border: 1px solid;
		border-color: $input-border-color;
		height: $input-height;
		min-width: 150px;
		width: 100%;
		align-items: center;
		// text-overflow: ellipsis;
	}
}
</style>
