<script setup lang="ts">
import api from "@/api";
import { useCaptchaComposable } from "@/components/shared/recaptcha/recaptcha";
import { useToastStore } from "@/store/toast";
import { useRoute } from "vue-router";
import type { ComponentPublicInstance } from "vue";
import { CValidate } from "@/assets/js/validations";
import { useDefaultStore } from "@/store";

definePage({
	name: "ResetPass",
});

const emit = defineEmits(["click-back"]);
const { recaptcha } = useCaptchaComposable();
const toastStore = useToastStore();
const store = useDefaultStore();
const route = useRoute();

const form = reactive({ password: "" });
const resetPassInputRef = ref<ComponentPublicInstance | null>(null);
const passwordRules = CValidate.passwordRAllCombo(8);
const isShowPass = ref(false);

async function clickResetPass() {
	const google_token = await recaptcha("resetPass");

	// const google_token = store.tokenCaptcha;
	const params = {
		token: route.query.token as string,
		password: form.password,
		google_token,
	};
	store.refreshToken = !store.refreshToken;
	try {
		const res = await api.authResetPass(params);
		console.warn(res);
		toastStore.openToastSuccess(
			"Password reset success. You can now proceed to login",
		);
	} catch (err: any) {
		toastStore.openToastError("Failed to reset password");
		console.error(err.message);
	}
}

onMounted(() => {
	setTimeout(() => {
		resetPassInputRef.value?.$el.querySelector("input")?.focus();
	}, 400);
});
</script>

<template lang="pug">
.reset-pass
	modern-form
		template(#content)
			label
				span Enter your new password below.
			.form
				form-comp.row
					.group
						eye-button(@toggled="isShowPass = $event")
							field-comp(
								ref="resetPassInputRef",
								v-model="form.password",
								:type="isShowPass ? 'text' : 'password'",
								name="password",
								:rules="passwordRules",
								autocomplete="one-time-code",
								placeholder="New Password",
								@keyup.enter="clickResetPass"
							)
						error-message.error-msg(name="password")
				//- span You'll receive an email with instructions to reset your password.Please check your spam folder.
				//- turn-stile(
					:resetToken="store.refreshToken",
					@token-change="store.setCaptchaToken"
					)
				button(@click="clickResetPass")
					span Save password
</template>

<style lang="scss">
.reset-pass {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	.logo-wrapper {
		padding-top: 20px !important;
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 30px;
		max-width: 350px;
		width: 350px;
		padding: 0 20px 20px;
	}
}
</style>
