import messages from "@intlify/unplugin-vue-i18n/messages";
import { createI18n } from "vue-i18n";

export const i18n: any = createI18n({
	locale: import.meta.env.VITE_APP_I18N_LOCALE || "sr",
	fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "sr",
	messages,
});

export function $t(key = "", multiCount: any = 0) {
	return multiCount ? i18n.global.t(key, multiCount) : i18n.global.t(key);
}
