<script setup lang="ts">
definePage({
	name: "AuthPage",
	redirect: "/auth/login",
});
</script>

<template lang="pug">
#auth
	header-bar
	router-view.auth-views(v-slot="{ Component }")
		transition(name="fade", mode="out-in")
			component(:is="Component")
</template>

<style lang="scss" scoped>
#auth {
	display: flex;
	flex-direction: column;
	height: 100%;
	// background-color: $bg-color1;
	background-color: $background-soft-color;

	&::before {
		content: " ";
		display: block;
		position: fixed;
		inset: 0;
		opacity: 1;
		// background-image: url("@/assets/image/bg1.png");
		background-repeat: no-repeat;
		background-size: cover;
		filter: blur(5px);

		@media (prefers-color-scheme: dark) {
			opacity: 0.05;
		}
	}

	.auth-views {
		padding: 10px 4% 20px;
		max-width: 1500px;
		align-self: center;
		z-index: 1;
	}

	@include fade-transition(0.1s);

	@media screen and (width >= 768px) {
		.auth-views {
			padding: 80px 4% 20px;
		}
	}
}
</style>
