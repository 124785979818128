<script setup lang="ts">
import { type TRegisterEntity } from "@/assets/js/types";
import { useDefaultStore } from "@/store";

export type TOptionAgencyType = {
	name: TRegisterEntity;
	title: string;
	text: string;
};

definePage({
	name: "RegisterComp",
});

const emit = defineEmits(["back", "next"]);

const store = useDefaultStore();
const options = ref<TOptionAgencyType[]>([
	{
		name: "agency",
		title: "Register employment agency",
		text: "Lorem ipsum dolor sit amet consectetur. Penatibus nisi odio laoreet donec massa.",
	},
	{
		name: "b2b",
		title: "Register B2B entity",
		text: "Lorem ipsum dolor sit amet consectetur. Penatibus nisi odio laoreet donec massa.",
	},
	{
		name: "candidate",
		title: "Register candidate",
		text: "Lorem ipsum dolor sit amet consectetur. Penatibus nisi odio laoreet donec massa.",
	},
]);
const selection = computed<TOptionAgencyType["name"] | "">(
	() => store.registerForm.agencyType,
);
const isDisabledAction = computed(() => !selection.value);

async function onClickNext() {
	await store.changeWizardPage(1);
	emit("next");
}
</script>

<template lang="pug">
.register
	.image-section
		//- .title {{ $t("app.auth.loginImageTitle") }}
		//- .desc {{ $t("app.auth.loginImageDesc") }}
		img(alt="placeholder-image", src="@/assets/image/auth/registration.png")
	modern-form
		template(#logo)
			img.logo(src="@/assets/image/logoModal.svg", draggable="false")
		template(#content)
			.form-title
				span {{ $t("app.auth.registerTitle") }}
			.option(
				v-for="option in options",
				:key="option.name",
				:class="{ selected: selection === option.name }",
				@click="store.registerForm.agencyType = option.name"
			)
				.title {{ option.title }}
				//- .text {{ option.text }}
				.modern-rbox
					input(type="radio", :checked="selection === option.name")
			recaptcha-text.w-72
			.action
				back-next-btns(
					:is-disabled="isDisabledAction",
					:can-show-back="false",
					@next="onClickNext"
				)
</template>

<style lang="scss" scoped>
.register {
	width: 100%;

	.image-section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;

		.title {
			// font-family: Anton, sans-serif;
			font-size: 60px;
			font-style: normal;
			font-weight: 400;
		}

		.desc {
			font-family: "Open Sans", sans-serif;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		img {
			width: 100%;
			max-width: 900px;
		}
	}

	.form {
		background: $background-color;
		padding: 60px 0 30px;
		box-shadow: 0 5px 15px 0 #00000008;

		:deep() {
			.logo-wrapper {
				// gap: 40px;

				.logo {
					width: 80px;
					height: 81px;
					margin: auto;
				}
			}
		}

		.form-title {
			// font-weight: bold;
			font-size: 1.3rem;
			margin: 20px 20px 10px;
		}

		.option {
			display: flex;
			flex-direction: column;
			border: 1px solid $border-color1;
			border-radius: 50px;
			padding: 5px 50px 5px 40px;
			max-width: 320px;
			transition: 0.3s border-color ease;
			// min-width: 320px;
			width: 100%;
			position: relative;
			min-height: 70px;
			justify-content: center;
			margin: 0 40px;
			cursor: pointer;

			.title {
				font-size: 0.9rem;
				font-weight: bold;
				transition: 0.3s color ease;
			}

			.text {
				font-size: 0.7rem;
				transition: 0.3s color ease;
				visibility: collapse;
			}

			.modern-rbox {
				position: absolute;
				right: 20px;
				top: 0;
				bottom: 0;
				pointer-events: none;
				margin: auto;
				height: fit-content;
			}

			&.selected {
				border-color: $app-accent-color1;

				.title {
					color: $app-accent-color1;
				}
				.text {
					color: $app-accent-color1;
				}
			}
		}

		.action {
			justify-content: center;
			margin: auto 40px 30px;
		}
	}

	@media screen and (width >= 768px) {
		.form {
			.option {
				min-width: 320px;
			}
		}
	}
}
</style>
