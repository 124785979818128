import type { TCountryItem, TUserEdit, TUsersProfileGet } from "@/api/types";
import { CFormItem } from "./formTypes";
import { DateInstance } from "../dateHelper";
import { CValidate } from "../validations";

type TProfileBasicUpdate = Omit<TUsersProfileGet, "usr_id"> & {
	usr_id?: number;
	password?: string;
};
type TProfileBasicClassForm = Omit<Required<TUserEdit>, "image">;

export class CBasicType extends CFormItem {
	protected _userId?: number | null = null;
	protected _fullName = "";
	protected _dateOfBirth: string | undefined = "";
	protected _address = "";
	protected _countryObj: Partial<TCountryItem> | null = null;
	protected _email = "";
	protected _phoneNumber = "";
	protected _socialNetworks = "";
	protected _employmentLength?: number = 30;
	protected _aboutMe = "";
	protected _position = ""; // TEST121 TODO Needs to see what to with this paremeter, usr_position maybe is "exp.title"
	protected _password?: string | null = "";
	protected _imgId: number = 0;
	protected _usr_attributes?: TProfileBasicUpdate["usr_attributes"] = undefined;
	protected _company: {
		id?: string | number;
		name?: string;
	} = {
		id: undefined,
		name: undefined,
	};
	readonly validations = {
		fullName: CValidate.stringR(),
		address: CValidate.stringR(),
		email: CValidate.emailR(),
		// phoneNumber: CValidate.numberR(),
		phoneNumber: CValidate.stringR(),
		companyName: CValidate.stringR(),
		aboutMe: CValidate.stringR(),
	};
	touchedKeys = new Set();
	logTouchedKeys = false;

	constructor(obj?: TProfileBasicUpdate) {
		super();
		if (obj) {
			this.updateVal(obj);
		}
	}

	updateVal(obj: TProfileBasicUpdate) {
		this.userId = obj.usr_id;
		this.fullName = obj.usr_name;
		this.dateOfBirth = obj.usr_dateofbirth;
		this.address = obj.usr_address;
		// this.countryCode = obj.cnt_code ? { cnt_code: obj.cnt_code } : null;
		this.email = obj.usr_email;
		this.phoneNumber = obj.usr_phone ?? "";
		this.socialNetworks = obj.usr_socialnetworks;
		this.employmentLength = obj.usr_employmentlength;
		this.aboutMe = obj.usr_aboutme ?? "";
		this.position = obj.usr_position;
		this.password = obj.password;
		this.imgId = obj.img_id || 0;
		this._company = {
			id: obj.cmp_id,
			name: obj.cmp_name,
		};

		if (obj.usr_attributes) {
			if (typeof obj.usr_attributes === "string") {
				try {
					this._usr_attributes = JSON.parse(obj.usr_attributes);
				} catch {}
			} else {
				this._usr_attributes = obj.usr_attributes;
			}
		}
	}
	updateCountry(obj: TCountryItem | null) {
		this.countryObj = obj;
	}

	mapKeysForDb(payload: Record<string, any>) {
		const map: Record<string, string> = {
			userId: "usr_id",
			fullName: "usr_name",
			dateOfBirth: "usr_dateofbirth",
			position: "usr_position",
			email: "usr_email",
			countryCode: "cnt_code",
			aboutMe: "usr_aboutme",
			employmentLength: "usr_employmentlength",
			address: "usr_address",
			phoneNumber: "usr_phone",
			socialNetworks: "usr_socialnetworks",
			password: "usr_password",
			companyId: "cmp_id",
			companyName: "cmp_name",
			usr_attributes: "usr_attributes",
		};
		const data: Record<string, any> = {};
		const keys = Object.keys(payload);
		for (const key of keys) {
			data[map[key]] = payload[key];
		}

		return data;
	}

	getIsoShort(date: string) {
		const dIns = DateInstance;
		return dIns.getFormattedDate(date, "YYYY-MM-DD");
	}

	toDb(includeKeys: string[] = []): TProfileBasicClassForm {
		const data: Record<string, any> = {
			userId: this.userId,
			fullName: this.fullName,
			position: this.position,
			email: this.email,
			countryCode: this.countryObj?.cnt_code?.toLowerCase(),
			aboutMe: this.aboutMe,
			employmentLength: this.employmentLength,
			address: this.address,
			phoneNumber: this.phoneNumber,
			socialNetworks: this.socialNetworks,
			companyId: this.companyId,
			companyName: this.companyName,
			usr_attributes: this._usr_attributes,
		};

		if (this.dateOfBirth) {
			// data.dateOfBirth = this.getIsoShort(this.dateOfBirth);
			// the code above always returned a day less

			// for now it is set like this because it is not converted when it is received from the backend
			data.dateOfBirth = DateInstance.getFormatDTLocale(
				this.dateOfBirth,
				"YYYY-MM-DD",
			);
		}
		if (this.password) {
			data.password = this.password;
		}

		const dataFiltered = includeKeys.length
			? Object.fromEntries(
					Object.entries(data).filter((item) => this.touchedKeys.has(item[0])),
				)
			: data;
		const dataMapped = this.mapKeysForDb(dataFiltered);
		return dataMapped as TProfileBasicClassForm;
	}

	updateTouchedKey(key: string) {
		if (this.logTouchedKeys) {
			this.touchedKeys.add(key);
		}
	}
	clearTouchedKeys() {
		this.touchedKeys.clear();
	}
	toDbTouchedKeys(): Partial<TProfileBasicClassForm> {
		if (this.touchedKeys.size) {
			const filteredData = this.toDb(Array.from(this.touchedKeys) as string[]);
			this.clearTouchedKeys();
			return filteredData as Partial<TProfileBasicClassForm>;
		}
		return {};
	}

	// #region get/set
	get userId(): number | null | undefined {
		return this._userId;
	}
	set userId(val: number | null | undefined) {
		this.updateTouchedKey("userId");
		this._userId = val;
	}
	get fullName() {
		return this._fullName;
	}
	set fullName(val: string) {
		this.updateTouchedKey("fullName");
		this._fullName = val;
	}
	get dateOfBirth() {
		return this._dateOfBirth;
	}
	set dateOfBirth(val: string | undefined) {
		this.updateTouchedKey("dateOfBirth");
		this._dateOfBirth = val;
	}
	get address() {
		return this._address;
	}
	set address(val: string) {
		this.updateTouchedKey("address");
		this._address = val;
	}
	get countryObj(): Partial<TCountryItem> | null {
		return this._countryObj;
	}
	set countryObj(val: Partial<TCountryItem> | null) {
		this.updateTouchedKey("countryCode");
		this._countryObj = val;
	}
	get email() {
		return this._email;
	}
	set email(val: string) {
		this.updateTouchedKey("email");
		this._email = val;
	}
	get phoneNumber() {
		return this._phoneNumber;
	}
	set phoneNumber(val: string) {
		this.updateTouchedKey("phoneNumber");
		this._phoneNumber = val;
	}
	get socialNetworks() {
		return this._socialNetworks;
	}
	set socialNetworks(val: string) {
		this.updateTouchedKey("socialNetworks");
		this._socialNetworks = val;
	}
	get employmentLength() {
		return this._employmentLength;
	}
	set employmentLength(val: number | undefined) {
		this.updateTouchedKey("employmentLength");
		this._employmentLength = val;
	}
	get aboutMe() {
		return this._aboutMe;
	}
	set aboutMe(val: string) {
		this.updateTouchedKey("aboutMe");
		this._aboutMe = val;
	}
	get position() {
		return this._position;
	}
	set position(val: string) {
		this.updateTouchedKey("position");
		this._position = val;
	}
	get password() {
		return this._password;
	}
	set password(val: string | null | undefined) {
		this.updateTouchedKey("password");
		this._password = val;
	}
	get imgId(): number {
		return this._imgId;
	}
	set imgId(val: number) {
		this.updateTouchedKey("imgId");
		this._imgId = val;
	}
	get companyId() {
		return this._company.id;
	}
	set companyId(val: string | number | undefined) {
		this.updateTouchedKey("companyId");
		this._company.id = val;
	}
	get companyName() {
		return this._company.name;
	}
	set companyName(val: string | undefined) {
		this.updateTouchedKey("companyName");
		this._company.name = val;
	}
	// get attributes(): typeof this._usr_attributes {
	// 	return this._usr_attributes;
	// }
	getAttrs(key: keyof NonNullable<TProfileBasicUpdate["usr_attributes"]>) {
		if (this._usr_attributes && key) {
			return this._usr_attributes[key];
		}
		return null;
	}
	setAttrs(key: string, value?: any) {
		this.updateTouchedKey("usr_attributes");
		if (key) {
			if (!this._usr_attributes) {
				this._usr_attributes = {};
			}
			Object.assign(this._usr_attributes, { [key]: value });
			// this._usr_attributes = { [key]: value }; // To clear whole obj
		} else {
			this._usr_attributes = undefined;
		}
	}
	// #endregion

	get isEmptyForm() {
		return !this.touchedKeys.size;
	}
}
