<script setup lang="ts">
// import type { TStepItem } from "@/components/shared/wizard/WizardSteps.vue";
import { useTokenStore } from "@/store/token";
import { useRouter, RouterView } from "vue-router";

definePage({
	name: "SettingsPage",
	redirect: {
		name: "SettingsAboutMe",
	},
});

const router = useRouter();
// const route = useRoute();
const tokenStore = useTokenStore();

if (tokenStore.isCandidate) {
	router.push({
		name: "SettingsInformation",
	});
} else if (tokenStore.isLoggedIn && !tokenStore.isCandidate) {
	router.push({
		name: "SettingsAboutMe",
	});
}
// type TCats = {
// 	id: number;
// };

// const stepList: (TStepItem & { permissions?: string[] })[] = [
// 	{
// 		name: "about_me",
// 		label: "About me",
// 		routeName: "SettingsAboutMe",
// 		permissions: ["company"],
// 	},
// {
// 	name: "cv",
// 	label: "CV",
// 	routeName: "ProfilePage",
// 	// routeName: "SettingsCv",
// 	permissions: ["user"],
// },
// {
// 	name: "social_media",
// 	label: "Social media",
// 	routeName: "SettingsSocial",
// },
// {
// 	name: "information",
// 	label: "Information",
// 	routeName: "SettingsInformation",
// },
// {
// 	name: "notification",
// 	label: "Notifications",
// 	routeName: "SettingsNotifications",
// },
// ] as const;

// const stepListFiltered = computed<TStepItem[]>(() => {
// 	return stepList.filter((step) => {
// 		const check = (...str: string[]) => true;
// 		// const check = useTokenStore().can;
// 		if ((step.permissions && check(...step.permissions)) || !step.permissions) {
// 			return true;
// 		}
// 		return false;
// 	});
// });

// const settingsPageRoutes = ref<TStepItem[]>([]);

// watch(
// 	() => route.name,
// 	(val) => {
// 		setupActiveWizardPage();
// 	},
// );

// async function onChangeStep(step: TStepItem) {
// 	settingsPageRoutes.value = [step];

// 	await router.push({
// 		name: step.routeName as any,
// 	});
// }
// function setupActiveWizardPage() {
// 	const routeName = route.name;
// 	if (routeName) {
// 		const stepItem = stepList.find((step) => step.routeName === routeName);
// 		if (stepItem) {
// 			settingsPageRoutes.value = [stepItem];
// 		}
// 	}
// }

// onMounted(() => {
// setupActiveWizardPage();
// });
</script>

<template lang="pug">
.settings-page
	.header
		.header-label
			.header-bar
			span Settings
	router-view
	//- .content(v-else)
		//- .category(v-for="category in categories", :key="category.id")
			span {{ category }}
		.user-steps
			wizard-steps(
				v-if="stepList",
				:is-radio-box="true",
				:steps="stepListFiltered",
				:is-interactable="true",
				:wrapper-styles="{ fontSize: '1rem' }",
				:separator-styles="{ height: '30px', width: '1px' }",
				:selected-steps="settingsPageRoutes",
				@change-step="onChangeStep"
			)
		.mid-section
			router-view(v-slot="{ Component }")
				component(:is="Component")
</template>

<style lang="scss" scoped>
.settings-page {
	// all with side menu 1489
	max-width: 1224px;
	width: 100%;
	display: flex;
	// justify-content: space-between;
	// padding-left: 50px;
	// padding-left: 20px;
	padding: 0 30px 30px;
	flex: 1;
	flex-direction: column;
	border-radius: 10px;
	// border: $border;
	background-color: $bg-color1;

	.header {
		align-self: baseline;
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		width: 100%;

		// text
		font-family: "Open Sans", sans-serif;
		font-size: 16px;
		font-weight: 700;
		line-height: 21.79px;
		text-align: left;
		color: $text-color;

		.header-label {
			display: flex;
			flex-direction: column;

			.header-bar {
				// background: $gradient-line3;
				height: 5px;
				border-radius: 0 0 10px 10px;
				position: relative;
				margin-bottom: 5px;

				&::before {
					// content: "";
					// position: absolute;
					// top: 0;
					// height: 5px;
					// background: $gradient-line3;
					// border-radius: 0 0 10px 10px;
				}

				&:not(&.animate) {
					&::before {
						width: 100%;
					}
				}

				&.animate {
					@keyframes width-line {
						0% {
							width: 0;
						}
						100% {
							width: 100%;
						}
					}

					&::before {
						animation: width-line;
						animation-duration: 0.5s;
						animation-fill-mode: forwards;
						animation-timing-function: ease-out;
					}
				}
			}
		}

		.export-cv {
			width: 150px;
			margin: 10px 0 0;
		}
	}

	.content {
		display: flex;

		.mid-section {
			display: flex;
			padding: 0 10px 0 40px;
		}
	}

	a {
		text-decoration: none;
	}
}
</style>
