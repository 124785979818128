// import { useTokenStore } from "@/store/token";
import { createRouter, createWebHistory } from "vue-router/auto";

const router = createRouter({
	history: createWebHistory(),
});

router.beforeEach((to, from) => {
	// TODO 2 Rework this in future and use route meta props
	// Forbid going to auth pages if user is logged in
	// const authPage = "AuthPage";
	// if (to.matched.some((match) => match.name === authPage)) {
	// 	const isLoggedIn = useTokenStore().isLoggedIn;
	// 	if (isLoggedIn) {
	// 		return false;
	// 	}
	// }
	return true;
});

export default router;
