import { toTypedSchema } from "@vee-validate/zod";
import type { TypedSchema } from "vee-validate";
import z from "zod";
import type { PartialDeep } from "type-fest";

const validationText = {
	required: "This field is required",
	minimum: "Requires minimum number of characters",
	number: "Not a valid number",
	email: "Not a valid email",
	uppercaseOne: "Requires one uppercase character",
	lowercaseOne: "Requires one lowercase character",
	numberOne: "Requires one number",
	passwordMatch: "Passwords do not match",
} as const;

function requiredText(min = 1) {
	return z.string().min(min, minText(min));
}
function requiredNumber(min = 1) {
	return z
		.number({
			invalid_type_error: validationText.number,
		})
		.min(min, minText(min));
}
function requiredMail(min = 1) {
	return z.string().min(min, minText(min)).email(validationText.email);
}
function requiredPass(min = 1) {
	return z
		.string()
		.min(min, minText(min))
		.regex(/.*[A-Z].*/, validationText.uppercaseOne)
		.regex(/.*[a-z].*/, validationText.lowercaseOne)
		.regex(/.*\d.*/, validationText.numberOne);
}
function passwordMatch(min = 1) {
	return z
		.object({
			password: requiredPass(min),
			passwordConfirm: requiredPass(min),
		})
		.superRefine(({ passwordConfirm, password }, ctx) => {
			if (passwordConfirm !== password) {
				ctx.addIssue({
					code: "custom",
					path: ["passwordConfirm"],
					message: validationText.passwordMatch,
				});
			}
		});
}

function passwordRMatchAllCombo(min = 1): TypedSchema<
	PartialDeep<{
		password: string;
		passwordConfirm: string;
	}>,
	{
		password: string;
		passwordConfirm: string;
	}
> {
	return toTypedSchema(passwordMatch(min));
}

export const CValidate = {
	/**
	 * string().min(1)
	 */
	stringR(min = 1) {
		return toTypedSchema(requiredText(min));
	},
	/**
	 * number().min(1)
	 */
	numberR(min = 1) {
		return toTypedSchema(requiredNumber(min));
	},
	/**
	 * string().min(1).email()
	 */
	emailR(min = 1) {
		return toTypedSchema(requiredMail(min));
	},
	/**
	 * string().min(1).regex()
	 */
	passwordRAllCombo(min = 1) {
		return toTypedSchema(requiredPass(min));
	},
	passwordRMatchAllCombo,
};

function minText(min: number) {
	return min === 1
		? validationText.required
		: `${validationText.minimum} (${min})`;
}
