import type { TCvItem } from "@/api/types";
import {
	CFormItem,
	CTypeItems,
	type TCvItemAdd,
	type TCvItemAddPart,
	type TCvItemEdit,
} from "./formTypes";
import { TYPE_CATS } from "../constants";
import { CValidate } from "../validations";

export class CCSkillTypeItems extends CTypeItems {
	protected readonly items: CTypeItem[] = [];

	constructor(...items: TCvItem[]) {
		super();
		if (items?.length) {
			for (const item of items) {
				this.addItem(item);
			}
		} else {
			this.addItem();
		}
	}
	addItem(item?: TCvItem, shouldChangeActive = true): CTypeItem {
		const itemInstance = new CTypeItem(item);
		this.items.push(itemInstance);
		if (shouldChangeActive) {
			this.activeItemHash = itemInstance.uniqueId;
		}
		return itemInstance;
	}
	updateItem(obj: TCvItem, uniqueId: string) {
		const index = this.items.findIndex((item) => item.uniqueId === uniqueId);
		if (index !== -1) {
			this.items[index].updateVal(obj);
		}
	}
	getAllAdded(): TCvItemAdd | null {
		const tempArr: TCvItemAdd = {
			cvc_id: TYPE_CATS["computer-skills"],
			items: [],
		};
		for (const item of this.getItems) {
			if (!item.id) {
				const items = item.toDbAdd().items;
				if (Object.values(items).some(Boolean)) {
					// Only if all values exist, the object should be included
					tempArr.items.push(items);
				}
			}
		}
		if (tempArr.items.length) {
			return tempArr;
		}
		return null;
	}
	getAllEditted() {
		const tempArr = [];
		for (const item of this.getItems) {
			if (item.id) {
				tempArr.push(item.toDb());
			}
		}
		return tempArr;
	}

	get getItems() {
		return this.items.filter(
			(item) => !this.pendingRemoval.includes(item.uniqueId),
		);
	}
}
class CTypeItem extends CFormItem {
	public: 0 | 1 = 0;
	cSkillsArr: string[] = [];
	readonly validations = {
		computerSkills: CValidate.stringR(),
	};

	constructor(obj?: TCvItem) {
		super();
		if (obj) {
			this.updateVal(obj);
		}
	}

	updateVal(obj: TCvItem) {
		this.id = obj.cve_id;
		this.public = obj.cve_public ? 1 : 0;

		this.updateSkills(this.parseArray(obj.cve_title));
	}
	updateSkills(tags: string[]) {
		this.cSkillsArr = tags;
	}
	parseArray(val?: string | null) {
		if (typeof val === "string") {
			return val.split(",");
		}
		return [];
	}
	get computerSkills(): string {
		return this.cSkillsArr.join(",");
	}

	toDbAdd(): TCvItemAddPart {
		return {
			cvc_id: TYPE_CATS["computer-skills"],
			items: {
				cve_title: this.computerSkills,
				cve_institution: this.computerSkills,
			},
		};
	}
	toDb(): TCvItemEdit {
		return {
			cvc_id: TYPE_CATS["computer-skills"],
			cve_id: this.id,
			cve_title: this.computerSkills,
			cve_public: this.public ? 1 : 0,
			cve_institution: this.computerSkills,
			// cve_attributes: {
			// 	cve_field: this.fieldStudy,
			// 	cve_start_date: this.startDate,
			// 	cve_end_date: this.endDate,
			// 	cve_ongoing: this.ongoing,
			// 	// cve_description: this.desc,
			// },
		};
	}

	get isEmptyForm(): boolean {
		return !this.computerSkills;
	}
}
