<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import { openReportedRemoveRestore } from "../../components/modals/modalDefinitions";
import api from "@/api";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";

const router = useRouter();

definePage({
	name: "AdminReportedListings",
});

const search = ref("");
const date = ref<string>("");
const manageCallApi = ref<boolean>(true);

const columns = reactive<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.listings.listingName"),
	},
	{
		id: "jrm_date",
		label: $t("app.listings.dateReported"),
	},
	{
		id: "job_created",
		label: $t("app.listings.datePosted"),
	},
	{
		id: "cmp_name",
		label: $t("app.listings.company"),
	},
	{
		id: "cli_name",
		label: $t("app.listings.client"),
	},
	{
		id: "user_count",
		label: $t("app.listings.reports"),
	},
	{
		id: "job_adminlocked", // status
		label: $t("app.listings.status"),
	},
	{
		id: "action",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([]);
const load = ref(false);

const showReportsData = ref<any>({});

// functionality
function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}

function setRemove(e: any) {
	openReportedRemoveRestore({ data: e }, "lock", onConfirm);
}

function setRestore(e: any) {
	openReportedRemoveRestore({ data: e }, "unlock", onConfirm);
}

async function onConfirm() {
	await getReports(true);
}

async function getReports(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	type TParam = {
		offset: number;
		limit: number;
		search?: string;
		date?: string;
	};
	const params: TParam = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value !== "") {
		params.search = search.value;
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	try {
		const res = await api.getReports(params);

		rows.value.push(...res.data.data.data);
		manageCallApi.value = res.data.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

function goToDetails(e: any) {
	void router.push({
		name: "ReoprtedData",
		params: { id: e.job_id },
	});
}

watch([search, date], async () => {
	await getReports(true);
});

onMounted(async () => {
	await getReports();
});
</script>

<template lang="pug">
.admin-subpage
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.listings.title") }}
			.wrapp-filters
				search-input(
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				//- .date
					modern-date-picker(
						v-model="date",
						:enable-time-picker="false",
						style="width: 150px"
					)

		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getReports"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'job_created' || slotProps.column.id == 'jrm_date'",
					:class="{ opacity50: slotProps.row.job_adminlocked }",
					@click="goToDetails(slotProps.row)"
				)
					.dateTime(:class="{ 'not-bold': slotProps.column.id == 'job_created' }")
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
				td(v-else-if="slotProps.column.id == 'job_adminlocked'")
					.statusUnavail(v-if="slotProps.row[slotProps.column.id]")
						span {{ $t("app.listings.unavailable") }}

				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					button.alt.btn-s(
						v-if="!slotProps.row.job_adminlocked",
						@click="setRemove(slotProps.row)"
					)
						span {{ $t("app.listings.lock") }}
					button.alt.btn-s(v-else, @click="setRestore(slotProps.row)")
						span {{ $t("app.listings.unlock") }}
					span(@click="goToDetails(slotProps.row)")
						img(src="@/assets/image/actionIcons/more.svg")

				td(
					v-else,
					:class="{ opacity50: slotProps.row.job_adminlocked }",
					@click="goToDetails(slotProps.row)"
				) {{ slotProps.row[slotProps.column.id] }}
			template(#table-body-extra-row-content="slotProps")
				transition(name="show-row")
					td.info-row(
						v-show="showReportsData.job_id === slotProps.row.job_id",
						:colspan="columns.length"
					)
		.loader-wrap-intern(v-else-if="load")
			.loader

		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/no_job.png", alt="")
</template>

<style lang="scss" scoped>
.admin-subpage {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;
		.up {
			display: flex;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				white-space: nowrap;
				margin-top: $margin-title-up;
			}
		}

		.wrapp-filters {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 15px;

			.dropdown {
				padding: 0 10px 0 2px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			margin-left: auto;
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 40px !important;
			position: relative;
			padding-right: 22px;

			span {
				cursor: pointer;
			}

			img {
				cursor: pointer;
				margin-left: 18px;
			}

			.show-reports-data {
				width: 234px;
				// min-height: 121px;
				box-sizing: border-box;
				padding: 10px 20px 10px 18px;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 1px;
				border-radius: 20px;
				background: $background-color;
				border: 1px solid #dfdfe8;
				// background: #e44e75;

				font-size: 14px;
				font-weight: 600;
				line-height: 19px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;

				& > .close-btn {
					margin: 0 0 auto auto;
					display: flex;
					align-self: center;
					padding: 3px;
					justify-content: flex-end;
					position: absolute;
					top: 5px;
					right: 5px;

					.close-icon {
						$size: 13px;

						height: $size;
						width: $size;
						cursor: pointer;

						path {
							fill: #aeadad;
						}

						&:hover {
							path {
								fill: #ffb4c3;
								transition: all 0.3s ease;
							}
						}
					}
				}

				img {
					cursor: pointer;
					margin-left: 18px;
					position: absolute;
				}

				.wrapp-info {
					span:first-child {
						display: inline-block;
						font-size: 11px;
						font-weight: 700;
						line-height: 15px;
						letter-spacing: 0;
						text-align: left;
						color: $text-color-lighter;
						margin-right: 20px;
					}
				}
				.m15 {
					margin-bottom: 15px;
				}
			}
		}
	}
}
.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}
td {
	cursor: pointer;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
<style lang="scss">
.admin-subpage {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
		}
		.opacity50 {
			opacity: 0.5;
			// opacity: 0.5px;
		}
		.not-bold {
			font-weight: 400;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
}
</style>
