import { axios } from "./config.js";
import type {
	TAuthCompany,
	TAuthVerifyCreate,
	TAuthVerifyResend,
	TAxiosGenRes,
	TCategoryList,
	TCountryItem,
	TCvGet,
	TEditCVParams,
	TForgotPass,
	TLoginParam,
	TLoginRes,
	TResetPass,
	TUserAdd,
	TUsersProfileGet,
} from "./types";

export default {
	// Auth
	authLogin: (params: TLoginParam) =>
		axios.post<TLoginRes>("auth/login", params),
	authCandidateRegister: (params: FormData) =>
		axios.post("auth/candidates/register", params),
	authRenewSession: () => axios.post<TLoginRes>("auth/renew-jwt"),
	authB2bRegister: (params: any) => axios.post("auth/b2b/register", params),
	authCompanyRegister: (params: TAuthCompany) =>
		axios.post("auth/company/register", params),
	authLogout: () => axios.get("auth/logout", { validateStatus: null }),
	authVerifyCreate: (params: TAuthVerifyCreate) =>
		axios.post<TLoginRes>("auth/verify", params),
	authVerifyResend: (params: TAuthVerifyResend) =>
		axios.put("auth/verify", params),
	authForgot: (params: TForgotPass) =>
		axios.post("auth/forgot-password", params),
	authResetPass: (params: TResetPass) =>
		axios.put("auth/forgot-password", params),

	// Categories
	getCategories: (params: Record<string, any>) =>
		axios.post<TAxiosGenRes<TCategoryList[]>>(
			"cv/categories/categories-get",
			params,
		),
	getCV: (params = {}) =>
		axios.get<{ data: TCvGet[]; res: "OK" }>("cv/cv-get", { params }),
	editCV: (params: TEditCVParams) => axios.post("cv/cv-edit", params),

	// Admin
	getAdmins: (params: any) => axios.post("admin/admin/admin-get", params),
	addAdmins: (params: any) => axios.post("admin/admin/admin-add", params),
	removeAdmin: (params: any) => axios.post("admin/admin/admin-remove", params),
	getCompanies: (params: any) =>
		axios.get("admin/companies/companies-get", { params }),
	getCompanySingle: (params: any) =>
		axios.get("companies/companies-get-single", { params }),
	getAdminCompanySingle: (params: any) =>
		axios.get("admin/companies/companies-get-single", { params }),
	adminVerifyCompanies: (params: any) =>
		axios.post("admin/companies/companies-verify", params),
	getAdminUsers: (params: any) =>
		axios.get("admin/users/users-get", { params }),
	removeUser: (params: any) => axios.post("users/users-remove", params),
	getAdminAds: (params: any) => axios.get("admin/ads/ads-get", { params }),
	editAdminAds: (params: any) => axios.post("admin/ads/ads-edit", params),
	addAdminAds: (params: any) => axios.post("admin/ads/ads-add", params),
	removeAdminAds: (params: any) => axios.post("admin/ads/ads-remove", params),

	// Contact us
	sendMessage: (params: any) => axios.post("auth/contact-us", params),
	// jobs
	getJobs: (params: any) => axios.get("jobs/jobs-get", { params }),
	getJobsAll: (params: any) => axios.get("jobs/jobs-get-all", { params }),
	getSingleJob: (params: any) => axios.get("jobs/jobs-get-single", { params }),

	// Users
	getUsers: (params: any) => axios.get("users/users-get", { params }),
	getUserProfile: () =>
		axios.get<{ data: TUsersProfileGet }>("users/users-get-profile", {}),
	postUserProfileEdit: (params: FormData | any) =>
		axios.post<{ res: "OK" }>("users/users-edit", params),
	postEditDestinations: (params: any) =>
		axios.post("users/destinations-edit", params),
	postUserAdd: (params: TUserAdd) => axios.post("users/users-add", params),
	likeListing: (params: any) => axios.post("jobs/jobs-like", params),

	// COMPANIES
	getCompaniesClient: (params: any) =>
		axios.get("companies/clients-get", { params }),
	getCmpClientAll: () => axios.get("companies/clients-get-all"),
	setJob: (params: any) => axios.post("jobs/jobs-add", params),
	getCandidates: (params: any) =>
		axios.get("applications/applications-get-users", { params }),
	jobsEdit: (params: any) => axios.post("jobs/jobs-edit", params),
	assigmentsEdit: (params: any) => axios.post("jobs/assignments-edit", params),
	getAssigments: (params: any) => axios.get("jobs/assignments-get", { params }),
	editCompany: (params: any) => axios.post("companies/companies-edit", params),

	// applications
	setApplication: (params: any) =>
		axios.post("applications/applications-add", params),
	getMyApplications: (params: any) =>
		axios.get("applications/applications-get-jobs", { params }),
	getApplication: (params: any) =>
		axios.get("applications/applications-get", { params }),
	getApplicationStatuses: (params: any) =>
		axios.get("applications/appstatus-get"),
	setApplicationStatus: (params: any) =>
		axios.post("applications/applications-edit-status", params),
	getB2bApplications: (params: any) =>
		axios.get("applications/applications-get-b2b", { params }),
	sendAppinvites: (params: any) =>
		axios.post("applications/appinvites-add", params),
	getAppInvites: (params: any) =>
		axios.get("applications/appinvites-get", params),
	invitesAccept: (params: any) =>
		axios.post("applications/appinvites-accept", params),
	invitesReject: (params: any) =>
		axios.post("applications/appinvites-remove ", params),
	invitesGetApplications: (params: any) =>
		axios.get("/applications/appinvites-get-applications", { params }),
	invitesGetSingle: (params: any) =>
		axios.get("applications/appinvites-get-single", { params }),
	getUsersFromApplication: (params: any) =>
		axios.get("applications/applications-get-participants", { params }),
	applicationsMute: (params: any) =>
		axios.post("applications/applications-mute", params),

	// company candidates
	getCompanyCandidates: (params: any) =>
		axios.get("managed-users/managed-users-get", { params }),
	// user set request to company
	setCompanyCandidates: (params: any) =>
		axios.post("managed-users/managed-users-request", params),
	// user cancer request - deletes itself when not yet approved
	cancelCompanyCandidates: () =>
		axios.post("managed-users/managed-users-cancel"),
	// company
	confirmUserRequest: (params: any) =>
		axios.post("managed-users/managed-users-reply", params),
	// company
	deleteUserManaged: (params: any) =>
		axios.post("managed-users/managed-users-delete", params),
	getManagedUserApplications: (params: any) =>
		axios.get("managed-users/managed-users-get-applications", { params }),
	// user
	manageUsersRequestDelete: () =>
		axios.post("managed-users/managed-users-request-delete"), //axios.post("managed-users/manage-users-request-delete"),

	// App - Files
	// { file_title: string; file_data: File | Blob }
	appFilesAdd: (params: any) => axios.post("applications/files-add", params),
	appFilesEditTitle: (params: { file_id: number; file_title: string }) =>
		axios.get("applications/files-edit-title", { params }),
	appFilesGetUser: (params: { usr_id?: number }) =>
		axios.get("applications/files-get-by-user", { params }),
	appFilesGetSingle: (params: {
		file_id: number;
		app_id?: number;
		msg_id?: number;
	}) =>
		axios.get("applications/files-get-single", {
			responseType: "blob",
			params,
		}),
	appFilesRemove: (params: { file_id: number }) =>
		axios.post("applications/files-remove", params),
	appFilesShareAdd: (params: { file_id: number; usr_id: number }) =>
		axios.post("applications/fileshares-add", params),
	appFilesShareRemove: (params: {
		file_id: number;
		usr_id: number;
		app_id: number;
	}) => axios.post("applications/fileshares-remove", params),
	applicationsAttachmentsGet: (params: any) =>
		axios.get("applications/attachments-get", { params }),
	applicationsReview: (params: any) =>
		axios.post("applications/attachments-review", params),
	appFilesharesEdit: (params: any) =>
		axios.post("applications/fileshares-edit", params),

	// Countries
	getCountries: () =>
		axios.get<{ data: TCountryItem[] }>("users/countries-get"),

	// report
	addReport: (params: any) => axios.post("jobs/jobremarks-add", params),
	getReports: (params: any) => axios.get("jobs/jobremarks-get", { params }),
	getReportedJob: (params: any) =>
		axios.get("jobs/jobremarks-get-single", { params }),
	getReportedUsers: (params: any) =>
		axios.get("jobs/jobremarks-get-users", { params }),
	// all candidates
	getPotentialCandidates: (params: any) =>
		axios.get("candidates/candidates-get", { params }),
	getPublicCompanies: (params: any) =>
		axios.get("companies/companies-get", { params }),

	// ROLES
	getRoles: () => axios.get("roles/roles-get"),

	// calendar
	getCalendarEvents: (params: any) =>
		axios.get("events/events-get-date", { params }),
	getEventUsers: (params: any) =>
		axios.get("events/eventinvites-get", { params }),
	editEventUsers: (params: any) =>
		axios.post("events/eventinvites-edit", params),
	addCalendarEvents: (params: any) => axios.post("events/events-add", params),
	editCalendarEvents: (params: any) => axios.post("events/events-edit", params),
	getCalendarEventData: (params: any) =>
		axios.get("events/events-get-single", { params }),
	deleteEvents: (params: any) => axios.post("events/events-remove", params),

	// ADS
	getAds: () => axios.get("ads/ads-get"),

	// CHAT 	// App - Messages
	getRooms: (params: any) =>
		axios.get("applications/messages-get-rooms", { params }),
	getMessages: (params: any) =>
		axios.get("applications/messages-get", { params }),
	addMessage: (params: any) => axios.post("applications/messages-add", params),

	appMessagesSeen: (params: { msg_id: number }) =>
		axios.post("applications/messages-seen", params),

	// internal MSG
	getRoomsInternal: (params: any) =>
		axios.get("applications/messages-get-rooms-internal", { params }),
	getMessagesInternal: (params: any) =>
		axios.get("applications/messages-get-internal", { params }),

	// feedback
	addFeedback: (params: any) => axios.post("feedbacks/feedbacks-add", params),
	getFeedback: (params: any) =>
		axios.get("feedbacks/feedbacks-get", { params }),
	getFeedbackFile: (params: any) =>
		axios.get("feedbacks/feedbacks-get-file", { responseType: "blob", params }),
	removeFeedback: (params: any) =>
		axios.post("feedbacks/feedbacks-remove", params),
	// notifications
	getNotifications: (params: any) =>
		axios.get("users/notifications-get", { params }),
	deleteOldNotifications: () => axios.post("users/notifications-remove-old"), // notifications-remove-old
	seenNotifications: (params: any) =>
		axios.post("users/notifications-seen", params),
	// clients
	getClients: (params: any) => axios.get("companies/clients-get", { params }),
	getSingleClient: (params: any) =>
		axios.get("companies/clients-get-single", { params }),
	addClient: (params: any) => axios.post("companies/clients-add", params),
	editClient: (params: any) => axios.post("companies/clients-edit", params),
	removeClient: (params: any) => axios.post("companies/clients-remove", params),
	// reportovi
	getReportsByClient: (params: any) =>
		axios.get("reports/reports-get-clients", { params }),
	getReportsByListing: (params: any) =>
		axios.get("reports/reports-get-listings", { params }),

	// side menu notify
	getNotifyCount: () => axios.get("users/notifications-get-unseen-count"),
	getMsgCount: () => axios.get("applications/messages-internal-has-seen"),
};
