<script setup lang="ts">
import { dff } from "@/assets/js/dateHelper";
import type { TFormSavedItem } from "@/components/profile/FormSavedItem.vue";
import { useCandidateStore } from "@/store/candidate";
const candidateStore = useCandidateStore();

definePage({
	name: "ProfileEducation",
});

const formItems = computed(() => candidateStore.formUser.edu);
const activeItem = computed(
	() =>
		formItems.value.getActiveFirstItem() as (typeof formItems.value.getItems)[number],
);
const inactiveItems = computed(
	() => formItems.value.getInactiveItems() as typeof formItems.value.getItems,
);

function transformSavedItem(
	item: (typeof formItems.value)["getItems"][number],
): TFormSavedItem {
	return {
		title: item.school,
		sub1: item.fieldStudy,
		sub2: `${dff(item.startDate)} - ${item.endDate ? dff(item.endDate) : "Ongoing"}`,
	};
}
const onClickMore = () =>
	!activeItem.value.isEmptyForm && candidateStore.formUser.edu.addItem();
const onEditItem = (id: string) => formItems.value.changeActiveItem(id);
const onDeleteItem = (id: string) => formItems.value.removeItem(id);
</script>

<template lang="pug">
.page
	.form-wrapper
		modern-form
			template(#logo)
				.logo-placeholder
			template(#contentInner)
				form-comp.form-inputs
					template(v-if="formItems.getItems.length > 1")
						form-saved-item(
							v-for="item in inactiveItems",
							:id="item.uniqueId",
							:key="item.uniqueId",
							:item="transformSavedItem(item)",
							@edit="onEditItem($event.id)",
							@delete="onDeleteItem($event.id)"
						)
					edu-form(v-if="activeItem", :form="activeItem")
				.add-more(
					tabindex="0",
					:class="{ disabled: activeItem.isEmptyForm }",
					@click="onClickMore",
					@keyup.enter.space="onClickMore"
				)
					img(src="@/assets/image/actionIcons/add.svg")
					span Add education
			template(#action)
				slot
	.right-side
		img(src="@/assets/image/auth/reg/2.png")
		//- input-img(
		//- 	:old-image="candidateStore.profileImageSrc",
		//- 	@set-image="onSetImage",
		//- 	@delete="onDeleteImage"
		//- )
</template>

<style lang="scss" scoped>
.page {
	@include candidate-profile-page;
}
</style>
