<script setup lang="ts">
import api from "@/api";
import { useCaptchaComposable } from "@/components/shared/recaptcha/recaptcha";
// import { useRouter } from "vue-router";
import { useToastStore } from "@/store/toast";
import { openVerifyModal } from "@/components/modals/modalDefinitions";
import { useCandidateStore } from "@/store/candidate";
import { useDefaultStore } from "@/store";
import { parseApiError, checkPhone } from "@/assets/js/helpers";
import { openVerifySuccessModal } from "@/components/modals/modalDefinitions";
import { useRouter, useRoute } from "vue-router";

definePage({
	name: "b2b",
});

const { recaptcha } = useCaptchaComposable();
const defaultStore = useDefaultStore();
const candidateStore = useCandidateStore();
// const toastStore = useToastStore();
const router = useRouter();
const route = useRoute();
const form = computed(() => defaultStore.registerForm.companyData);
const countryOptions = computed(() => candidateStore.countryOptions);

async function onRegister() {
	await registerApi();
}

async function registerApi() {
	const google_token = await recaptcha("registerCompany");

	// const google_token = defaultStore.tokenCaptcha;
	const params: any = {
		...form.value.toDb(),
		google_token,
	};
	if (route.query.invite_token) {
		params.ain_token = route.query.invite_token;
	}
	defaultStore.refreshToken = !defaultStore.refreshToken;
	try {
		await api.authB2bRegister(params);
		// toastStore.openToastSuccess(
		// 	"Company registered successfully. Verify account to proceed.",
		// );

		// void router.push("/");

		const email = form.value.usr_email;
		openVerifyModal({ email, type: "company" });
	} catch (err: any) {
		console.warn(err.message);
		const errorMessage = parseApiError(err);
		useToastStore().openToastError(
			errorMessage || "There was an error registering, please try again later.",
		);
	}
}

async function setCountries() {
	await candidateStore.fetchCountries();
	const defaultCountry = candidateStore.countryOptions.find(
		(co) => co.cnt_code.toLowerCase() === "rs",
	);
	if (defaultCountry) {
		form.value.updateCountry(defaultCountry);
	} else {
		console.warn("No default countries", candidateStore.countryOptions);
	}
}
async function initData() {
	// if (useTokenStore().isLoggedIn) {
	// 	await candidateStore.fetchUserProfile();
	// }
	// await candidateStore.fetchSetupCvData();
	await setCountries();
}

function openModal() {
	openVerifySuccessModal({}, onConfirm);
}
function onConfirm() {
	router.push({
		name: "CompanyProfile",
	});
}
onMounted(() => {
	void initData();

	if (route.query.token) {
		openModal();
	}
});
</script>

<template lang="pug">
.company-info
	modern-form
		template(#content)
			.title Fill in your B2B information
			.inputs
				.row
					.group
						label Company name
							.red *
						input(v-model="form.cmp_name")
				.row
					//- .group
						label Address and city
							.red *
						input(v-model="form.cmp_address")
					.group
						label Country
							.red *
						multiselect-form(
							v-model="form.countryObj",
							:options="countryOptions",
							:append-to-body="true",
							label="cnt_name",
							value-prop="cnt_code"
						)
				.row
					.group
						label Registration number
							.red *
						input(v-model="form.cmp_regnumber")
					.group
						label Director / owner
							.red *
						input(v-model="form.usr_name")
				.row
					.group
						label Email
							.red *
						input(v-model="form.usr_email")
					.group
						label Phone number
							.red *
						//- input(v-model.number="form.usr_phone", type="number")
						input(v-model="form.usr_phone", type="text", @keydown="checkPhone")
				.row
					//- turn-stile(
						:resetToken="defaultStore.refreshToken",
						@token-change="defaultStore.setCaptchaToken"
						)
					button(@click="onRegister")
						span Register
</template>

<style lang="scss" scoped>
.company-info {
	display: flex;

	.title {
		font-size: 22px;
		font-weight: 400;
		line-height: 30.8px;
		text-align: left;
		// margin: 20px 20px 0;
		margin: 20px 0 0;
	}

	.inputs {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.row {
			display: flex;
			justify-content: center;
			margin: 0 30px;
			flex-wrap: wrap;

			&:last-child {
				margin-bottom: 30px;
			}

			input {
				min-width: 290px;
			}
			button {
				max-height: 40px;
				align-self: center;
			}
		}
	}

	.multiselect-form {
		height: 34px;
		border: 1px solid $border-color1;
		border-radius: 20px;
	}

	label {
		display: flex;
		gap: 5px;

		.red {
			color: $error-color;
		}
	}

	@media screen and (width >= 768px) {
		.inputs {
			.row {
				flex-wrap: nowrap;
			}
		}
	}
}
</style>
