<script setup lang="ts">
definePage({
	name: "CommunityPage",
});
</script>

<template lang="pug">
.community-page
	groupCommunityPosts
	.wrappSide
		wrappSideCommunity(:title="$t('app.community.recommended')")
		wrappBottomSide(:title="$t('app.community.youAlsoLike')")
	//- wrappPosts
	//- wrappSideCommunity(:title="'bb'")
</template>

<style lang="scss" scoped>
.community-page {
	// all with side menu 1489
	max-width: 1224px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

@media only screen and (max-width: 1459px) {
	.community-page {
		.wrappSide {
			width: 100%;
			max-width: 817px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		// .wrappSide>.wrapp-side-community{
		// 	margin-right: 29px;
		// }
	}
}
</style>
