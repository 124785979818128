<script setup lang="ts">
import { $t } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
import type { TCompany } from "@/assets/js/types";
import { openModalRepresentMe } from "@/components/modals/modalDefinitions";
import api from "@/api";
import { useTokenStore } from "@/store/token";

definePage({
	name: "CompanyInformation",
	path: "/company/company-information/:id",
});

const tokenStore = useTokenStore();

const showRepresentMe = computed(() => {
	return (
		tokenStore.isLoggedIn &&
		tokenStore.userData?.rol_id === 1 && // "candidate"
		!tokenStore.userData?.cmp_name
		// tokenStore.userData?.rol_name === "managed-user")
		// !tokenStore.isUserCompany &&
		// !tokenStore.isUserAdmin
	);
});

const route = useRoute();
const router = useRouter();

const company = ref<TCompany>();

async function getCompanySingle() {
	try {
		const res = await api.getCompanySingle({ cmp_id: route.params.id });
		console.log(res.data.data);
		company.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function representMe() {
	console.log("representMe");
	openModalRepresentMe(
		{ name: company.value.cmp_name, data: company.value },
		onConfirm,
	);
}
function onConfirm(e: TCompany) {
	console.log(e);
	console.log("confirm");
	tokenStore.renewToken();
}

onMounted(async () => {
	await getCompanySingle();
});
</script>

<template lang="pug">
.company-information-page
	.wrappUp
		.wrapp-title
			.line
			.w-t(@click="router.back()")
				.arrow-left
				.title {{ $t("app.companies.companyInformation") }}
				.action
		button(v-if="showRepresentMe", @click="representMe")
			span {{ $t("app.companies.representMe") }}
	component-company-information(v-if="company", :company="company")
</template>

<style lang="scss" scoped>
.company-information-page {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;

	label {
		color: $text-color-light;
		font-size: 0.7rem;
		display: inline-block;
		margin-bottom: 19px;
	}
	button {
		max-height: 34px;
	}

	.wrappUp {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		align-items: center;
		width: 100%;
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				// height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				display: inline;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
	}
}
</style>
