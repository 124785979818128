<script setup lang="ts">
import { openMyApplicationModal } from "@/components/modals/modalDefinitions";
import type {
	TTableColumn,
	TTableRow,
} from "@/components/shared/table/ModernTable.vue";
import { $t } from "@/i18n";
import api from "@/api";
import { useTokenStore } from "@/store/token";
import { useToastStore } from "@/store/toast";
import { useDefaultStore } from "@/store";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";

definePage({
	name: "JobSaved",
});

interface IListCountry {
	cnt_code: string;
	cnt_name: string;
}

const router = useRouter();
const tokenStore = useTokenStore();
const toastStore = useToastStore();
const showRowIndex = ref<number[]>([]);
const manageCallApi = ref<boolean>(true);
const search = ref<string>("");
const date = ref<string>("");
const load = ref(false);

const selectedCountry = ref<IListCountry | null>(null);
const countries = ref<IListCountry[] | []>([]);

const tempColumns = ref<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.joblisting.tableHeaders.listingName"),
	},
	{
		id: "job_created",
		label: $t("app.joblisting.tableHeaders.date"),
	},
	{
		id: "job_expires",
		label: $t("app.joblisting.tableHeaders.expy"),
	},
	{
		id: "cnt_name",
		label: $t("app.joblisting.tableHeaders.loc"),
	},
	{
		id: "cli_name",
		label: $t("app.joblisting.tableHeaders.company"),
	},
	{
		id: "col6",
		label: $t("app.joblisting.tableHeaders.send"),
	},
	{
		id: "liked",
		label: "", //$t("app.joblisting.tableHeaders.save"),
	},
	{
		id: "col7",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([]);

const tempOptionsLoc = ref([
	{ label: "All Locations", name: "test1" },
	{ label: "Remote", name: "test3" },
	{ label: "Office", name: "test5" },
	{ label: "Hybrid", name: "test7" },
]);
const selectedOptionLoc = ref({ label: "All Locations", name: "test1" });

const tempOptionsSort = ref([
	{ label: "Newest posted", name: "test1" },
	{ label: "Oldest posted", name: "test3" },
	{ label: "Expiring soon", name: "test5" },
	{ label: "Not expiring soon", name: "test7" },
]);
const selectedOptionSort = ref({ label: "Newest posted", name: "test1" });

const toggleShowRow = (index: number) => {
	// console.log(index);
	if (showRowIndex.value.includes(index)) {
		showRowIndex.value = showRowIndex.value.filter((i) => i !== index);
		return;
	}
	showRowIndex.value.push(index);
};

const openModal = async (job: any) => {
	if (!tokenStore.userData) {
		await useDefaultStore().onClickLoginPage();
		return;
	}
	openMyApplicationModal({ data: job }, onConfirm);
};
function onConfirm(e: any) {
	console.log(e);
	const res = rows.value.findIndex((el) => el.job_id === e.job_id);
	if (res >= 0) {
		console.log(res);
		rows.value[res].applied = 1;
	}
}
function formatDate(date: string) {
	return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}

function openPage(e: any) {
	void router.push({
		name: "JobPreview",
		params: { id: e.job_id },
	});
}

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function deselectCountries(evt: any, evtParsed: any) {
	selectedCountry.value = null;
}

async function getJobs(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	interface IParams {
		view: "company" | "public";
		cmp_id?: number; // optional ako je view company uzece se iz jwt-a
		// filter - (po statusu),
		// ako je view comapny onda moze biti cancelled, finished, unlisted, paused, published, draft,
		// ako je view public onda moze da bude published i paused
		filter?:
			| "Cancelled"
			| "Finished"
			| "Unlisted"
			| "Paused"
			| "Published"
			| "Draft";
		limit?: number;
		offset?: number;
		search?: string;
		date?: string;
		cnt_code?: string;
		liked: boolean;
	}

	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}

	const params: IParams = {
		view: "public",
		liked: true,
		limit: 20,
		// offset: rows.value.length,
		offset: filterLiked().length,
	};

	if (search.value !== "") {
		params.search = search.value;
	}

	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	if (selectedCountry.value) {
		params.cnt_code = selectedCountry.value.cnt_code;
	}

	try {
		const res = await api.getJobs(params);
		// console.log(res.data.data.data);
		if (res.data?.data?.data) {
			rows.value.push(...res.data.data.data);
		}
		manageCallApi.value = res.data.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}
async function saveListing(e: TTableRow, action: string) {
	console.log(e);
	try {
		const res = await api.likeListing({ job_id: e.job_id });
		console.log(res);
		setLiked(e.job_id, action);
		filterLiked();
		if (action === "save") {
			toastStore.openToastSuccess($t("app.joblisting.successLiked"));
		} else {
			toastStore.openToastSuccess($t("app.joblisting.successUnLiked"));
		}
	} catch (e) {
		console.log(e);
		toastStore.openToastError($t("app.joblisting.error"));
	}
}

function setLiked(id: number, action: string) {
	const index = rows.value.findIndex((obj: TTableRow) => obj.job_id == id);
	rows.value[index].liked = action === "save" ? 1 : 0;
}

function filterLiked() {
	const res = rows.value.filter((obj) => obj.liked === 1);
	// console.log("-------------", res.length);
	return res;
}

watch([search, date, selectedCountry], async () => {
	await getJobs(true);
});
onMounted(async () => {
	await getJobs();
	await getCountries();
});
</script>

<template>
	<div class="page">
		<div class="list-wrapper">
			<div class="table-header">
				<div class="title-wrap">
					<div class="line"></div>
					<div class="title">{{ $t("app.joblisting.savedJob") }}</div>
				</div>
				<div class="input-wrap">
					<search-input
						placeholder="Type something..."
						:search-value="search"
						@update:search-value="search = $event"
					/>
					<!-- <input type="date" placeholder="Expiry Date" /> -->

					<div class="dropdown">
						<multiselect-form
							v-model="selectedCountry"
							:options="countries"
							label="cnt_name"
							value-prop="cnt_code"
							placeholder="All countries"
							:can-deselect="true"
							@deselect="deselectCountries"
						></multiselect-form>
					</div>
					<modern-date-picker
						v-model="date"
						:enable-time-picker="false"
						style="width: 150px"
						placeholder="Expires until"
					/>

					<div v-if="false" class="dropdown location-wrap">
						<multiselect-form
							v-model="selectedOptionLoc"
							:options="tempOptionsLoc"
							label="label"
							value-prop="label"
						/>
					</div>

					<div v-if="false" class="dropdown sort-wrap">
						<multiselect-form
							v-model="selectedOptionSort"
							:options="tempOptionsSort"
							label="label"
							value-prop="label"
						/>
					</div>
				</div>
			</div>
			<div class="table-comp">
				<modern-table
					v-if="rows.length > 0"
					:columns="tempColumns"
					:rows="rows"
					:has-border="false"
					:display-additional-row="true"
					:isInfiniteScroll="manageCallApi"
					style="max-height: 700px"
					@scroll-more="getJobs"
				>
					<template #table-body-cell="slotProps">
						<td v-if="slotProps.column.id === 'col6'">
							<button
								style="min-width: 104px"
								class="btn-s"
								:disabled="slotProps.row.applied"
								@click="openModal(slotProps.row)"
							>
								{{
									slotProps.row.applied
										? $t("app.joblisting.applied")
										: $t("app.joblisting.apply")
								}}
								<img
									v-if="!slotProps.row.applied"
									src="@/assets/image/arrow-right.svg"
								/>
							</button>
						</td>
						<td
							v-else-if="
								slotProps.column.id === 'job_created' ||
								slotProps.column.id === 'job_expires'
							"
							@click="openPage(slotProps.row)"
						>
							{{ formatDate(slotProps.row[slotProps.column.id]) }}
						</td>
						<td v-else-if="slotProps.column.id === 'liked'">
							<img
								v-if="slotProps.row[slotProps.column.id] === 0"
								src="../../../assets/image/icons/noLike.svg"
								alt=""
								title="not saved"
								@click="saveListing(slotProps.row, 'save')"
							/>
							<img
								v-else
								src="../../../assets/image/icons/like.svg"
								alt=""
								title="saved"
								@click="saveListing(slotProps.row, 'unsave')"
							/>
						</td>
						<td v-else-if="slotProps.column.id === 'col7'">
							<div
								class="show-more"
								@click="toggleShowRow(slotProps.row.job_id)"
							>
								{{
									showRowIndex.includes(slotProps.row.job_id)
										? "Show less"
										: "Show more"
								}}<img
									:class="{
										rotate: showRowIndex.includes(slotProps.row.job_id),
									}"
									src="@/assets/image/tablecaret.svg"
									alt=""
								/>
							</div>
						</td>
						<td
							v-else-if="slotProps.column.id === 'job_title'"
							style="font-weight: bold"
							@click="openPage(slotProps.row)"
						>
							{{ slotProps.row[slotProps.column.id] }}
						</td>
						<td
							v-else
							:class="{
								blue:
									slotProps.column.id === 'cnt_name' ||
									slotProps.column.id === 'cli_name',
							}"
							@click="openPage(slotProps.row)"
						>
							{{ slotProps.row[slotProps.column.id] }}
						</td>
					</template>
					<template #table-body-extra-row-content="slotProps">
						<transition name="show-row">
							<td
								v-show="showRowIndex.includes(slotProps.row.job_id)"
								:colspan="tempColumns.length"
								class="info-row"
							>
								{{ slotProps.row.job_shortinfo }}
							</td>
						</transition>
					</template>
				</modern-table>
				<div v-else-if="load" class="loader-wrap-intern">
					<div class="loader"></div>
				</div>
				<div v-else class="wrapp-no-fund-data-table">
					<img src="@/assets/image/no_job.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.page {
	// padding: 10px;
	height: 100%;
	border-radius: 10px;
	background-color: $bg-color1;

	.list-wrapper {
		min-height: 850px;
		min-width: 1220px;
		// border: $border;

		padding: 0 30px;

		.table-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.title-wrap {
				width: max-content;
				.line {
					// height: 5px;
					height: 0;
					width: 100%;
					// background: $gradient-line3;
					box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
					border-radius: 0 0 10px 10px;
				}

				.title {
					font-weight: 700;
					font-size: 16px;
					margin-top: 12px;
				}
			}
			.input-wrap {
				display: flex;
				flex-direction: row;
				gap: 15px;
				padding-top: 12px;

				.dropdown {
					padding: 0 10px;
					border-radius: $input-radius;
					color: $text-color;
					font-size: 14px;
					outline: none;
					background-color: transparent;
					text-indent: 10px;
					border: 1px solid;
					border-color: $input-border-color;
					min-height: $input-height;
					min-width: 150px;
					text-overflow: ellipsis;
				}
			}
		}

		.table-comp {
			margin-top: 25px;
			height: calc(100% - 67px) !important;

			.info-row {
				box-sizing: border-box;
				max-width: 100%;
				padding: 20px 10px;
				cursor: initial;
			}

			.show-more {
				cursor: pointer;
				display: flex;
				justify-content: flex-end;
				img {
					margin-left: 5px;
					transition: transform 0.4s ease-in-out;
				}

				.rotate {
					transform: rotate(180deg);
				}
			}
		}
	}
}
.btn-s {
	min-height: 29px !important;
	max-height: 29px !important;
}
td {
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
}

.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}
.blue {
	color: $app-accent-color1;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
