<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
// import { openChangePlan } from "../../components/modals/modalDefinitions";
import api from "@/api";
import type { TCountryItem } from "@/api/types";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";

definePage({
	name: "AdminCompanies",
});

const router = useRouter();
const columns = reactive<TTableColumn[]>([
	{
		id: "cmp_name",
		label: $t("app.companies.company"),
	},
	{
		id: "cmp_regnumber",
		label: $t("app.companies.registrationNumber"),
	},
	{
		id: "usr_emailverified",
		label: $t("app.api.verify.verified"),
	},
	{
		id: "cnt_name",
		label: $t("app.companies.country"),
	},
	{
		id: "usr_email",
		label: $t("app.companies.email"),
	},
	{
		id: "cmp_type",
		label: $t("app.companies.type"),
	},
	{
		id: "cmp_plan",
		label: $t("app.companies.membership"),
	},
	{
		id: "action",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([]);
const manageCallApi = ref<boolean>(true);
const showOptionsData = ref<any>({});
const load = ref(false);

// FILTERS
interface IListType {
	id: number;
	label: string;
}
interface IListType2 {
	id: string;
	label: string;
}
const date = ref<string>("");
const search = ref<string>("");
const selectedCountry = ref<TCountryItem | null>(null);
const countries = ref<TCountryItem[] | []>([]);

const selectedType = ref<IListType | null>(null);
const typeList = reactive([
	{ id: 2, label: "AGENCY" },
	{ id: 3, label: "B2B" },
]);

const selectedMembership = ref<IListType2 | null>(null);
const membershipList = reactive([
	{ id: "FREE", label: "FREE" },
	{ id: "P1", label: "Premium" },
	{ id: "P2", label: "P2" },
	{ id: "P3", label: "P3" },
]);

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

// deselect

function deselectCountries(evt: any, evtParsed: any) {
	// console.log(evt);
	// console.log(evtParsed);
	selectedCountry.value = null;
}
function deselectType() {
	selectedType.value = null;
}
function deselectMembership() {
	selectedMembership.value = null;
}

async function getData(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}

	interface IParams {
		limit: number;
		offset: number;
		search?: string;
		cnt_code?: string;
		type?: string;
		membership?: string;
		usr_emailverified?: any;
	}
	const params: IParams = {
		limit: 20,
		offset: rows.value.length,
	};
	if (search.value && search.value.trim().length > 0) {
		params.search = search.value;
	}
	if (selectedCountry.value) {
		params.cnt_code = selectedCountry.value.cnt_code;
	}
	if (selectedType.value) {
		params.type = selectedType.value.label;
	}
	if (selectedMembership.value) {
		params.membership = selectedMembership.value.id;
	}
	if (date.value) {
		params.usr_emailverified = DateInstance.getUtcFromDate(
			date.value,
			"YYYY-MM-DD",
		);
	}
	try {
		const res = await api.getCompanies(params);

		// rows.value = res.data.data;
		rows.value.push(...res.data.data);
		manageCallApi.value = res.data.data.length <= 0 ? false : true;
	} catch (err: any) {
		console.warn(err.message);
	} finally {
		load.value = false;
	}
}

function showOptions(e: any) {
	showOptionsData.value = e;
}

function getMoreInfo(e: any) {
	showOneCompany(e);
	showOptionsData.value = {};
}

// function getChangePlan(e: any) {

// 	// DORADI
// 	openChangePlan(e);
// 	showOptionsData.value = {};
// }

function showOneCompany(e: any) {
	// showCompanyData.value = e;
	void router.push({
		name: "AdminCompanyInformation",
		params: { id: e.cmp_id },
	});
}

// function writePlan(e: string) {
// 	const res = plans.value.find((el) => el.id === e.toUpperCase());
// 	if (res) {
// 		return res.label;
// 	}
// 	return null;
// }

onMounted(async () => {
	await getData();
	await getCountries();
});
// functionality
function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}
watch(
	[selectedCountry, selectedType, selectedMembership, date, search],
	async () => {
		await getData(true);
	},
);
</script>

<template lang="pug">
.admin-subpage
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.companies.title") }}
			.wrapp-filters
				search-input(
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				.date
					modern-date-picker(
						v-model="date",
						:placeholder="$t('app.api.verify.dateVerified')",
						:enable-time-picker="false",
						style="width: 150px"
					)
				.dropdown
					multiselect-form(
						v-model="selectedCountry",
						:options="countries",
						label="cnt_name",
						value-prop="cnt_code",
						placeholder="All countries",
						:canDeselect="true",
						@deselect="deselectCountries"
					)
				.dropdown
					multiselect-form(
						v-model="selectedType",
						:options="typeList",
						label="label",
						value-prop="label",
						placeholder="All type",
						:can-deselect="true",
						@deselect="deselectType"
					)
				.dropdown
					multiselect-form(
						v-model="selectedMembership",
						:options="membershipList",
						label="label",
						value-prop="label",
						placeholder="All members",
						:can-deselect="true",
						@deselect="deselectMembership"
					)
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getData"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'usr_emailverified'",
					@click="showOneCompany(slotProps.row)"
				)
					.dateTime
						span {{ slotProps.row[slotProps.column.id] ? formatDate(slotProps.row[slotProps.column.id]) : "" }}
				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span(@click="showOptions(slotProps.row)")
						img(src="@/assets/image/actionIcons/more.svg")

					.show-reports-data(v-if="showOptionsData.cmp_id === slotProps.row.cmp_id")
						.close-btn(data-name="close-action", @click="showOptionsData = {}")
							svg.close-icon(
								width="16",
								height="16",
								viewBox="0 0 16 16",
								fill="none",
								xmlns="http://www.w3.org/2000/svg"
							)
								path(
									fill-rule="evenodd",
									clip-rule="evenodd",
									d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
									fill="#575758"
								)
						.wrapp-info(@click="getMoreInfo(slotProps.row)")
							span {{ $t("app.candidates.moreInfo") }}
						//- .wrapp-info(@click="getChangePlan(slotProps.row)")
							span {{ $t("app.candidates.changePlan") }}

				td(
					v-else,
					:class="{ bold: slotProps.column.id === 'cnt_name', upperCase: slotProps.column.id === 'cmp_plan' }",
					@click="showOneCompany(slotProps.row)"
				) {{ slotProps.row[slotProps.column.id] }}
			//- template(#table-body-extra-row-content="slotProps")
				transition(name="show-row")
					//- td.info-row(
						v-show="showOptionsData.cmp_id === slotProps.row.cmp_id",
						:colspan="columns.length"
						)
		.loader-wrap-intern(v-else-if="load")
			.loader
		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/noCandidatesFund.png")
</template>

<style lang="scss" scoped>
.admin-subpage {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			// align-items: center;
			justify-content: space-between;
			// padding-bottom: 15px;

			.dropdown {
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			max-width: 40px;
			margin-left: auto;
			display: flex;
			align-items: center;
			height: 40px !important;
			position: relative;
			padding-right: 22px;

			span {
				cursor: pointer;
			}

			img {
				cursor: pointer;
				margin-left: 18px;
			}

			.show-reports-data {
				width: 149px;
				min-height: 04px;
				box-sizing: border-box;
				padding: 12px 15px 0;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 1px;
				border-radius: 20px;
				background: $background-color;
				border: 1px solid #dfdfe8;
				// background: #e44e75;

				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0.03em;
				text-align: left;

				& > .close-btn {
					margin: 0 0 auto auto;
					display: flex;
					align-self: center;
					padding: 3px;
					justify-content: flex-end;
					position: absolute;
					top: 5px;
					right: 5px;

					.close-icon {
						$size: 13px;

						height: $size;
						width: $size;
						cursor: pointer;

						path {
							fill: #aeadad;
						}

						&:hover {
							path {
								fill: #ffb4c3;
								transition: all 0.3s ease;
							}
						}
					}
				}

				img {
					cursor: pointer;
					margin-left: 18px;
					position: absolute;
				}

				.wrapp-info {
					margin-bottom: 15px;
				}
				.wrapp-info:hover {
					color: $app-accent-color1;
					cursor: pointer;
				}
			}
		}
	}
}
.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>

<style lang="scss">
.admin-subpage {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.upperCase {
			text-transform: uppercase;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
	.search-wrapper {
		margin-right: 20px;
		margin-top: 10px;
		width: 20%;
		min-width: 150px !important;
		input {
			min-width: 150px !important;
			max-width: 100% !important;
			width: 100% !important;
		}
	}
	input[type="date"],
	.dropdown {
		margin-top: 10px;
	}
}
</style>
