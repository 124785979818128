<script setup lang="ts">
import api from "@/api";
import { useToastStore } from "@/store/toast";
import { useCaptchaComposable } from "@/components/shared/recaptcha/recaptcha";
import { checkPhone } from "@/assets/js/helpers";

const toastStore = useToastStore();

definePage({
	name: "ContactPage",
});

const { recaptcha } = useCaptchaComposable();
interface IType {
	name: string;
	email: string;
	phone_number: string;
	message: string;
	google_token: string;
}
interface IForm {
	name: string;
	type: string;
	label: string;
	value: string;
	error: boolean;
}

const form2 = reactive<IForm[]>([
	{
		name: "name",
		type: "text",
		label: "firstLastName",
		value: "",
		error: false,
	},
	{ name: "email", type: "text", label: "email", value: "", error: false },
	{
		name: "phone_number",
		type: "text",
		label: "phoneNum",
		value: "",
		error: false,
	},
	{
		name: "message",
		type: "textarea",
		label: "message",
		value: "",
		error: false,
	},
]);

// const token = ref("");
// const resetToken = ref(false);
const policy = ref(false);

function checkData() {
	let ifErr = false;
	const reg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

	for (const element of form2) {
		if (element.value.trim().length <= 0) {
			element.error = true;
			ifErr = true;
		}
		if (element.name === "email" && !reg.test(element.value)) {
			element.error = true;
			ifErr = true;
			toastStore.openToastError("Email is not valid.");
			return;
		}
	}

	if (ifErr) {
		toastStore.openToastError("All fields are required.");
		return;
	}
	if (!policy.value) {
		toastStore.openToastError("You must agree to our Privacy Policy.");
		return;
	}
	void sendData();
}

async function sendData() {
	const token = await recaptcha("contact");
	const params: IType = {
		name: "",
		email: "",
		phone_number: "",
		message: "",
		google_token: token,
	};
	for (const element of form2) {
		params[element.name] = element.value;
	}
	// resetToken.value = !resetToken.value;
	try {
		const res = await api.sendMessage(params);
		console.log(res);
		clearForm();
		console.log(params);
		toastStore.openToastSuccess(
			"You have successfully sent your message. Expect a reply soon.",
		);
	} catch {
		toastStore.openToastError("Error");
	}
}

function clearForm() {
	for (const element of form2) {
		element.value = "";
		element.error = false;
	}
	policy.value = false;
}
</script>

<template lang="pug">
.contact-page
	.wrappTxt
		.title {{ $t("app.contact.contactUs") }}

		.txtB {{ $t("app.contact.weHereTxt") }}
		.txt {{ $t("app.contact.fillTxt") }}
		.wrapp-info
			.group
				.icon
					img(src="@/assets/image/iconsCV/email.svg")
				.info
					.label {{ $t("app.contact.email") }}
					.info-data info@imjob.rs
			.group
				.icon
					img(src="@/assets/image/iconsCV/phone.svg")
				.info
					.label {{ $t("app.contact.phoneNum") }}
					.info-data +381 11 4235 496
					.info-data +381 69 3040 595
			.group
				.icon
					img(src="@/assets/image/iconsCV/locationFill.svg")
				.info
					.label {{ $t("app.contact.location") }}
					.info-data No.11L Teodora Drajzera St.11040 Belgrade, Serbia

	.wrapp-form
		.wrapp-title
			.line
			.title {{ $t("app.contact.sendMsg") }}
		.input-group
			.group(v-for="(obj, index) in form2", :key="index")
				label {{ $t("app.contact." + obj.label) }}
				textarea(
					v-if="obj.type === 'textarea'",
					v-model="obj.value",
					:class="{ error: obj.error }",
					@keyup="obj.error = false"
				)
				input(
					v-else-if="obj.name === 'phone_number'",
					v-model="obj.value",
					:type="obj.type",
					:class="{ error: obj.error }",
					@keyup="obj.error = false",
					@keydown="checkPhone"
				) 
				input(
					v-else,
					v-model="obj.value",
					:type="obj.type",
					:class="{ error: obj.error }",
					@keyup="obj.error = false"
				)
			.g-check.modern-cbox
				input#policy(v-model="policy", type="checkbox")
				label.wrapp-label(for="policy")
					span.dark {{ $t("app.contact.checkBoxMsg") }}
					br
					span.blue {{ $t("app.contact.privacyPolicy") }}

			//- turn-stile(v-model="token", :resetToken="resetToken")

			button(@click="checkData")
				span {{ $t("app.contact.send") }}
</template>

<style lang="scss" scoped>
@import "@/assets/css/pageStyle/contactFeedback.scss";
</style>
