<script setup lang="ts">
// import { useTokenStore } from "@/store/token";
import { openChangePass } from "@/components/modals/modalDefinitions";
import lodashClonedeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import api from "@/api";
import { useToastStore } from "@/store/toast";
import { $t } from "@/i18n";
import RepresentorComp from "@/components/profile/RepresentorComp.vue";
import { useCandidateStore } from "@/store/candidate";

definePage({
	name: "SettingsInformation",
});

interface IListCountry {
	cnt_code: string;
	cnt_name: string;
}

type TSocialMedia = {
	type: string | any;
	link: string;
};

type TUserProfile = {
	cmp_id?: number | null; // null,
	cmp_name?: string | null; // null,
	rol_name: string; // "candidate",
	rol_id: number; // 1,
	usr_position?: string | null; // "MY Position",
	usr_dateofbirth?: string | null; // "1984-08-02 00:00:00",
	cnt_code?: string | null; // "rs",
	img_id?: number | null; // 6688460822357472,
	usr_email: string; // "miracupkic@gmail.com",
	usr_aboutme: string | null; // "t. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, \"Lorem ipsum dolor sit amet..\", comes from a line in section",
	usr_created?: string | null; // "2024-02-12 11:39:43",
	usr_socialnetworks: any; // null,
	usr_id: number | null; // 123,
	usr_name: string; // "Moje Prezime ć",
	usr_phone?: string | null; // "12121212",
	usr_address: string | null; // "Beograd",
	usr_employmentlength?: string | null; // null,
	cmp_id_mngrequest: number | null; // 2,
	usr_mngrequested: string | null; // "2024-04-08 07:32:16",
	usr_mngapproved: string | null; // null
	usr_attributes: any;
	destinations: IListCountry[] | string[];
	usr_mutenotifications?: null | 0 | 1;
};

const toastStore = useToastStore();
const candidateStore = useCandidateStore();
const countries = ref<IListCountry[]>();

// function deselectCountries(evt: IListCountry) {
// 	console.log(evt);
// }

// data
const oldUserData = ref<TUserProfile>();
const newUserData = ref<TUserProfile>();

const editInformation = ref(false);

const notifications = ref([
	{ name: $t("app.settings.mute"), value: true },
	// { name: "Notifikacija 1", value: true },
]);

const networks = ref(["linkedin", "facebook", "instagram", "x"]);
const setMedia = ref<TSocialMedia>({
	type: "",
	link: "",
});

function deleteSocial(e: TSocialMedia) {
	console.log("delete", e);
	delete newUserData.value.usr_socialnetworks[e.type];
	refreshNetworks({ type: e.type, link: e.link });
}

function setEditSocial(e: TSocialMedia) {
	console.log("edit", e);
	setMedia.value.link = e.link;
	setMedia.value.type = { label: e.type };

	delete newUserData.value.usr_socialnetworks[e.type];
	refreshNetworks({ type: e.type, link: e.link });
}

function cancelSocilForSave() {
	setMedia.value.link = "";
	setMedia.value.type = "";
}

function setSocilForSave() {
	if (setMedia.value.link === "" || setMedia.value.type === "") {
		toastStore.openToastError($t("app.settings.socialFieldsErr"));
		return;
	}
	console.log(setMedia.value.type);
	newUserData.value.usr_socialnetworks[setMedia.value.type.label] =
		setMedia.value.link;
	setMedia.value.link = "";
	setMedia.value.type = "";
	refreshNetworks();
	console.log(newUserData.value.usr_socialnetworks);
}

function refreshNetworks(obj: TSocialMedia | null = null) {
	if (obj !== null) {
		networks.value.push(obj.type);
	}

	for (const property in newUserData.value.usr_socialnetworks) {
		console.log(`${property}: `);
		let index = networks.value.indexOf(property);
		if (index !== -1) {
			networks.value.splice(index, 1);
		}
	}
}

// const stayTime = ref("6 months");

function openEditPass() {
	console.log("open modal");
	openChangePass({}, confirmAdd);
}
async function confirmAdd() {
	// console.log("confirm add");
	editInformation.value = false;
}

// const tokenStore = useTokenStore();
async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

async function fetchUserProfile() {
	try {
		const res = await api.getUserProfile();
		console.log("res.data.data------------------------");

		oldUserData.value = lodashClonedeep(res.data.data);
		newUserData.value = lodashClonedeep(res.data.data);
		refreshNetworks();
		notifications.value[0].value = oldUserData.value.usr_mutenotifications
			? true
			: false;

		if (res.data?.data?.destinations) {
			newUserData.value.destinations = setPreferences(
				res.data.data.destinations,
			);
			// console.log(newUserData.value.destinations);
		}
		oldUserData.value.usr_socialnetworks =
			oldUserData.value.usr_socialnetworks ?? {};
		newUserData.value.usr_socialnetworks =
			newUserData.value.usr_socialnetworks ?? {};
		// newUserData.value.usr_attributes.profile_youtube_link = "kksksks";
		// console.log(oldUserData.value);
		// console.log(newUserData.value);
	} catch (err: any) {
		console.warn(err.message);
	}
}

async function saveEdit() {
	// console.log("saveEdit");

	let params: any = {};

	for (const key in newUserData.value) {
		if (
			Object.prototype.hasOwnProperty.call(oldUserData.value, key) &&
			newUserData.value[key] !== oldUserData.value[key] &&
			key !== "destinations" &&
			key !== "usr_attributes" &&
			key !== "usr_socialnetworks"
		) {
			params[key] = newUserData.value[key];
		}
	}
	// SET SOCIAL
	if (
		!isEqual(
			newUserData.value.usr_socialnetworks,
			oldUserData.value.usr_socialnetworks,
		)
	) {
		params.usr_socialnetworks = JSON.stringify(
			newUserData.value.usr_socialnetworks,
		);
	}
	// set notifications
	if (
		oldUserData.value.usr_mutenotifications !==
		(notifications.value[0].value ? 1 : 0)
	) {
		params.usr_mutenotifications = notifications.value[0].value ? 1 : 0;
	}

	// params.usr_socialnetworks = JSON.stringify({ linkedin: "hhhh/kkk/" });

	// CHECK DESTIONATIONS

	// destinations_add i destinations_remove
	// @ts-expect-error
	const oldDestinations = setPreferences(oldUserData.value.destinations);

	const checkDestinations = findAddedAndSubtractedElements(
		oldDestinations,
		newUserData.value.destinations,
	);

	// Set destinations
	let destinationsParams: any = {};

	if (checkDestinations.added.length > 0) {
		destinationsParams.destinations_add = checkDestinations.added;
	}
	if (checkDestinations.subtracted.length > 0) {
		destinationsParams.destinations_remove = checkDestinations.subtracted;
	}
	// End set destinations

	if (
		Object.keys(params).length <= 0 &&
		Object.keys(destinationsParams).length <= 0
	) {
		toastStore.openToastError($t("app.api.global.nothing-changed"));
		return;
	}

	if (Object.keys(params).length > 0) {
		await saveEditInformation(params);
	}
	if (Object.keys(destinationsParams).length > 0) {
		await saveEditDestinations(destinationsParams);
	}
	toastStore.openToastSuccess($t("app.profile.success"));
	editInformation.value = false;
	await fetchUserProfile();
}

async function saveEditInformation(params: any) {
	try {
		const res = await api.postUserProfileEdit(params);
		console.log(res);
		await candidateStore.fetchUserProfile(); // Refresh the data to verify correct info and to update avatar
		await candidateStore.fetchSetupCvData(); // Refresh the cv data to not send changed values (add / delete) - duplication
	} catch (e) {
		console.log(e.response?.data);
		if (e.response?.data?.data) {
			const errMsg = "app.api.global." + e.response?.data?.data;
			toastStore.openToastError($t(errMsg));
		}
	}
}

async function saveEditDestinations(params: any) {
	try {
		const res = await api.postEditDestinations(params);
		console.log(res);
	} catch (e) {
		// console.log(e.response?.data);
		if (e.response?.data?.data) {
			const errMsg = "app.api.global." + e.response?.data?.data;
			toastStore.openToastError($t(errMsg));
		}
	}
}

function readPreferences() {
	let read = [];
	for (const obj of oldUserData.value.destinations) {
		// @ts-expect-error
		read.push(obj.cnt_name);
	}
	return read.toString();
}
function setPreferences(destinations: IListCountry[]) {
	let arrayPref = [];
	for (const destination of destinations) {
		arrayPref.push(destination.cnt_code);
	}

	return arrayPref;
}

function cancel() {
	editInformation.value = false;
	newUserData.value = lodashClonedeep(oldUserData.value);
	newUserData.value.destinations = setPreferences(
		// @ts-expect-error
		newUserData.value.destinations,
	);
}

onMounted(async () => {
	await fetchUserProfile();
	await getCountries();
});

// watch(
// () => newUserData.value?.usr_socialnetworks,
// (newVal, oldVal) => {
// 	console.log(newVal);
// 	console.log(oldVal);

// 	if (newVal !== oldVal) {
// 		console.log("nsxsyxsxysxsxsx");
// 	}

// 	// networks.value;
// },
// {
// 	deep: true,
// },);

function findAddedAndSubtractedElements(
	primarySequence: any[],
	comparisonSequence: any[],
) {
	const addedElements = comparisonSequence.filter(
		(element) => !primarySequence.includes(element),
	);
	const subtractedElements = primarySequence.filter(
		(element) => !comparisonSequence.includes(element),
	);

	return {
		added: addedElements,
		subtracted: subtractedElements,
	};
}

function checkNumber(e: any) {
	// console.log(e.target.value);
	// const numericRegex = /^[0-9]*$/;
	// if (!numericRegex.test(e.target.value)) {
	// 	e.preventDefault();
	// }
	// Allow: backspace, delete, tab, escape, enter, and dot (.)
	if (
		[46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
		// Allow: Ctrl/cmd+A
		(e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
		// Allow: Ctrl/cmd+C
		(e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
		// Allow: Ctrl/cmd+V
		(e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
		// Allow: Ctrl/cmd+X
		(e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
		// Allow: home, end, left, right
		(e.keyCode >= 35 && e.keyCode <= 39)
	) {
		// let it happen, don't do anything
		return;
	}

	// Ensure that it is a number and stop the keypress
	if (
		(e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
		(e.keyCode < 96 || e.keyCode > 105)
	) {
		e.preventDefault();
	}
}

async function refresh() {
	await fetchUserProfile();
}
</script>

<template lang="pug">
.information(v-if="oldUserData")
	.split
		.title {{ $t("app.settings.information") }}
		.input-group
			.group
				label {{ $t("app.contact.firstLastName") }}

				input(
					v-if="editInformation",
					v-model="newUserData.usr_name",
					type="text",
					:placeholder="$t('app.companies.listingName')"
				)
				.txt(v-else) {{ oldUserData.usr_name }}
			.group
				label {{ $t("app.contact.email") }}
				.txt {{ oldUserData.usr_email }}
			.group
				label {{ $t("app.auth.pass") }}
				.txt **********
			.wrapp-action
				.btn(@click="openEditPass")
					span {{ $t("app.settings.changePassword") }}

		//- preferences
		.title.mt-90 {{ $t("app.settings.preferences") }}
		.input-group
			.group
				label {{ $t("app.settings.preferCountryTxt") }}
				//- textarea(v-if="editInformation", ref="shortinfo", v-model="preferCountry")

				.dropdown(v-if="editInformation")
					multiselect-form(
						v-model="newUserData.destinations",
						mode="tags",
						:searchable="true",
						:create-option="true",
						:close-on-select="true",
						:options="countries",
						:append-to-body="true",
						label="cnt_name",
						value-prop="cnt_code"
					)
				.txt(v-else) {{ readPreferences() }}
			.group
				label {{ $t("app.settings.stayTime") }} (months)
				input(
					v-if="editInformation",
					v-model="newUserData.usr_employmentlength",
					type="number",
					@keydown="checkNumber"
				)
				.txt(v-else) {{ oldUserData.usr_employmentlength }}

	.split
		.title {{ $t("app.settings.socialMedia") }}
		.wrapp-social
			SocialMedia(
				v-for="(value, key, index) in newUserData.usr_socialnetworks",
				:key="index",
				:social="{ type: key, link: value }",
				:edit="editInformation",
				@delete="deleteSocial",
				@edit="setEditSocial"
			)
			//- div(
				v-for="(value, key, index) in newUserData.usr_socialnetworks",
				:key="index"
				) {{ value }},{{ key }},{{ index }}
		.input-group(v-if="editInformation")
			.group
				label {{ $t("app.settings.addProfile") }}
				.dropdown
					multiselect-form(
						v-model="setMedia.type",
						:options="networks",
						placeholder="Filter",
						label="label",
						value-prop="label"
					)
			.group
				label {{ $t("app.settings.insertLink") }}
				input(v-model="setMedia.link", type="text")
			.wrapp-button
				.btn.alt(@click="cancelSocilForSave")
					span {{ $t("app.settings.cancel") }}
				.btn(@click="setSocilForSave")
					span {{ $t("app.settings.set") }}

		//- notifications
		.title.mt-90 {{ $t("app.settings.notifications") }}
		.input-group
			.wrapp-switch(v-for="(notifi, index) in notifications", :key="index")
				span {{ notifi.name }}
				SwitchComp(v-model="notifi.value", :disabled="!editInformation")
		RepresentorComp(
			v-if="newUserData.usr_mngrequested || newUserData.usr_mngapproved",
			:data="{ cmp_id_mngrequest: newUserData.cmp_id_mngrequest, usr_mngrequested: newUserData.usr_mngrequested, usr_mngapproved: newUserData.usr_mngapproved, cmp_name: newUserData.cmp_name, cmp_id: newUserData.cmp_id }",
			@refresh="refresh"
		)
	.split.wrapp-main-func
		.btn(v-if="!editInformation", @click="editInformation = true")
			span {{ $t("app.settings.edit") }}
		.btn.alt(v-if="editInformation", @click="cancel")
			span {{ $t("app.settings.cancel") }}
		.btn(v-if="editInformation", @click="saveEdit")
			span {{ $t("app.settings.saveChanges") }}
</template>

<style lang="scss" scoped>
.information {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 26px;
	justify-content: space-between;

	.split {
		// width: 50%;
		// margin-bottom: 90px;
		min-width: 320px;

		.title {
			// font-family: Archivo, sans-serif;
			font-size: 16px;
			font-weight: 700;
			line-height: 17.41px;
			text-align: left;
			color: $text-color; // test
		}
		.mt-90 {
			margin-top: 90px;
		}
		.input-group {
			margin-top: 20px;
			padding-left: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: baseline;
			max-width: 320px;
			min-width: 250px;

			.group {
				margin-bottom: 15px;
				font-family: "Open Sans", sans-serif;

				label {
					// k
				}
				.txt {
					font-weight: 600;
				}
			}
			.wrapp-action {
				max-width: 100%;
			}
		}
		.wrapp-social {
			display: flex;
			padding-top: 20px;
		}
		.wrapp-button {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	.wrapp-main-func {
		display: flex;
		flex-direction: column;
		min-width: 150px;
		.btn {
			margin-bottom: 20px;
		}
	}

	.dropdown {
		padding: 0 10px;
		border-radius: $input-radius;
		// color: $text-color;
		// font-size: 14px;
		outline: none;
		// background-color: transparent;
		// text-indent: 10px;
		border: 1px solid;
		border-color: $input-border-color;
		height: $input-height;
		min-width: 150px;
		align-items: center;
		// text-overflow: ellipsis;
		margin-top: 10px;
	}
	.wrapp-switch {
		display: flex;
		justify-content: space-between;
		width: 200px;
		margin-bottom: 15px;
		span {
			font-family: "Open Sans", sans-serif;
			font-size: 12px;
			font-weight: 600;
			line-height: 16.34px;
			text-align: left;
			color: $text-color-light;
		}
	}
}
</style>
