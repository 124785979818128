<script setup lang="ts">
definePage({
	name: "HomePage",
	redirect: "/auth/login",
});

// function seeOffers() {
// 	console.log("seeOffers");
// }
</script>

<template lang="pug">
.home-page
	//- button(@click="socket.sendMessage(message)") Send message
	.wrappTxt
		.title {{ $t("app.home.title") }}
			span {{ " " + $t("app.home.job") }}
			span.nospan !
		.txt {{ $t("app.home.txt") }}
		router-link.btn(:to="{ name: 'JobListingPage' }")
			span {{ $t("app.home.seeOffers") }}
	.wrappImg
		img(src="@/assets/image/home.png")
</template>

<style lang="scss" scoped>
.home-page {
	// all with side menu 1489
	max-width: 1224px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-left: 50px;

	.wrappTxt {
		box-sizing: border-box;
		width: calc(50% - 33px);
		margin-top: 77px;

		.title {
			// font-family: Anton, sans-serif;
			font-size: 60px;
			font-weight: 400;
			line-height: 90px;
			letter-spacing: 0;
			text-align: left;
			margin-bottom: 30px;

			span {
				color: $text-color-tirkiz;
			}
			.nospan {
				color: $text-color;
			}
		}
		.txt {
			font-family: "Open Sans", sans-serif;
			font-size: 24px;
			font-weight: 400;
			line-height: 33px;
			letter-spacing: 0;
			text-align: left;
			margin-bottom: 30px;
		}
	}
	.wrappImg {
		box-sizing: border-box;
		width: 50%;
		img {
			width: 100%;
		}
	}
	a {
		text-decoration: none;
	}
}

@media only screen and (max-width: 1250px) {
	.home-page {
		padding: 0;
		flex-direction: column;
		align-items: center;
		.wrappTxt,
		.wrappImg {
			width: 80%;
		}
	}
}

@media only screen and (max-width: 767px) {
	.home-page {
		padding: 20px;
		flex-direction: column;
		align-items: center;
		.wrappTxt,
		.wrappImg {
			width: 100%;
		}
		.wrappTxt {
			margin: 0;
			.title {
				font-size: 40px;
				line-height: 60px;
				letter-spacing: 0;
				text-align: center;
			}
			.txt {
				font-size: 18px;
				font-weight: 400;
				line-height: 23px;
				letter-spacing: 0;
				text-align: left;
				margin-bottom: 30px;
			}
		}
	}
}
</style>
