import { useRoute, useRouter } from "vue-router";
import api from "@/api";

export const useChatMessage = defineStore("chatMessage", () => {
	const route = useRoute();
	const router = useRouter();

	// data
	const rooms = ref<any[]>([]);
	const selectedRoom = ref<any>(null);
	const messages = ref<any[]>([]);
	const isInfiniteScroll = ref(true);

	async function getRooms(params: any) {
		// type TParams = {
		//     search?: string;
		// };
		// const params: TParams = {};
		// if (searchInRooms.value) {
		//     params.search = searchInRooms.value;
		// }
		try {
			// console.log("zameni sa apijem za sobe koji dobijes", params);
			try {
				const res = await api.getRoomsInternal(params);
				// console.log(res.data);
				rooms.value = res.data.data;

				// messages.value.unshift(...res.data.data.reverse());
				// isInfiniteScroll.value = false;
			} catch (e) {
				console.log(e);
			}
			// const res = await api.getRooms(params); zameni sa apijem koji dobijes
			// console.log(res.data);
			// messages.value.push(...res.data.data);
			// messages.value.unshift(...res.data.data.reverse());
			// isInfiniteScroll.value = false;
		} catch (e) {
			console.log(e);
		}
	}

	async function getMessages(resetChat = false, view = "old") {
		if (!selectedRoom.value) {
			return;
		}
		if (resetChat) {
			messages.value = [];
		}
		type TParams = {
			last_msg_id: number | null;
			usr_id: number;
			chat_type: string;
			search?: string;
			view: "old" | "new" | string;
		};

		const params: TParams = {
			last_msg_id:
				view === "old"
					? messages.value.length <= 0
						? null
						: messages.value.at(-1).msg_id
					: messages.value.length <= 0
						? null
						: messages.value.at(0).msg_id,
			usr_id: selectedRoom.value.usr_id,
			chat_type: selectedRoom.value.chat_type,
			view: view,
		};

		try {
			const res = await api.getMessagesInternal(params);

			if (view === "old") {
				messages.value.push(...res.data.data.reverse());
			} else if (view === "new") {
				messages.value.unshift(...res.data.data);
				await setSeen(res.data.data[0].msg_id);
			}

			if (res.data.data.length > 0) {
				isInfiniteScroll.value = true;
			}
		} catch (e) {
			console.log(e);
		}

		// messages.value.push(...res.data.data);
	}
	async function setRow(e: any) {
		selectedRoom.value = e;
		router.replace({ name: "ChatMain", params: { id: e.usr_id } });
		await getMessages(true);
		if (e.unread === 1 && messages.value.length > 0) {
			// setLocallySeen();
			await setSeen(messages.value[0].msg_id);
		}
	}
	async function setSeen(id: number) {
		try {
			const res = await api.appMessagesSeen({ msg_id: id });
			if (res.data?.res === "OK") {
				setLocallySeen();
			}
		} catch (e) {
			console.log(e);
		}
	}
	function setLocallySeen() {
		const index = rooms.value.findIndex(
			(obj) =>
				obj.usr_id === selectedRoom.value?.usr_id &&
				obj.chat_type === selectedRoom.value?.chat_type,
		);
		if ((index || index === 0) && index !== -1) rooms.value[index].unread = 0;
		// console.log(index);
	}

	async function cbInWS(message: any) {
		// if (message?.type === "new-message") {
		const index = rooms.value.findIndex((obj) =>
			message?.usr_id
				? obj.usr_id === message?.usr_id
				: obj.usr_id === message?.usr_id_to,
		);
		// console.log("index", index);

		if (
			selectedRoom.value !== null &&
			(selectedRoom.value?.usr_id === message?.usr_id ||
				selectedRoom.value?.usr_id === message?.usr_id_to)
		) {
			await getMessages(false, "new");
		} else if ((index || index === 0) && index !== -1) {
			rooms.value[index].unread = 1;
		} else if (index === -1 && selectedRoom.value?.newCh) {
			await getMessages(true, "new");
			// await getRooms(); //call in new msg
		} else if (
			index === -1 &&
			(!selectedRoom.value || !selectedRoom.value?.newCh)
		) {
			await getRooms({});
		}
		// set new room
		if (selectedRoom.value?.newCh) {
			await getRooms({});
			const obj = rooms.value.find(
				(obj) => obj.usr_id === Number(route.params.id),
			);
			if (obj) {
				selectedRoom.value = obj;
				// chatMsgStore.setSelectedRoom(obj);
			}
		}
		// setNewMsgPositions(message.usr_id);
		// }
	}
	// set value
	function setSelectedRoom(e: any) {
		selectedRoom.value = e;
	}
	function resetMessage() {
		messages.value = [];
	}

	return {
		// data
		rooms,
		selectedRoom,
		messages,
		isInfiniteScroll,

		// function
		getRooms,
		getMessages,
		setSelectedRoom,
		setRow,
		cbInWS,
		resetMessage,
	};
});
