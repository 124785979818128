import type { TCvItem, TLangItem } from "@/api/types";
import {
	CFormItem,
	CTypeItems,
	type TCvItemAdd,
	type TCvItemAddPart,
	type TCvItemEdit,
} from "./formTypes";
import { TYPE_CATS, langOptions } from "../constants";
import { CValidate } from "../validations";

export class CLangTypeItems extends CTypeItems {
	protected readonly items: CTypeItem[] = [];

	constructor(...items: TCvItem[]) {
		super();
		if (items?.length) {
			for (const item of items) {
				this.addItem(item);
			}
		} else {
			this.addItem();
		}
	}
	addItem(item?: TCvItem, shouldChangeActive = true): CTypeItem {
		const itemInstance = new CTypeItem(item);
		let res = langOptions.find((obj) => obj.name === item?.cve_institution);
		itemInstance.updateProficiency(res ?? langOptions[0]);
		this.items.push(itemInstance);
		if (shouldChangeActive) {
			this.activeItemHash = itemInstance.uniqueId;
		}
		return itemInstance;
	}
	updateItem(obj: TCvItem, uniqueId: string) {
		const index = this.items.findIndex((item) => item.uniqueId === uniqueId);
		if (index !== -1) {
			this.items[index].updateVal(obj);
		}
	}
	getAllAdded(): TCvItemAdd | null {
		const tempArr: TCvItemAdd = {
			cvc_id: TYPE_CATS.languages,
			items: [],
		};
		for (const item of this.getItems) {
			if (!item.id) {
				const items = item.toDbAdd().items;
				if (
					Object.values(items).every((value) => {
						if (typeof value === "string") {
							return value.trim() !== "";
						}
						return Boolean(value);
					})
				) {
					// Only if all values exist, the object should be included
					tempArr.items.push(items);
				}
			}
		}
		if (tempArr.items.length) {
			return tempArr;
		}
		return null;
	}
	getAllEditted() {
		const tempArr = [];
		for (const item of this.getItems) {
			if (item.id) {
				tempArr.push(item.toDb());
			}
		}
		return tempArr;
	}

	get getItems() {
		return this.items.filter(
			(item) => !this.pendingRemoval.includes(item.uniqueId),
		);
	}
}
class CTypeItem extends CFormItem {
	public: 0 | 1 = 0;
	title = "";
	proficiencyObj: TLangItem | null = null;
	readonly validations = {
		title: CValidate.stringR(),
		proficiency: CValidate.stringR(),
	};

	constructor(obj?: TCvItem) {
		super();
		if (obj) {
			this.updateVal(obj);
		}
	}

	updateVal(obj: TCvItem) {
		this.id = obj.cve_id;
		this.public = obj.cve_public ? 1 : 0;
		this.title = obj.cve_title;

		if (obj.cve_institution) {
			const item = langOptions.find(
				(item) => item.name === obj.cve_institution,
			);
			if (item) {
				this.updateProficiency(item);
			}
		}
		// this.proficiency = obj.cve_institution;
	}
	updateProficiency(obj: TLangItem) {
		this.proficiencyObj = obj;
	}
	get proficiency(): string {
		return this.proficiencyObj?.name || "";
	}

	toDbAdd(): TCvItemAddPart {
		return {
			cvc_id: TYPE_CATS.languages,
			items: {
				cve_title: this.title,
				cve_institution: this.proficiency,
			},
		};
	}
	toDb(): TCvItemEdit {
		return {
			cvc_id: TYPE_CATS.languages,
			cve_id: this.id,
			cve_title: this.title,
			cve_public: this.public ? 1 : 0,
			cve_institution: this.proficiency,
			// cve_attributes: {
			// 	cve_field: this.fieldStudy,
			// 	cve_start_date: this.startDate,
			// 	cve_end_date: this.endDate,
			// 	cve_ongoing: this.ongoing,
			// 	// cve_description: this.desc,
			// },
		};
	}

	get isEmptyForm(): boolean {
		return !(this.title || this.proficiency);
	}
}
