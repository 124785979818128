<script setup lang="ts">
// import { useTokenStore } from "@/store/token";
import { openChangePass } from "@/components/modals/modalDefinitions";
import lodashClonedeep from "lodash.clonedeep";
import { useToastStore } from "@/store/toast";
import api from "@/api";
import { $t } from "@/i18n";
import { baseURL } from "@/api/config";
import { useCandidateStore } from "@/store/candidate";

definePage({
	name: "SettingsAboutMe",
});

const candidateStore = useCandidateStore();
const toastStore = useToastStore();
type TUserProfile = {
	cmp_id?: number | null; // null,
	cmp_name?: string | null; // null,
	rol_name: string; // "candidate",
	rol_id: number; // 1,
	usr_position?: string | null; // "MY Position",
	usr_dateofbirth?: string | null; // "1984-08-02 00:00:00",
	cnt_code?: string | null; // "rs",
	img_id?: number | null; // 6688460822357472,
	usr_email: string; // "miracupkic@gmail.com",
	usr_aboutme: string | null; // "t. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of \"de Finibus Bonorum et Malorum\" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, \"Lorem ipsum dolor sit amet..\", comes from a line in section",
	usr_created?: string | null; // "2024-02-12 11:39:43",
	usr_socialnetworks: string | null; // null,
	usr_id: number | null; // 123,
	usr_name: string; // "Moje Prezime ć",
	usr_phone?: string | null; // "12121212",
	usr_address: string | null; // "Beograd",
	usr_employmentlength?: string | null; // null,
	cmp_id_mngrequest: number | null; // 2,
	usr_mngrequested: string | null; // "2024-04-08 07:32:16",
	usr_mngapproved: string | null; // null
	usr_attributes: any;
	usr_mutenotifications?: null | 0 | 1;
};

// IMAGE
const deleteImage = ref<any>(null);
const image = ref<any>(null);

const oldUserData = ref<TUserProfile>();
const newUserData = ref<TUserProfile>();

const editInformation = ref(false);

const notifications = ref([{ name: $t("app.settings.mute"), value: true }]);

function openEditPass() {
	console.log("open modal");
	openChangePass({}, confirmAdd);
}
async function confirmAdd() {
	console.log("confirm add");
	editInformation.value = false;
}

async function fetchUserProfile() {
	try {
		const res = await api.getUserProfile();
		console.log("res.data.data------------------------");
		oldUserData.value = lodashClonedeep(res.data.data);
		newUserData.value = lodashClonedeep(res.data.data);
		// newUserData.value.usr_attributes.profile_youtube_link = "kksksks";
		// console.log(oldUserData.value);
		// console.log(newUserData.value);
		notifications.value[0].value = oldUserData.value.usr_mutenotifications
			? true
			: false;
	} catch (err: any) {
		console.warn(err.message);
	}
}

async function saveEdit() {
	console.log("saveEdit");
	// let params: any = {};
	const fd = new FormData();
	let change = false;
	for (const key in newUserData.value) {
		if (
			Object.prototype.hasOwnProperty.call(oldUserData.value, key) &&
			newUserData.value[key] !== oldUserData.value[key] && // params[key] = newUserData.value[key];
			key !== "img_id"
		) {
			change = true;
			fd.append(key, newUserData.value[key]);
		}
	}
	// set notifications
	if (
		oldUserData.value.usr_mutenotifications !==
		(notifications.value[0].value ? 1 : 0)
	) {
		// @ts-expect-error
		fd.append("usr_mutenotifications", notifications.value[0].value ? 1 : 0);
	}
	if (image.value?.file) {
		fd.append("image", image.value.file);
		change = true;
	}
	if (deleteImage.value) {
		// @ts-expect-error
		fd.append("delete_image", 1);
		change = true;
	}

	if (/*Object.keys(params).length <= 0*/ !change) {
		toastStore.openToastError($t("app.api.global.nothing-changed"));
		return;
	}

	try {
		const res = await api.postUserProfileEdit(fd);
		console.log(res);
		toastStore.openToastSuccess($t("app.profile.success"));
		editInformation.value = false;
		await fetchUserProfile();
		await candidateStore.fetchUserProfile(); // Refresh the data to verify correct info and to update avatar
		await candidateStore.fetchSetupCvData(); // Refresh the cv data to not send changed values (add / delete) - duplication
	} catch (e) {
		console.log(e.response?.data);
		if (e.response?.data?.data) {
			const errMsg = "app.api.global." + e.response?.data?.data;
			toastStore.openToastError($t(errMsg));
		} else toastStore.openToastError("Erorr");
	}
}

onMounted(async () => {
	await fetchUserProfile();
});

function getOldImg(id: any) {
	return id ? baseURL + "/users/images-get/" + id : undefined;
}

function setImage(e: any) {
	console.log(e);
	//   e.file | e.base64
	image.value = e;
	deleteImage.value = false;
}

function deleteImg(e: any) {
	// deleteImage.value = null;
	console.log("set delete");
	deleteImage.value = true;
	image.value = null;
	if (newUserData.value) {
		newUserData.value.img_id = null;
	}
}

function cancel() {
	editInformation.value = false;
	deleteImage.value = false;
	image.value = null;
	newUserData.value = lodashClonedeep(oldUserData.value);
	// newUserData.value.usr_attributes = "kksksks";
	// console.log(oldUserData.value);
	// console.log(newUserData.value);
}

// const tokenStore = useTokenStore();
</script>

<template lang="pug">
.aboutme(v-if="oldUserData")
	.split
		.title {{ $t("app.settings.information") }}
		.input-group
			.group
				label {{ $t("app.contact.firstLastName") }}
				input(
					v-if="editInformation",
					v-model="newUserData.usr_name",
					type="text",
					:placeholder="$t('app.companies.listingName')"
				)
				.txt(v-else) {{ oldUserData.usr_name }}
			.group
				label {{ $t("app.contact.email") }}
				.txt {{ oldUserData.usr_email }}
			.group
				label {{ $t("app.auth.pass") }}
				.txt **********
			.wrapp-action
				.btn(@click="openEditPass")
					span {{ $t("app.settings.changePassword") }}
		//- preferences
		.title.mt-90 {{ $t("app.profile.aboutMe") }}
		.input-group
			.group
				label {{ $t("app.profile.descripion") }}
				textarea(
					v-if="editInformation",
					ref="shortinfo",
					v-model="newUserData.usr_aboutme"
				)
				.txt(v-else) {{ oldUserData.usr_aboutme }}
		//- notifications 
		.title.mt-90 {{ $t("app.settings.notifications") }}
		.input-group
			.wrapp-switch(v-for="(notifi, index) in notifications", :key="index")
				span {{ notifi.name }}
				SwitchComp(v-model="notifi.value", :disabled="!editInformation")
	.split
		inputImg(
			:oldImage="getOldImg(newUserData.img_id)",
			:aspectRatio="1 / 1",
			xBackground="#E44E75",
			:deletable="editInformation",
			:editable="editInformation",
			@set-image="setImage",
			@delete="deleteImg"
		)
	.split.wrapp-main-func
		.btn(v-if="!editInformation", @click="editInformation = true")
			span {{ $t("app.settings.edit") }}
		.btn.alt(v-if="editInformation", @click="cancel")
			span {{ $t("app.settings.cancel") }}
		.btn(v-if="editInformation", @click="saveEdit")
			span {{ $t("app.settings.saveChanges") }}
</template>

<style lang="scss" scoped>
.aboutme {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-top: 26px;
	justify-content: space-between;
	.split {
		// width: 50%;
		// margin-bottom: 90px;
		min-width: 320px;

		.title {
			// font-family: Archivo, sans-serif;
			font-size: 16px;
			font-weight: 700;
			line-height: 17.41px;
			text-align: left;
			color: $text-color; // test
		}
		.mt-90 {
			margin-top: 90px;
		}
		.input-group {
			margin-top: 20px;
			padding-left: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: baseline;
			max-width: 320px;
			min-width: 250px;

			.group {
				margin-bottom: 15px;
				font-family: "Open Sans", sans-serif;

				label {
					// k
				}
				.txt {
					font-weight: 600;
				}
				textarea {
					height: 180px;
				}
			}
			.wrapp-action {
				max-width: 100%;
			}
		}
		.wrapp-social {
			display: flex;
			padding-top: 20px;
		}
		.wrapp-button {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	}

	.wrapp-switch {
		display: flex;
		justify-content: space-between;
		width: 200px;
		margin-bottom: 15px;
		span {
			font-family: "Open Sans", sans-serif;
			font-size: 12px;
			font-weight: 600;
			line-height: 16.34px;
			text-align: left;
			color: $text-color-light;
		}
	}

	.wrapp-main-func {
		display: flex;
		flex-direction: column;
		min-width: 150px;
		.btn {
			margin-bottom: 20px;
		}
	}
}
</style>
