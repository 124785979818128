<script setup lang="ts">
import api from "@/api";
import { $t } from "@/i18n";
// import { useDefaultStore } from "@/store";
import type { TListing } from "@/assets/js/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTokenStore } from "@/store/token";
import { useRoute, useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";

// const store = useDefaultStore();
dayjs.extend(utc);

const router = useRouter();
const route = useRoute();
definePage({
	name: "ListingPreviewBtB",
	path: "/company/listing-preview-btb/:id",
});

const tokenStore = useTokenStore();

const emit = defineEmits(["returnOnList", "editList"]);

const form = ref<TListing | any>({});
const data = ref<TListing | any>({});

async function getSingleJob() {
	const params = {
		view: "public",
		job_id: route.params.id,
	};
	try {
		const res = await api.getSingleJob(params);
		// console.log(res.data.data);
		data.value = res.data.data;
		data.value.job_expires = formatLocalDate(res.data.data.job_expires); // job_expires job_active
		data.value.job_active = formatLocalDate(res.data.data.job_active);
		form.value = JSON.parse(JSON.stringify(res.data.data));
		form.value.job_expires = formatLocalDate(form.value.job_expires);
		form.value.job_active = formatLocalDate(form.value.job_active);

		if (res.data.data.job_attributes) {
			data.value.job_attributes = JSON.parse(data.value.job_attributes);
			// console.log(data.value.job_attributes);
		} else {
			data.value.job_attributes = { job_notes: "" };
			form.value.job_attributes = { job_notes: "" };
		}
	} catch (e) {
		console.log(e);
	}
}

function formatLocalDate(date: string, format = "YYYY-MM-DD HH:mm") {
	// removed locale
	// return dayjs.utc(date).format("DD.MM.YYYY. HH:mm");
	return DateInstance.getFormatDTLocale(date, format);
}

onMounted(async () => {
	await getSingleJob();
});
</script>
<template lang="pug">
.list-preview
	.main(v-if="form")
		.up
			.wrapp-title
				.line
				.w-t
					.arrow-left(@click="router.back()")
					.title {{ $t("app.companies.listingPreview") }}
			.wrapp-action(v-if="tokenStore.can('job-edit')")
				//- .btn(v-if="!edit", @click="setEdit")
					span {{ $t("app.companies.editListing") }}
		.content
			.input-group
				candidates-list(v-if="data.job_id", :job-id="data.job_id")
			.input-group
				.group
					label {{ $t("app.companies.listingName") }}
					.txt {{ form.job_title }}
				.group
					label {{ $t("app.companies.country") }}
					.txt {{ form?.cnt_name }}
				.group
					label {{ $t("app.companies.shortDescription") }}
					.txt {{ form.job_shortinfo }}
				.group
					label {{ $t("app.companies.client") }}
					.txt {{ form?.cli_name }}
				.group
					label {{ $t("app.companies.dateFrom") }}
					.txt {{ DateInstance.getFormattedDate(form.job_active, "DD. MMM YYYY.") }}
				.group
					label {{ $t("app.companies.expirationDate") }}
					.txt {{ DateInstance.getFormattedDate(form.job_expires, "DD. MMM YYYY.") }}

				.group
					label {{ $t("app.companies.addExternallink") }}
					.txt {{ form.job_externallink }}
				.box
					.txt-label {{ $t("app.companies.qReqVideo") }}
					.flex-option
						.modern-rbox
							.txt(style="margin-bottom: 0") {{ form.job_reqvideo ? "Yes" : "No" }}
				.box
					.txt-label {{ $t("app.companies.qReqCV") }}
					.flex-option
						.modern-rbox
							.txt(style="margin-bottom: 0") {{ form.job_reqportfolio ? "Yes" : "No" }}
				.box
					.txt-label {{ $t("app.companies.qReqRecommendations") }}
					.flex-option
						.modern-rbox
							.txt(style="margin-bottom: 0") {{ form.job_reqrecommendations ? "Yes" : "No" }}

			.input-group
				.group.textarea
					label {{ $t("app.companies.reqAdditional") }}

					.txt {{ form.job_reqadditional }}
				.group.editor
					label {{ $t("app.companies.fLDescription") }}
					//- textarea.long(v-if="edit", v-model="form.job_description")

					.txt.mh510.scroll(v-html="form.job_description")
					//- {{ form.job_description }}
				.group
</template>
<style lang="scss" scoped>
.list-preview {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-action {
			display: flex;
			align-items: center;
			.btn {
				// margin-top: 7px;
				margin-left: 20px;
			}
		}
	}

	.content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 30px; //  40px

		.input-group {
			display: flex;
			flex-direction: column;
			align-items: baseline;
			max-width: 26%;
			.group.textarea {
				max-height: 150px;
				margin-bottom: 10px;
			}
			.group.editor {
				height: 530px;
				margin-bottom: 10px;
			}
			textarea {
				height: 100px;
			}
			textarea.long {
				height: 530px;
			}
			.txt {
				font-size: 13px;
				font-weight: 600;
				line-height: 18px;
				letter-spacing: 0;
				margin-bottom: 20px;
			}
			pre {
				font-family: "Open Sans", sans-serif;
				// font-size: 13px;
				font-weight: 600 !important;
				// line-height: 18px;
				// letter-spacing: 0;
				// text-align: left;
				text-wrap: wrap;
			}
		}
		.input-group:first-of-type {
			max-width: 40%;
		}
		.dropdown {
			padding: 0 10px;
			border-radius: $input-radius;
			// color: $text-color;
			// font-size: 14px;
			outline: none;
			// background-color: transparent;
			// text-indent: 10px;
			border: 1px solid;
			border-color: $input-border-color;
			height: $input-height;
			min-width: 150px;
			width: 100%;
			align-items: center;
			// text-overflow: ellipsis;
		}
		.box {
			margin-top: 15px;
			.txt-label {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color-light;
				margin-bottom: 10px;
			}

			.flex-option {
				display: flex;
			}
			.modern-rbox {
				display: flex;
				margin-right: 40px;
				input {
					margin-right: 10px;
				}
			}
		}
		.scroll-user {
			.wrapp-select-user {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				width: 300px;
				min-height: 40px;
			}
			.header-text {
				margin-top: 16px;
				margin-bottom: 8px;
				margin-left: 61px !important;
				width: calc(100% - 61px);
				display: flex;
				justify-content: space-between;
			}
			.modern-rbox {
				display: flex;
				align-items: center;
				margin: 6px 0;
				input {
					margin-right: 30px;
				}
			}
			.responsible-user {
				margin-left: auto;
				padding-right: 24px;
				cursor: pointer;
			}
			.created-by-wrapper {
				width: calc(100% - 93px);
			}
		}
		.alt {
			margin-right: 20px;
		}
	}
}
label {
	.edit {
		margin-left: 10px;
		cursor: pointer;
		width: 13px;
	}
}
.red {
	color: $status-color-red;
	cursor: pointer;
	margin-left: 10px;
}
.mh510 {
	max-height: 510px;
	overflow-x: auto;
}

@media only screen and (max-width: 1360px) {
	.list-preview {
		.content {
			width: 100%;
			display: flex;
			// flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-around;
			gap: 30px; //  40px

			.input-group {
				max-width: 50%;
				min-width: 300px;
			}
		}
	}
}
</style>
<style lang="scss">
.list-preview {
	.content {
		.input-group {
			.group {
				.txt {
					ol,
					ul,
					menu {
						list-style: initial;
						margin: initial;
						padding-left: 20px;
					}
					ol {
						list-style: decimal;
					}
					// li{
					// 	margin-left: 20px;
					// }
				}
			}
		}
	}
}
</style>
