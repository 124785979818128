<script setup lang="ts">
import { useCandidateStore } from "@/store/candidate";
const candidateStore = useCandidateStore();

definePage({
	name: "ComputerSkills",
});

const formItems = computed(() => candidateStore.formUser.computerSkills);
</script>

<template lang="pug">
.page
	.form-wrapper
		modern-form
			template(#logo)
				.logo-placeholder
			template(#contentInner)
				form-comp.form-inputs
					template(v-for="(form, _index) in formItems.getItems", :key="_index")
						.row
							.group
								label Add skills
								multiselect-form(
									v-model="form.cSkillsArr",
									mode="tags",
									:searchable="true",
									:create-option="true",
									:close-on-select="true",
									:options="form.cSkillsArr",
									:append-to-body="true"
								)
			template(#action)
				slot
	.right-side
		img(src="@/assets/image/auth/reg/1.png")
		//- input-img(
		//- 	:old-image="candidateStore.registerImageSrc",
		//- 	@set-image="onSetImage",
		//- 	@delete="onDeleteImage"
		//- )
</template>

<style lang="scss" scoped>
.page {
	@include candidate-profile-page;
}
</style>
