<script setup lang="ts">
definePage({
	name: "PostPage",
});
</script>

<template lang="pug">
.post-page
	wrappPosts
	.wrappSide
		wrappSideCommunity(:title="$t('app.community.topCommunities')")
		.placeholder
			img(src="@/assets/image/sideImg.png")
	//- groupCommunityPosts
	//- wrappSideCommunity
</template>

<style lang="scss" scoped>
.post-page {
	// all with side menu 1489
	max-width: 1224px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.wrappSide {
		width: 394px;
		.placeholder {
			width: 100%;
			max-width: 394px;
			height: fit-content;
			img {
				width: 100%;
				// height: 384px;
			}
		}
	}
}

@media only screen and (max-width: 1459px) {
	.post-page {
		.wrappSide {
			width: 100%;
			max-width: 817px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
}
</style>
