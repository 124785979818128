<script setup lang="ts">
import api from "@/api";
import { $t } from "@/i18n";
// import { useDefaultStore } from "@/store";
import type { TUserData, TListing, TListingEdit } from "@/assets/js/types";
import { useToastStore } from "@/store/toast";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTokenStore } from "@/store/token";
import userImg from "@/assets/image/icons/user.svg";
import userSelectedImg from "@/assets/image/icons/userSelected.svg";
import type { TCountryItem } from "@/api/types";
import { useRoute, useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";
// import { baseURL } from "@/api/config";

// const store = useDefaultStore();
const toastStore = useToastStore();
dayjs.extend(utc);

const router = useRouter();
const route = useRoute();
definePage({
	name: "ListingPreview",
	path: "/company/listing-preview/:id",
});

const tokenStore = useTokenStore();

const emit = defineEmits(["returnOnList", "editList"]);

// const props = defineProps<{
// 	data: any;
// }>();

const assigments = ref<number[]>([]);
const assign = ref<number[]>([]);
const users_add = ref<number[] | []>([]);
const users_delete = ref<number[] | []>([]);

function setAssigned(e: number) {
	if (assign.value.includes(e)) {
		assign.value = assign.value.filter((id) => id !== e);
		return;
	}
	assign.value.push(e);
}

function checkAssigned() {
	if (assigments.value && Array.isArray(assigments.value)) {
		users_add.value = assign.value.filter(
			(item) => !assigments.value.includes(item),
		);
		users_delete.value = assigments.value.filter(
			(item) => !assign.value.includes(item),
		);
	} else {
		users_add.value = assign.value;
	}
}

// FILTERS

interface IClientType {
	cli_id: number;
	cli_name: string;
}

const users = ref<TUserData[]>([]);
const usersSearched = ref<TUserData[]>([]);
const edit = ref(false);
const editNotes = ref(false);

const search = ref<string>("");
function searchUsers() {
	const help = users.value.filter((item) =>
		item.usr_name.toLowerCase().includes(search.value.toLowerCase()),
	);
	usersSearched.value = help;
}

watch([search], async () => {
	// filter users
	searchUsers();
});

const form = ref<TListing | any>({});
const data = ref<TListing | any>({});
// ({
// job_title: "Test Job", // s 255 R
// cnt_code: "sr", // s

// job_shortinfo: "", // s
// job_expires: "2024-11-11", // d R
// job_active: "2024-1-11", // d R
// job_externallink: "httpsmmmm", // s
// job_reqportfolio: false,
// job_reqvideo: false, //
// job_reqrecommendations: false, //
// job_description: "Test description ", // s 6000
// job_reqadditional: "Testttt  additional", // s
// usr_id_responsible: 49, // i

// cmp_id: 4, // i
// cli_id: 2, // i R

// job_status: 'Draft' , // "Draft","Published","Paused","Unlisted","Finished","Cancelled"
// });

const client = ref<IClientType[]>([]);
const selectedClient = ref<IClientType | null>(null);

const selectedCountry = ref<TCountryItem | null>(null);
const countries = ref<TCountryItem[] | []>([]);

async function getSingleJob() {
	const params = {
		view: "company",
		job_id: route.params.id,
	};
	try {
		const res = await api.getSingleJob(params);
		data.value = res.data.data;
		data.value.job_expires = formatLocalDate(res.data.data.job_expires); // job_expires job_active
		data.value.job_active = formatLocalDate(res.data.data.job_active);
		form.value = JSON.parse(JSON.stringify(res.data.data));
		form.value.job_expires = formatLocalDate(form.value.job_expires);
		form.value.job_active = formatLocalDate(form.value.job_active);

		if (res.data.data.job_attributes) {
			data.value.job_attributes = JSON.parse(data.value.job_attributes);
			setNotes();
		} else {
			data.value.job_attributes = { job_notes: "" };
			form.value.job_attributes = { job_notes: "" };
		}
		return true;
	} catch (e) {
		if (e.response.status === 303) {
			void router.push({
				name: "JobPreview",
				params: { id: route.params.id },
			});
		}
		return false;
	}
}

async function getUsers() {
	try {
		const res = await api.getUsers({ cmp_id: tokenStore.userData?.cmp_id });
		users.value = res.data.data;
		usersSearched.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

async function getCompaniesClient() {
	try {
		const res = await api.getCompaniesClient({
			cmp_id: tokenStore.userData?.cmp_id,
		});
		client.value = res.data.data.data;
	} catch (e) {
		console.log(e);
	}
}

function setClient() {
	const help = client.value.find((item) => item.cli_id === data.value.cli_id);
	if (help) {
		selectedClient.value = help;
	}
}
function setCountry() {
	const help = countries.value.find(
		(item: TCountryItem) =>
			item.cnt_code.toLowerCase() === data.value.cnt_code.toLowerCase(),
	);
	if (help) {
		selectedCountry.value = help;
	}
}
function setSelectedCountry(e: any) {
	// console.log(e);
	form.value.cnt_code = e.cnt_code;
}

function setSelectedClients(e: any) {
	form.value.cli_id = e.cli_id;
}

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
		// console.log(countries.value);
	} catch (e) {
		console.log(e);
	}
}

async function saveChanges() {
	checkAssigned();

	const params: TListingEdit = {};

	for (const key in form.value) {
		// Object.prototype.hasOwnProperty.call(props.data, key)
		if (form.value[key] !== data.value[key]) {
			switch (key) {
				case "job_expires":
				case "job_active": {
					params[key] = DateInstance.getUtcFromDate(
						form.value[key],
						"DD.MM.YYYY. HH:mm",
					);
					break;
				}
				case "job_reqportfolio":
				case "job_reqvideo":
				case "job_reqrecommendations": {
					// @ts-expect-error
					params[key] = form.value[key] ? 1 : 0;

					break;
				}
				case "cnt_code": {
					params[key] = selectedCountry.value?.cnt_code;

					break;
				}
				case "cli_id": {
					params[key] = selectedClient.value?.cli_id;

					break;
				}
				default: {
					params[key] = form.value[key];
				}
			}
		}
	}

	if (
		Object.keys(params).length === 0 &&
		users_add.value.length <= 0 &&
		users_delete.value.length <= 0
	) {
		toastStore.openToastError("Nothing changed");
		return;
	}

	if (Object.keys(params).length !== 0) {
		params.job_id = data.value.job_id;
		await sendEdit(params);
	}

	if (users_add.value.length > 0 || users_delete.value.length > 0) {
		await sendAssignedEdit();
	}
}

async function sendEdit(params: any) {
	try {
		// console.log(params);
		const res = await api.jobsEdit(params);
		console.log(res.data.data);
		toastStore.openToastSuccess("Listing updated successfully.");
		if (editNotes.value) {
			editNotes.value = false;
		}
		await refresh();
	} catch (e: any) {
		console.log(e.response.data.data);
		if (e.response.data.data) {
			toastStore.openToastError("Nothing changed");
			return;
		}
		toastStore.openToastError("Error");
	}
}

async function sendAssignedEdit() {
	type TParam = {
		job_id: number;
		users_add?: number[];
		users_delete?: number[];
	};

	const params: TParam = {
		// @ts-expect-error
		job_id: route.params.id,
	};
	if (users_add.value.length > 0) {
		params.users_add = users_add.value;
	}
	if (users_delete.value.length > 0) {
		params.users_delete = users_delete.value;
	}

	try {
		// console.log(params);
		const res = await api.assigmentsEdit(params);
		console.log(res.data.data);
		toastStore.openToastSuccess("Assigned user changed successfully");
		// emit("returnOnList", true);
		await refresh();
	} catch (e: any) {
		if (e.response.data.data === "invalid-users_add") {
			toastStore.openToastError("Invalid users");
			return;
		}
		toastStore.openToastError("Error");
	}
}

async function getAssigments() {
	try {
		const res = await api.getAssigments({ job_id: route.params.id });
		assigments.value = [];
		assign.value = [];
		for (let i = 0; i < res.data.data.length; i++) {
			// data to compare
			assigments.value.push(res.data.data[i].usr_id);
			// data to change
			assign.value.push(res.data.data[i].usr_id);
		}
		// console.log(assigments.value);
	} catch (e) {
		console.log(e);
	}
}

function formatLocalDate(date: string, format = "YYYY-MM-DD HH:mm") {
	// removed locale
	// return dayjs.utc(date).format("DD.MM.YYYY. HH:mm");
	return DateInstance.getFormatDTLocale(date, format);
}

function limitTextareaShortInfo() {
	const maxLength = 255;
	let remainingChars = maxLength - form.value.job_shortinfo.length;
	if (remainingChars < 0) {
		form.value.job_shortinfo = form.value.job_shortinfo.slice(0, maxLength);
		remainingChars = 0;
	}
}

function limitTextareaAdditional() {
	const maxLength = 255;
	let remainingChars = maxLength - form.value.job_reqadditional.length;
	if (remainingChars < 0) {
		form.value.job_reqadditional = form.value.job_reqadditional.slice(
			0,
			maxLength,
		);
		remainingChars = 0;
	}
}
function setEdit() {
	edit.value = true;
	editNotes.value = false;
}
function resetEdit() {
	edit.value = false;
	form.value = JSON.parse(JSON.stringify(data.value));
	setNotes();
}
function resetNotes() {
	editNotes.value = false;
	setNotes();
}
async function refresh() {
	edit.value = false;
	await getSingleJob();
	await getAssigments();
	setClient();
	setCountry();
}
function setNotes() {
	if (
		data.value.job_attributes === null
		// || typeof data.value.job_attributes === "string"
	) {
		form.value.job_attributes = { job_notes: "" };
	} else {
		form.value.job_attributes = {};
		form.value.job_attributes.job_notes = data.value.job_attributes.job_notes;
	}
}
onMounted(async () => {
	// the logic of checking in the store whether the user is a company is not worth it,
	// because now links are copied and sent to users as well as to companies, and if he is logged in as a company,
	// the other APIs will throw him out so that he will not be redirected, which is what status 303 does
	const check = await getSingleJob();

	if (check) {
		await getUsers();
		await getAssigments();
	}

	// if (props.data.assign) {
	// 	assign.value = JSON.parse(JSON.stringify(props.data.assign));
	// }
	if (tokenStore.can("job-edit") && check) {
		await getCountries();
		await getCompaniesClient();
		setClient();
		setCountry();
	}
});
function copy(id: number) {
	// let link = window.location.href.replace(
	// 	"/company/listing-preview",
	// 	"/manage/job-preview",
	// );
	let link = window.origin + "/manage/job-preview/" + id;

	navigator.clipboard.writeText(link);
	toastStore.openToastInfo($t("app.messages.copyLink"));
}
</script>
<template lang="pug">
.list-preview
	.main(v-if="form")
		.up
			.wrapp-title
				.line
				.w-t
					.arrow-left(@click="router.back()")
					.title {{ $t("app.companies.listingPreview") }}

			.wrapp-action(v-if="tokenStore.can('job-edit')")
				img.copy(
					v-if="data.job_id && (data.job_status.toLowerCase() === 'unlisted' || data.job_status.toLowerCase() === 'published')",
					src="@/assets/image/icons/copy.svg",
					title="copy",
					@click="copy(data.job_id)"
				)
				.btn(v-if="!edit", @click="setEdit")
					span {{ $t("app.companies.editListing") }}
				.btn(v-if="edit", @click="saveChanges")
					span {{ $t("app.companies.saveChanges") }}
				.btn(v-if="edit", @click="resetEdit")
					span {{ $t("app.companies.cancel") }}
		.content
			.input-group
				candidates-list(v-if="data.job_id", :job-id="data.job_id")
				.group(style="margin-top: 20px")
					label {{ $t("app.companies.notes") }}
						img.edit(
							v-if="!editNotes && !edit && tokenStore.can('job-edit')",
							src="@/assets/image/actionIcons/edit.svg",
							alt="edit",
							title="edit notes",
							@click="editNotes = true"
						)
						span.red(
							v-else-if="!edit && tokenStore.can('job-edit')",
							@click="saveChanges"
						) {{ $t("app.companies.save") }}
						span.red(
							v-if="editNotes && !edit && tokenStore.can('job-edit')",
							@click="resetNotes"
						) {{ $t("app.companies.reset") }}
					textarea(
						v-if="edit || editNotes",
						v-model="form.job_attributes.job_notes",
						@input="limitTextareaShortInfo"
					)
					pre.txt(v-else-if="form.job_attributes?.job_notes") {{ form.job_attributes.job_notes }}
			.input-group
				.group
					label {{ $t("app.companies.listingName") }}
					input(
						v-if="edit",
						v-model="form.job_title",
						type="text",
						:placeholder="$t('app.companies.listingName')"
					)
					.txt(v-else) {{ form.job_title }}
				.group
					label {{ $t("app.companies.country") }}
					.dropdown(v-if="edit")
						multiselect-form(
							v-model="selectedCountry",
							:options="countries",
							label="cnt_name",
							value-prop="cnt_code",
							placeholder="All countries",
							@select="setSelectedCountry"
						)
					.txt(v-else) {{ tokenStore.can("job-edit") ? selectedCountry?.cnt_name : form.cnt_name }}
				.group
					label {{ $t("app.companies.shortDescription") }}
					textarea(
						v-if="edit",
						v-model="form.job_shortinfo",
						@input="limitTextareaShortInfo"
					)
					.txt(v-else) {{ form.job_shortinfo }}
				.group
					label {{ $t("app.companies.client") }}
					.dropdown(v-if="edit")
						multiselect-form(
							v-model="selectedClient",
							:options="client",
							label="cli_name",
							value-prop="cli_id",
							placeholder="All clients",
							@select="setSelectedClients"
						)
					.txt(v-else) {{ tokenStore.can("job-edit") ? selectedClient?.cli_name : form.cli_name }}
				.group
					label {{ $t("app.companies.dateFrom") }}
					modern-date-picker(
						v-if="edit",
						v-model="form.job_active",
						:enable-time-picker="true",
						:max-date="form.job_expires"
					)
					.txt(v-else) {{ DateInstance.getFormattedDate(form.job_active, "DD. MMM YYYY.") }}
				.group
					label {{ $t("app.companies.expirationDate") }}
					modern-date-picker(
						v-if="edit",
						v-model="form.job_expires",
						:enable-time-picker="false",
						:min-date="form.job_active"
					)
					.txt(v-else) {{ DateInstance.getFormattedDate(form.job_expires, "DD. MMM YYYY.") }}

				.group
					label {{ $t("app.companies.addExternallink") }}
					input(
						v-if="edit",
						v-model="form.job_externallink",
						type="text",
						placeholder="https.."
					)
					.txt(v-else) {{ form.job_externallink }}
				.box
					.txt-label {{ $t("app.companies.qReqVideo") }}
					.flex-option
						.modern-rbox(v-if="edit", @click="form.job_reqvideo = true")
							input(type="radio", :checked="form.job_reqvideo")
							label Yes
						.modern-rbox(v-if="edit", @click="form.job_reqvideo = false")
							input(type="radio", :checked="!form.job_reqvideo")
							label No
						.modern-rbox(v-else)
							.txt(style="margin-bottom: 0") {{ form.job_reqvideo ? "Yes" : "No" }}
				.box
					.txt-label {{ $t("app.companies.qReqCV") }}
					.flex-option
						.modern-rbox(v-if="edit", @click="form.job_reqportfolio = true")
							input(type="radio", :checked="form.job_reqportfolio")
							label Yes
						.modern-rbox(v-if="edit", @click="form.job_reqportfolio = false")
							input(type="radio", :checked="!form.job_reqportfolio")
							label No
						.modern-rbox(v-else)
							.txt(style="margin-bottom: 0") {{ form.job_reqportfolio ? "Yes" : "No" }}
				.box
					.txt-label {{ $t("app.companies.qReqRecommendations") }}
					.flex-option
						.modern-rbox(v-if="edit", @click="form.job_reqrecommendations = true")
							input(type="radio", :checked="form.job_reqrecommendations")
							label Yes
						.modern-rbox(v-if="edit", @click="form.job_reqrecommendations = false")
							input(type="radio", :checked="!form.job_reqrecommendations")
							label No
						.modern-rbox(v-else)
							.txt(style="margin-bottom: 0") {{ form.job_reqrecommendations ? "Yes" : "No" }}

			.input-group
				.group.textarea
					label {{ $t("app.companies.reqAdditional") }}
					textarea(
						v-if="edit",
						v-model="form.job_reqadditional",
						@input="limitTextareaAdditional"
					)
					.txt(v-else) {{ form.job_reqadditional }}
				.group.editor
					label {{ $t("app.companies.fLDescription") }}
					//- textarea.long(v-if="edit", v-model="form.job_description")
					modern-editor(
						v-if="edit",
						ref="EditorRef",
						v-model="form.job_description"
					)
					.txt.mh510.scroll(v-else, v-html="form.job_description")
					//- {{ form.job_description }}
				.group
					label {{ $t("app.companies.assigned") }}
					search-input(
						v-if="edit",
						style="max-width: 100%",
						placeholder="Type something...",
						:search-value="search",
						@update:search-value="search = $event"
					)
					.scroll-user(v-if="edit")
						.wrapp-select-user
							.header-text
								label {{ $t("app.companies.assignedEmployee") }}
								label {{ $t("app.companies.holder") }}
						.wrapp-select-user(v-for="(user, index) in usersSearched", :key="index")
							.modern-rbox(@click="setAssigned(user.usr_id)")
								input(type="radio", :checked="assign.includes(user.usr_id)")
							show-user(:user="user")
							.responsible-user(@click="form.usr_id_responsible = user.usr_id")
								img(
									v-if="form.usr_id_responsible === user.usr_id",
									:src="userSelectedImg",
									alt="selected"
								)
								img(v-else, :src="userImg", alt="not selected")

					.scroll-user(v-else)
						div(v-for="(user, index) in usersSearched", :key="index")
							.wrapp-select-user(
								v-if="form.usr_id_responsible === user.usr_id || assign.includes(user.usr_id)"
							)
								show-user(:user="user")
								img(
									v-if="form.usr_id_responsible === user.usr_id",
									:src="userSelectedImg",
									alt="selected"
								)
</template>
<style lang="scss" scoped>
.list-preview {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-action {
			display: flex;
			align-items: center;
			.btn {
				// margin-top: 7px;
				margin-left: 20px;
			}
		}
	}

	.content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 30px; //  40px

		.input-group {
			display: flex;
			flex-direction: column;
			align-items: baseline;
			max-width: 26%;
			height: fit-content;
			.group.textarea {
				max-height: 150px;
				margin-bottom: 10px;
			}
			.group.editor {
				height: 530px;
				margin-bottom: 10px;
			}
			textarea {
				height: 100px;
			}
			textarea.long {
				height: 530px;
			}
			.txt {
				font-size: 13px;
				font-weight: 600;
				line-height: 18px;
				letter-spacing: 0;
				margin-bottom: 20px;
			}
			pre {
				font-family: "Open Sans", sans-serif;
				// font-size: 13px;
				font-weight: 600 !important;
				// line-height: 18px;
				// letter-spacing: 0;
				// text-align: left;
				text-wrap: wrap;
			}
		}
		.input-group:first-of-type {
			max-width: 40%;
		}
		.dropdown {
			padding: 0 10px;
			border-radius: $input-radius;
			// color: $text-color;
			// font-size: 14px;
			outline: none;
			// background-color: transparent;
			// text-indent: 10px;
			border: 1px solid;
			border-color: $input-border-color;
			height: $input-height;
			min-width: 150px;
			width: 100%;
			align-items: center;
			// text-overflow: ellipsis;
		}
		.box {
			margin-top: 15px;
			.txt-label {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color-light;
				margin-bottom: 10px;
			}

			.flex-option {
				display: flex;
			}
			.modern-rbox {
				display: flex;
				margin-right: 40px;
				input {
					margin-right: 10px;
				}
			}
		}
		.scroll-user {
			.wrapp-select-user {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				width: 300px;
				min-height: 40px;
			}
			.header-text {
				margin-top: 16px;
				margin-bottom: 8px;
				margin-left: 61px !important;
				width: calc(100% - 61px);
				display: flex;
				justify-content: space-between;
			}
			.modern-rbox {
				display: flex;
				align-items: center;
				margin: 6px 0;
				input {
					margin-right: 30px;
				}
			}
			.responsible-user {
				margin-left: auto;
				padding-right: 24px;
				cursor: pointer;
			}
			.created-by-wrapper {
				width: calc(100% - 93px);
			}
		}
		.alt {
			margin-right: 20px;
		}
	}
}
label {
	.edit {
		margin-left: 10px;
		cursor: pointer;
		width: 13px;
	}
}
.red {
	color: $status-color-red;
	cursor: pointer;
	margin-left: 10px;
}
.mh510 {
	max-height: 510px;
	overflow-x: auto;
}
.copy {
	margin-left: 20px;
	cursor: pointer;
}

@media only screen and (max-width: 1360px) {
	.list-preview {
		.content {
			width: 100%;
			display: flex;
			// flex-direction: column;
			flex-wrap: wrap;
			justify-content: space-around;
			gap: 30px; //  40px

			.input-group {
				max-width: 50%;
				min-width: 300px;
			}
		}
	}
}
</style>
<style lang="scss">
.list-preview {
	.content {
		.input-group {
			.group {
				.txt {
					ol,
					ul,
					menu {
						list-style: initial;
						margin: initial;
						padding-left: 20px;
					}
					ol {
						list-style: decimal;
					}
					// li{
					// 	margin-left: 20px;
					// }
				}
			}
		}
	}
}
</style>
