<script lang="ts" setup>
import api from "@/api";
import { useRoute, useRouter } from "vue-router";
import { openReportedRemoveRestore } from "../../components/modals/modalDefinitions";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import { $t } from "@/i18n";
import { DateInstance } from "@/assets/js/dateHelper";

const route = useRoute();
const router = useRouter();

definePage({
	name: "ReoprtedData",
	path: "/admin/reported/:id",
});

//  const props = withDefaults(defineProps<{
// 	data: any;
//     showReport?: boolean;
//     wide?: boolean;
// }>(), {
//     showReport: false,
//     wide: false,
// });

const emit = defineEmits(["report"]);

const columns = reactive<TTableColumn[]>([
	{
		id: "usr_name",
		label: $t("app.listings.listingName"),
	},
	{
		id: "usr_email",
		label: $t("app.listings.company"),
	},
	{
		id: "jrm_date",
		label: $t("app.listings.dateReported"),
	},
	// {
	// 	id: "jrm_remark",
	// 	label: $t("app.listings.company"),
	// },
	{
		id: "action",
		label: "",
	},
]);

const users = ref<TTableRow[]>([]);
const jobDetails = ref<any>();
const showRowIndex = ref<number[]>([]);

async function getJobData() {
	try {
		const res = await api.getReportedJob({ job_id: route.params.id });

		jobDetails.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

async function getReportedUsers() {
	try {
		const res = await api.getReportedUsers({ job_id: route.params.id });

		users.value = res.data.data.data;
	} catch (e) {
		console.log(e);
	}
}

function formatDate(date: string) {
	if (date) return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY."); // HH:mm
}

function goToDetails(e: any) {
	// Users who do not have an account can also report
	if (e.usr_id) {
		void router.push({
			name: "PublicProfile",
			params: { id: e.usr_id },
		});
	}
}

const toggleShowRow = (index: number) => {
	if (showRowIndex.value.includes(index)) {
		showRowIndex.value = showRowIndex.value.filter((i) => i !== index);
		return;
	}
	showRowIndex.value.push(index);
};

function setRemove(action: string) {
	// action lock || unlock
	if (action === "lock" || action === "unlock") {
		openReportedRemoveRestore({ data: jobDetails.value }, action, onConfirm);
	}
}
async function onConfirm() {
	await getJobData();
}

onMounted(async () => {
	await getJobData();
	await getReportedUsers();
});
</script>
<template lang="pug">
.reported-data
	.main
		.up
			.wrapp-title
				.line
				.w-t(@click="router.back()")
					.arrow-left
					.title {{ $t("app.reportedListings.reportedListing") }}
			.wrapp-action(v-if="jobDetails")
				.btn(v-if="!jobDetails.job_adminlocked", @click="setRemove('lock')")
					span {{ $t("app.listings.lock") }}
				.btn(v-if="jobDetails.job_adminlocked", @click="setRemove('unlock')")
					span {{ $t("app.listings.unlock") }}
		.data
			.wrapp-table
				modern-table(
					:rows="users",
					:columns="columns",
					:has-border="false",
					:display-additional-row="true",
					:isInfiniteScroll="false",
					@scroll-more="getReportedUsers"
				)
					template(#[`table-body-cell`]="slotProps")
						td.date(
							v-if="slotProps.column.id == 'jrm_date' || slotProps.column.id == 'postedDate'",
							:style="{ cursor: slotProps.row?.usr_id ? 'pointer' : '' }",
							:title="formatDate(slotProps.row[slotProps.column.id])",
							@click="goToDetails(slotProps.row)"
						)
							span {{ formatDate(slotProps.row[slotProps.column.id]) }}
						td(
							v-else-if="slotProps.column.id === 'usr_email'",
							:style="{ cursor: slotProps.row?.usr_id ? 'pointer' : '' }",
							:title="slotProps.row[slotProps.column.id] ? slotProps.row[slotProps.column.id] : slotProps.row.jrm_email"
						) {{ slotProps.row[slotProps.column.id] ? slotProps.row[slotProps.column.id] : slotProps.row.jrm_email }}
						td.action(v-else-if="slotProps.column.id === 'action'")
							.show-more(@click="toggleShowRow(slotProps.row.jrm_id)") {{ showRowIndex.includes(slotProps.row.jrm_id) ? "Show less" : "Show more" }}
								img(src="@/assets/image/tablecaret.svg")
						td(
							v-else,
							:title="slotProps.row[slotProps.column.id]",
							:class="{ bold: slotProps.column.id === 'usr_name' }",
							:style="{ cursor: slotProps.row?.usr_id ? 'pointer' : '' }",
							@click="goToDetails(slotProps.row)"
						) {{ slotProps.row[slotProps.column.id] }}
					template(#table-body-extra-row-content="slotProps")
						transition(name="show-row")
							td.info-row(
								v-if="showRowIndex.includes(slotProps.row.jrm_id)",
								:colspan="columns.length"
							)
								span {{ slotProps.row.jrm_remark }}
			listing-data(
				v-if="jobDetails",
				:data="jobDetails",
				:show-report="false",
				:setWide="false"
			)
</template>
<style lang="scss" scoped>
.reported-data {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;
		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				white-space: nowrap;
			}
		}

		.wrapp-action {
			display: flex;
			align-items: center;
			.btn {
				margin-bottom: 7px;
				margin-left: 20px;
			}
		}
		.data {
			width: 100%;
			display: flex;

			.wrapp-table {
				min-width: 45%;
				margin-right: 10px;
			}
		}
	}
}

td {
	// cursor: pointer;
	max-width: 100px;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 4px;
}
td.date {
	width: 86px !important;
}
td.action {
	width: 105px;
}

.show-more {
	width: fit-content;
	margin-left: auto;
	cursor: pointer;
	display: flex;
	img {
		margin-left: 5px;
		transition: transform 0.4s ease-in-out;
	}

	.rotate {
		transform: rotate(180deg);
	}
}
.info-row {
	box-sizing: border-box;
	max-width: 100%;
	padding: 20px 10px;
	text-wrap: initial;
}
.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media only screen and (max-width: 1260px) {
	.reported-data {
		// set style
	}
}
</style>
