<script setup lang="ts">
import api from "@/api";
import { useToastStore } from "@/store/toast";

definePage({
	name: "SettingsSocial",
});

const form = ref({
	profile: "",
	link: "",
});

async function onClick() {
	const params = { ...form.value };
	try {
		const res = await api.getCV(params);
		console.warn(res.data);
		useToastStore().openToastSuccess("Added");
	} catch (err) {
		console.warn(err.message);
		useToastStore().openToastError("Error");
	}
}
</script>

<template lang="pug">
.social
	.column1
		.input-group
			.label Add profile
			input(v-model="form.profile")
		.input-group
			.label Insert link
			input(v-model="form.link")
		.action-group.mt-4.px-1
			button(@click="onClick")
				span Add
	.column2
		social-icon
</template>

<style lang="scss" scoped>
.social {
	display: flex;
	gap: 10px;

	.column1,
	.column2 {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.input-group {
		display: flex;
		flex-direction: column;
		gap: 3px;
		align-items: flex-start;

		.label {
			font-size: 0.7rem;
			color: $text-color-light;
		}
	}
}
</style>
