<script setup lang="ts">
definePage({
	name: "LoginPage",
});
</script>

<template lang="pug">
#login-page
	.image-section
		.wrapp-image
			img(alt="placeholder-image", src="@/assets/image/auth/login.png")
		.title
			span {{  $t("app.auth.loginImageTitle")+ ' '  }}
			span.blue {{  $t("app.auth.loginImageTitleSecond")+ ' '  }}
			span {{ $t("app.auth.loginImageTitleThird") }}
		.desc {{ $t("app.auth.loginImageDesc") }}

	.comp-section
		login-comp
</template>

<style lang="scss">
#login-page {
	display: flex;
	flex-direction: column;
	gap: 40px 170px;
	height: 100%;
	width: 100%;

	.image-section,
	.comp-section {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.image-section {
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 70px;
		gap: 20px;

		.title {
			margin-top: 20px;
			// font-family: Anton, sans-serif;

			// font-size: clamp(1rem, 4vw, 60px);
			// line-height: normal;
			// font-weight: 800;
			// margin-bottom: 30px;
			font-family: "Libre Baskerville", sans-serif;
			font-size: 62px;
			font-weight: 400;
			line-height: 80.6px;
			text-align: left;
		}

		.desc {
			font-family: Inter, sans-serif;
			font-size: 26px;
			font-weight: 400;
			line-height: 39px;
			text-align: left;
		}

		img {
			width: 100%;
			max-width: 900px;
		}
	}

	.blue {
		color: $app-accent-color1;
		font-style: italic;
	}
	.form {
		.logo-wrapper {
			// gap: 40px;

			.logo {
				width: 80px;
				height: 81px;
				margin: auto;
			}
		}
	}

	@media screen and (width >= 768px) {
		flex-direction: row;

		.image-section {
			flex-basis: 80%;
		}
		.comp-section {
			min-width: 400px;

			> * {
				width: 100%;
			}
		}
	}
}
</style>
