<script setup lang="ts">
import { useRouter } from "vue-router";

definePage({
	name: "CatchAllPage",
});

// let timeout: ReturnType<typeof setTimeout> | null = null;
const router = useRouter();

const countdownValue = ref(5);
function updateCountdown() {
	if (countdownValue.value > 0) {
		countdownValue.value--;
	} else {
		clearInterval(countdownInterval);
		void router.replace("/manage/job-listing");
	}
}
let countdownInterval: any = null;
onMounted(() => {
	// timeout = setTimeout(() => {
	// 	void router.replace("/manage/job-listing");
	// }, 55000);

	countdownInterval = setInterval(updateCountdown, 1000);
});

onUnmounted(() => {
	// timeout && clearTimeout(timeout);
});
</script>

<template lang="pug">
.no-page
	img(src="@/assets/image/404.png", alt="404")
	span.title Page not found!
	span You will be redirected in {{ countdownValue }} seconds
</template>

<style lang="scss" scoped>
.no-page {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	.title {
		font-size: 22px;
		font-weight: 600;
		line-height: 30.8px;
		text-align: center;
	}
}
</style>
