<script setup lang="ts">
definePage({
	name: "AboutPage",
	redirect: "/auth/login",
});
</script>

<template lang="pug">
.about-page
	.up
		.title {{ $t("app.about.upTitle1") }}
			br
			span {{ $t("app.about.upTitle2") }}
		.txt {{ $t("app.about.upTxt") }}

	.bottom
		.bottomCover
			.wrappTxt
				.title {{ $t("app.about.mission") }}
				.txt {{ $t("app.about.bTxt") }}
			.wrappImg
				img(src="@/assets/image/about.png")
</template>

<style lang="scss" scoped>
.about-page {
	// all with side menu 1489
	max-width: 1224px;
	width: 100%;
	padding: 40px;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;
	.up {
		margin: auto;
		margin-top: calc(117px - 40px);
		margin-bottom: 87px;
		width: 70%;
		text-align: center;
	}
	.bottom {
		background-image: linear-gradient(
			rgba(246, 167, 18, 1),
			rgba(228, 78, 117, 1)
		);
		padding: 4px;
		border-radius: 40px;

		.bottomCover {
			border-radius: 37px;
			padding: 66px 50px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			background-color: $background-color;
		}
	}
	.wrappTxt {
		box-sizing: border-box;
		width: calc(60% - 65px);
	}
	.wrappImg {
		box-sizing: border-box;
		width: 40%;
		img {
			width: 100%;
		}
	}

	// in common
	.title {
		// font-family: Anton, sans-serif;
		font-size: 60px;
		font-weight: 400;
		line-height: 90px;
		letter-spacing: 0;
		margin-bottom: 30px;

		span {
			color: $text-color-tirkiz;
		}
	}
	.txt {
		font-family: "Open Sans", sans-serif;
		font-size: 24px;
		font-weight: 400;
		line-height: 33px;
		letter-spacing: 0;
	}
}

@media only screen and (max-width: 1250px) {
	.about-page {
		flex-direction: column;
		align-items: center;
		.wrappTxt,
		.wrappImg {
			width: 70%;
		}
	}
}

@media only screen and (max-width: 767px) {
	.about-page {
		padding: 20px;
		.up {
			margin-top: 0;
			width: 100%;
			text-align: center;
			// margin-bottom: 60px;
		}
		.bottom {
			padding: 4px;

			.bottomCover {
				padding: 30px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				background-color: $background-color;
			}
		}
		.wrappTxt,
		.wrappImg {
			width: 100%;
		}
		.wrappImg {
			img {
				width: 70%;
				margin: auto;
				margin-top: 40px;
			}
		}

		.title {
			font-size: 40px;
			line-height: 60px;
			letter-spacing: 0;
			text-align: center;

			span {
				color: $text-color-tirkiz;
			}
		}
		.txt {
			font-size: 18px;
			font-weight: 400;
			line-height: 23px;
		}
	}
}
</style>
