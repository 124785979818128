<script setup lang="ts">
import { $t } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
import type { TCompany } from "@/assets/js/types";
import { DateInstance } from "@/assets/js/dateHelper";
import api from "@/api";
import { openChangePlan } from "../../components/modals/modalDefinitions";
import { useToastStore } from "@/store/toast";

const emit = defineEmits(["returnOnList"]);

definePage({
	name: "AdminCompanyInformation",
	path: "/admin/company-information/:id",
});

const route = useRoute();
const router = useRouter();

const plans = ref([
	{ id: "FREE", label: "Freemium" },
	{ id: "P1", label: "Premium" },
]);

const company = ref<TCompany>();

async function getCompanySingle() {
	try {
		const res = await api.getAdminCompanySingle({ cmp_id: route.params.id });

		company.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}

function formatTime(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "HH:mm");
	}
}

// function pause() {
// 	console.log("pause");
// }

// function stop() {
// 	console.log("stop");
// }

// function reverseSet() {
// 	console.log("reverseSet");
// }

function updatePackage() {
	// DORADI
	openChangePlan(company.value, getChangePlan);
}

function getChangePlan(e: any) {
	getCompanySingle();
}

async function verify() {
	if (!company.value.cmp_id) {
		return;
	}

	const params = {
		cmp_id: company.value.cmp_id,
	};
	try {
		await api.adminVerifyCompanies(params);

		useToastStore().openToastSuccess("Successfully verified company.");
		await getCompanySingle();
	} catch (e) {
		console.log(e);
		useToastStore().openToastError("Error, please try again.");
	}
}
function writePlan() {
	const res = plans.value.find(
		(el) => el.id === company.value?.cmp_plan?.toUpperCase(),
	);
	if (res) {
		return res.label;
	}
	return null;
}

onMounted(async () => {
	await getCompanySingle();
});
</script>

<template lang="pug">
.company-information-admin
	.wrappUp(v-if="company")
		.wrapp-title
			.line
			.w-t(@click="router.back()")
				.arrow-left
				.title {{ $t("app.companies.companyInformation") }}
		button.alt.small(:disabled="!!company.usr_emailverified", @click="verify")
			span {{ company.usr_emailverified ? $t("app.api.verify.verified") : $t("app.companies.verify") }}
		.wrapp-right
			.wrappDate
				label {{ $t("app.companies.package") }}
				.date {{ writePlan() }}
			.wrappDate
				label {{ $t("app.companies.expirationDate") }}
				.date {{ company.cmp_planexpiry ? formatDate(company.cmp_planexpiry) : "-" }}
				.time {{ company.cmp_planexpiry ? formatTime(company.cmp_planexpiry) : "" }}
			.wrappActions
				//- .pause(@click="pause")
					div
					div
				//- .stop(@click="stop")
				//- .wrappImg(@click="reverseSet")
					img(src="../../assets/image/icons/roundArrow.svg")
				button(@click="updatePackage")
					span {{ $t("app.companies.updatePackage") }}
	component-company-information(v-if="company", :company="company")
</template>

<style lang="scss" scoped>
.company-information-admin {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;

	label {
		color: $text-color-light;
		font-size: 0.7rem;
		display: inline-block;
		margin-bottom: 19px;
	}
	button {
		max-height: 34px;
	}

	.wrappUp {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		align-items: center;
		width: 100%;
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			align-self: flex-start;
			.line {
				display: block;
				width: 100%;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				display: inline;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		// end title
		.wrapp-right {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			align-items: center;
			width: 50%;
		}
		.wrappDate {
			margin-top: 14px;
			align-self: flex-start;
			label {
				margin-bottom: 10px;
			}
			.date {
				font-size: 13px;
				font-weight: 600;
				line-height: 15px;
				letter-spacing: 0;
				text-align: left;
			}
			.time {
				font-size: 11px;
				font-weight: 400;
				line-height: 15px;
				letter-spacing: 0;
				text-align: left;
			}
		}
		.wrappActions {
			display: flex;
			align-items: center;
			> div,
			> button {
				margin-left: 15px;
			}
			> div:hover {
				opacity: 0.8;
			}
			.pause {
				width: 14.67px;
				height: 16px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;

				div {
					width: 40%;
					height: 100%;
					background-color: $app-accent-color1;
					border-radius: 2px;
				}
			}
			.stop {
				width: 14.67px;
				height: 16px;
				background-color: $app-accent-color1;
				border-radius: 2px;
				cursor: pointer;
			}
			.wrappImg {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}
	}
	.small {
		box-sizing: border-box;
		height: $button-height;
		min-width: 92px !important;
	}
}
</style>
