import { CWebsocket, READY_STATES } from "@/assets/js/websocketHelper";
import { defineStore } from "pinia";
import { useTokenStore } from "./token";

function getWebsocketUri() {
	//  dev
	const currentDomain = window.location.hostname;
	if (currentDomain === "localhost") {
		return "wss://204b123.mars-t.mars-hosting.com/api_v1/websocket/ws";
	}

	return "wss://" + currentDomain + "/api_v1/websocket/ws";
}

export const useSocketStore = defineStore("socket", () => {
	const socket = ref<CWebsocket | null>(null);
	const urlPrefix = getWebsocketUri();
	const tokenStore = useTokenStore();

	const options = ref<ConstructorParameters<typeof CWebsocket>[1]>({});
	function setOptions(payload: typeof options.value) {
		options.value = payload;
		// Object.assign(options, payload);
		if (socket.value && options.value) {
			socket.value.setOptions(options.value);
		}
	}

	const socketState: ComputedRef<
		(typeof READY_STATES)[keyof typeof READY_STATES]
	> = computed(() => READY_STATES[socket.value?.wsStatus]);

	function initSocket() {
		if (socket.value !== null) {
			socket.value.close();
		}
		const fullUrl = new URL(urlPrefix);
		fullUrl.searchParams.append("jwt", tokenStore.getTokenString);
		if (options.value && tokenStore.getTokenString) {
			socket.value = new CWebsocket(fullUrl, options.value);
		}
	}

	function sendMessage(
		data:
			| string
			| Record<string, any>
			| ArrayBufferLike
			| Blob
			| ArrayBufferView,
	) {
		socket.value.send(data);
	}

	function closeSocket() {
		socket.value.close();
	}

	return {
		initSocket,
		setOptions,
		options,
		socket,
		sendMessage,
		socketState,
		closeSocket,
	};
});
