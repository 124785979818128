<script setup lang="ts">
import { getCategoriesArray } from "@/assets/js/constants";
import { type TStepItem } from "@/components/shared/wizard/WizardSteps.vue";
// import { useDefaultStore } from "@/store";
import { useCandidateStore } from "@/store/candidate";
import { useCandidateRouteStore } from "@/store/candidateRoute";
import { useToastStore } from "@/store/toast";
import { useRouter } from "vue-router";

definePage({
	name: "ProfilePage",
	redirect: "/manage/profile/basic",
	meta: {
		requiresAuth: true,
	},
});
const emit = defineEmits(["back", "next"]);

// const store = useDefaultStore();
const candidateStore = useCandidateStore();
const candidateRouteStore = useCandidateRouteStore();
const router = useRouter();
const isSaveLoading = ref(false);

const stepList = computed(() => candidateRouteStore.stepList);
// const buttonLabel = computed<"Next" | "Save">(() =>
// 	candidateRouteStore.isLastButton ? "Save" : "Next",
// );

const onChangeStep = (step: TStepItem) =>
	candidateRouteStore.changeRouteStep(step);
// async function onClickBack() {
// 	const isSuccess = await candidateRouteStore.changeBackStep();
// 	if (isSuccess) {
// 		emit("back");
// 	}
// }
// async function onClickNext() {
// 	const response = await candidateRouteStore.changeNextStep();
// 	if (response === "next") {
// 		emit("next");
// 	} else if (response === "final") {
// 		await saveUser();
// 	}
// }
async function onClickSave() {
	await saveUser();
}

async function saveUser() {
	isSaveLoading.value = true;

	const isSuccessUser = await candidateStore.editUser();
	const isSuccessCv = await candidateStore.editCvData();
	if (isSuccessUser && isSuccessCv) {
		useToastStore().openToastSuccess("User info saved successfully.");
		await candidateStore.fetchUserProfile(); // Refresh the data to verify correct info and to update avatar
		await candidateStore.fetchSetupCvData(); // Refresh the cv data to not send changed values (add / delete) - duplication
	} else {
		if (!isSuccessCv) {
			useToastStore().openToastError(
				"There was an error saving CV data, please try again later.",
			);
		} else if (!isSuccessUser) {
			useToastStore().openToastError(
				"There was an error saving profile data, please try again later.",
			);
		}
	}

	isSaveLoading.value = false;
}
async function setCountries() {
	await candidateStore.fetchCountries();
	const defaultCountry = candidateStore.countryOptions.find(
		(co) => co.cnt_code.toLowerCase() === "rs",
	);
	if (defaultCountry) {
		candidateStore.formUser.basic.updateCountry(defaultCountry);
	} else {
		console.warn("No default countries", candidateStore.countryOptions);
	}
}
async function initData() {
	await candidateStore.fetchUserProfile(false);
	await candidateStore.fetchSetupCvData();
	await setCountries();
	candidateStore.formUser.basic.logTouchedKeys = true;
}

async function onClickExportCv() {
	await router.push({
		name: "CvPage",
	});
}

onMounted(() => {
	candidateRouteStore.stepList = getCategoriesArray("Profile");
	candidateRouteStore.setupActiveStep();
	void initData();
});
onUnmounted(() => {
	candidateRouteStore.resetActiveStep();
});
</script>

<template lang="pug">
.profile-page.box-wrapper
	.header
		.header-label
			.header-bar
			span My profile
		.export-cv
			button(@click="onClickExportCv")
				span {{ $t("app.profile.exportCv") }}
	.content
		.user-steps
			wizard-steps(
				v-if="stepList",
				:is-radio-box="true",
				:steps="stepList",
				:is-interactable="true",
				:wrapper-styles="{ fontSize: '1rem' }",
				:separator-styles="{ height: '30px', width: '1px' }",
				:selected-steps="candidateRouteStore.activeSteps",
				@change-step="onChangeStep"
			)
		.mid-section
			router-view(v-slot="{ Component }")
				component(:is="Component")
					template(#default)
						//- back-next-btns(
						//- 	:can-show-back="candidateRouteStore.canShowBack",
						//- 	@back="onClickBack",
						//- 	@next="onClickNext"
						//- )
						//- 	template(#next-label)
						//- 		span {{ buttonLabel }}
						//- turn-stile(
							:resetToken="store.refreshToken",
							@token-change="store.setCaptchaToken"
							)
						.action-btn
							button.mt-2(:disabled="isSaveLoading", @click="onClickSave")
								//- v-if="!candidateRouteStore.isLastButton",
								span Save
	spinner-block(
		v-if="isSaveLoading",
		:is-full-page="false",
		background="#0000003d"
	)
</template>

<style lang="scss" scoped>
.profile-page {
	max-width: 1224px;
	display: flex;
	flex-direction: column;
	gap: 20px 30px;
	// align-items: center;
	width: 100%;
	// height: 100%;
	// justify-content: center; // Overlaps with overflow
	padding: 0 20px 20px;
	overflow: auto;
	background-color: $bg-color1;

	.header {
		align-self: baseline;
		display: flex;
		justify-content: space-between;
		width: 100%;

		.header-label {
			display: flex;
			flex-direction: column;

			.header-bar {
				// background: $gradient-line3;
				height: 5px;
				border-radius: 0 0 10px 10px;
				position: relative;
				margin-bottom: 5px;

				&::before {
					// content: "";
					// position: absolute;
					// top: 0;
					// height: 5px;
					// background: $gradient-line3;
					// border-radius: 0 0 10px 10px;
				}

				&:not(&.animate) {
					&::before {
						width: 100%;
					}
				}

				&.animate {
					@keyframes width-line {
						0% {
							width: 0;
						}
						100% {
							width: 100%;
						}
					}

					&::before {
						animation: width-line;
						animation-duration: 0.5s;
						animation-fill-mode: forwards;
						animation-timing-function: ease-out;
					}
				}
			}
		}

		.export-cv {
			width: 150px;
			margin: 10px 0 0;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 70px 10px;
		position: relative;
		flex: 1;

		&::before {
			content: " ";
			position: absolute;
			inset: 0;
			// background-image: url("@/assets/image/bg_profile.png");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: bottom;
			pointer-events: none;
		}

		.user-steps {
			min-width: 250px;

			:deep() {
				.wrapper-steps {
					// transform: scale(1.1) translateY(30px) translateX(10px);
				}
			}
		}

		.mid-section {
			z-index: 1;

			.page {
				flex-wrap: wrap;
			}

			:deep() {
				.modern-form {
					height: auto;
					box-shadow: none;

					.logo-wrapper {
						display: none;
					}
				}

				.multiselect-form {
					height: 34px;
					border: 1px solid $border-color1;
					border-radius: 20px;

					&.tags {
						height: auto;
						min-height: 34px;
					}
				}

				.back-next-btns {
					margin: 30px 40px 0;
				}

				.action-btn {
					margin: 0 40px 30px;
					display: flex;
					align-items: center;
					justify-content: center;

					button {
						width: auto;
					}
				}
			}
		}
	}

	@media screen and (width > 1024px) {
		.content {
			flex-direction: row;
		}

		.mid-section {
			.page {
				flex-wrap: nowrap;
			}
		}
	}
}
</style>
