<script setup lang="ts">
definePage({
	name: "RegisterEmployees",
});
</script>

<template lang="pug">
.employees-wrap
	user-employees
</template>

<style lang="scss" scoped>
.employees-wrap {
	display: flex;
}
</style>
