import { useRoute } from "vue-router";
import { useTokenStore } from "./token";
import { useApplicationMessages } from "./applicationMessages";
import { useChatMessage } from "./chatMessage";
export const useMessageStore = defineStore("messages", () => {
	// private - it doesn't work if it's in computed
	const currentRoute = useRoute();

	const applicationMsgStore = useApplicationMessages();
	const chatMsgStore = useChatMessage();

	const chat_room = computed(() => {
		if (currentRoute.name === "ChatApplication") {
			return Number(currentRoute.params.id);
		}
		return null;
	});

	const globalMsg = ref<any>({});

	// decide if cb should have return type
	function triggerMessageGet(app_id: number, cb: () => void) {
		if (!chat_room.value) {
			return;
		}

		if (app_id === chat_room.value) {
			cb();
		}
	}

	async function triggerMessageGetAll(
		app_id: number,
		message: any,
		cb: () => void,
	) {
		if (
			message?.type === "sendNotification" ||
			(message?.type === "new-message" &&
				currentRoute.name !== "ChatApplication" &&
				currentRoute.name !== "ChatMain" &&
				!message.room)
		) {
			globalMsg.value = message;
		} else if (
			message?.type === "new-message" &&
			currentRoute.name === "ChatApplication" &&
			message.room
		) {
			// const index =  rooms.value.findIndex(
			// 	(obj) => obj.chat_type === message?.room,
			// );
			// const index = rooms.value.findIndex(
			// 	(obj) =>
			// 		(message?.room !== "b2b" &&
			// 			message?.room !== "b2b-applicant" &&
			// 			obj.chat_type === message?.room) ||
			// 		(obj.chat_type === message?.room &&
			// 			obj.usr_id === message?.usr_id_context),
			// );
			// if (
			// 	applicationMsgStore.selectedRoom?.chat_type === message?.room ||
			// 	index === -1
			// ) {
			// 	await applicationMsgStore.getMessages(false, "new");
			// } else if ((index || index === 0) && index !== -1) {
			// 	rooms.value[index].unread = 1;
			// }
			// await applicationMsgStore.getMessages(false, "new");
			applicationMsgStore.cbInWS(message);
		} else if (
			message?.type === "new-message" &&
			currentRoute.name === "ChatMain"
		) {
			chatMsgStore.cbInWS(message);
		}
		if (message?.message === "refresh-jwt") {
			useTokenStore().renewToken();
		}
		if (app_id === chat_room.value) {
			cb();
		}
	}

	// SEND MSG TO
	type TMsgTo = {
		usr_id: number;
		usr_name: string;
		job_id?: number;
	};

	const msgTo = ref<TMsgTo>(null);

	function setSendMsgTo(e: TMsgTo) {
		msgTo.value = e;
	}

	return {
		chat_room,
		triggerMessageGet,
		msgTo,
		setSendMsgTo,
		triggerMessageGetAll,
		globalMsg,
	};
});
