<script setup lang="ts">
import { onDeleteImage, onSetImage } from "@/components/profile/func";
import { useCandidateStore } from "@/store/candidate";
import { checkPhone } from "@/assets/js/helpers";
const candidateStore = useCandidateStore();

definePage({
	name: "BasicInfo",
});

const form = computed(() => candidateStore.formUser.basic);
const countryOptions = computed(() => candidateStore.countryOptions);
</script>

<template lang="pug">
.page.basic
	.form-wrapper
		modern-form
			template(#logo)
				.logo-placeholder
			template(#contentInner)
				form-comp.form-inputs
					.row
						.group
							label First and last name
							field-comp(
								v-model="form.fullName",
								name="fullName",
								:rules="form.validations.fullName"
							)
							error-message.error-msg(name="fullName")
					.row
						.group
							label Position
							field-comp(v-model="form.position", name="position")
					.row
						.group
							label Date of birth
								//- .red *
							modern-date-picker(
								v-model="form.dateOfBirth",
								:enable-time-picker="false",
								start-date="2000-01-01",
								:max-date="new Date()"
							)
					.row
						.group
							label Address and city
							field-comp(
								v-model="form.address",
								name="address",
								:rules="form.validations.address"
							)
							error-message.error-msg(name="address")
					.row
						.group
							label Country
								//- .red *
							multiselect-form(
								v-model="form.countryObj",
								:options="countryOptions",
								:append-to-body="true",
								label="cnt_name",
								value-prop="cnt_code"
							)
					.row
						.group
							label Email
							field-comp(
								v-model="form.email",
								type="email",
								autocomplete="off",
								name="email",
								:rules="form.validations.email"
							)
							error-message.error-msg(name="email")
					.row
						.group
							label Phone number
							input(v-model="form.phoneNumber", type="text", @keydown="checkPhone")
							error-message.error-msg(name="phoneNumber")
					.row
						.group
							label About me
							field-comp(
								v-slot="{ field }",
								v-model="form.aboutMe",
								type="text",
								name="aboutMe",
								:rules="form.validations.aboutMe"
							)
								textarea(v-bind="field")
							error-message.error-msg(name="aboutMe")
			template(#action)
				slot
	.right-side
		//- img(src="@/assets/image/auth/reg/1.png")
		input-img(
			:old-image="candidateStore.registerImageSrc",
			@set-image="onSetImage",
			@delete="onDeleteImage"
		)
</template>

<style lang="scss" scoped>
.page {
	@include candidate-profile-page;
}
</style>
