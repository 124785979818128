<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import {
	openAddAdmin,
	openConfirmModal,
} from "../../components/modals/modalDefinitions";
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";
import { useTokenStore } from "@/store/token";

import { useToastStore } from "@/store/toast";
const toastStore = useToastStore();

definePage({
	name: "AdminAdmin",
});

type TOrder = {
	name: string;
	id: number;
};

const tokenStore = useTokenStore();

const search = ref("");
const date = ref<string>("");
const showOptionsData = ref<any>({});
const manageCallApi = ref<boolean>(true);
const selectedOrder = ref<TOrder>(null);
const load = ref(false);

const orders = ref<TOrder[]>([
	{ name: "ID", id: 1 },
	{ name: "Name", id: 2 },
]);

const columns = reactive<TTableColumn[]>([
	{
		id: "usr_id",
		label: $t("app.admin.id"),
	},
	{
		id: "usr_name",
		label: $t("app.candidates.name"),
	},
	{
		id: "rol_name",
		label: $t("app.admin.rol"),
	},
	{
		id: "usr_created",
		label: $t("app.candidates.regDate"),
	},
	// {
	// 	id: "cnt_name",
	// 	label: $t("app.candidates.country"),
	// },
	{
		id: "usr_email",
		label: $t("app.candidates.email"),
	},
	{
		id: "status",
		label: $t("app.candidates.status"),
	},
	// {
	// 	id: "action",
	// 	label: "",
	// },
]);

if (
	tokenStore.userData?.rol_name === "super-admin" ||
	tokenStore.userData?.rol_id === 6
) {
	columns.push({
		id: "action",
		label: "",
	});
}

const rows = ref<TTableRow[]>([]);

// functionality
function formatDate(date: string) {
	if (date) return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}

function showOptions(e: any) {
	showOptionsData.value = e;
}

function getRemove(e: any) {
	// openConfirmBlockUser(e, e.usr_deleted, confirm);
	openConfirmModal(
		{ name: e.usr_email, data: e },
		confirmRemove,
		"Remove this user?",
	);
	showOptionsData.value = {};
}

async function confirmRemove(e: TTableRow) {
	try {
		await api.removeAdmin({ usr_id: e.usr_id });

		toastStore.openToastSuccess($t("app.admin.successRemoveUser"));
		await getAdmins(true);
	} catch (e) {
		console.log(e);
		toastStore.openToastError("Error");
	}
}

async function getAdmins(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	type TParam = {
		limit: number;
		offset: number;
		search?: string;
		date?: string;
		order?: string;
	};
	const params: TParam = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value) {
		params.search = search.value;
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	if (selectedOrder.value) {
		params.order = selectedOrder.value.name.toLowerCase();
	}
	try {
		const res = await api.getAdmins(params);

		rows.value.push(...res.data.data.data);
		manageCallApi.value = res.data.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

function deselectOrder() {
	selectedOrder.value = null;
}

watch([search, date, selectedOrder], async () => {
	await getAdmins(true);
});

function addNew() {
	openAddAdmin({}, confirmAdd);
}
async function confirmAdd() {
	await getAdmins(true);
}

onMounted(async () => {
	await getAdmins();
});
</script>

<template lang="pug">
.admin-subpage
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.admin.title") }}
			.wrapp-filters
				.wrapp-filter
					search-input(
						placeholder="Type something...",
						:search-value="search",
						@update:search-value="search = $event"
					)
					.dropdown
						multiselect-form(
							v-model="selectedOrder",
							:options="orders",
							label="name",
							value-prop="id",
							placeholder="Order",
							:can-deselect="true",
							@deselect="deselectOrder"
						)
				//- input(v-model="date", type="date", placeholder="Date created")
				//- .date
					modern-date-picker(
					    v-model="date",
					    :enable-time-picker="false",
					    style="width: 150px"
					)
				button(@click="addNew")
					span {{ $t("app.admin.addAdmin") }}
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getAdmins"
		)
			template(#[`table-body-cell`]="slotProps")
				td(v-if="slotProps.column.id == 'usr_created'")
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
				td(v-else-if="slotProps.column.id == 'status'")
					.statusUnavail(v-if="slotProps.row.usr_deleted")
						span {{ $t("app.admin.removedUser") }}
				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span(
						v-if="slotProps.row.rol_name !== 'super-admin' && !slotProps.row.usr_deleted",
						@click="showOptions(slotProps.row)"
					)
						img(src="@/assets/image/actionIcons/more.svg")
					.show-reports-data(v-if="showOptionsData.usr_id === slotProps.row.usr_id")
						.close-btn(data-name="close-action", @click="showOptionsData = {}")
							svg.close-icon(
								width="16",
								height="16",
								viewBox="0 0 16 16",
								fill="none",
								xmlns="http://www.w3.org/2000/svg"
							)
								path(
									fill-rule="evenodd",
									clip-rule="evenodd",
									d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
									fill="#575758"
								)
						.wrapp-info(@click="getRemove(slotProps.row)")
							span {{ $t("app.admin.remove") }}
							//- span(v-else) {{ $t("app.candidates.unblock") }}
				td(
					v-else,
					:class="{ bold: slotProps.column.id === 'firstName' || slotProps.column.id === 'lastName' }"
				) {{ slotProps.row[slotProps.column.id] }}
			template(#table-body-extra-row-content="slotProps")
				transition(name="show-row")
					td.info-row(
						v-show="showOptionsData.usr_id === slotProps.row.usr_id",
						:colspan="columns.length"
					)
		.loader-wrap-intern(v-else-if="load")
			.loader
</template>

<style lang="scss" scoped>
.admin-subpage {
	font-family: "Open Sans", sans-serif;
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				width: 100%;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}

		.wrapp-filters {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			.wrapp-filter {
				display: flex;
				margin-bottom: 15px;
				justify-content: center;
			}

			.dropdown {
				margin: 0 10px;
				// margin-top: 10px;
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
			.date {
				padding: 0 10px 0 2px;
				// margin-top: 10px;
			}
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			max-width: 40px;
			margin-left: auto;
			display: flex;
			align-items: center;
			height: 40px !important;
			position: relative;
			padding-right: 22px;

			span {
				cursor: pointer;
			}

			img {
				margin-left: 18px;
			}

			.show-reports-data {
				width: 149px;
				// min-height: 86px;
				box-sizing: border-box;
				padding: 6px 15px 0;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 1px;
				border-radius: 20px;
				background: $background-color;
				border: 1px solid #dfdfe8;
				// background: #e44e75;

				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0.03em;
				text-align: left;

				& > .close-btn {
					margin: 0 0 auto auto;
					display: flex;
					align-self: center;
					padding: 3px;
					justify-content: flex-end;
					position: absolute;
					top: 5px;
					right: 5px;

					.close-icon {
						$size: 13px;

						height: $size;
						width: $size;
						cursor: pointer;

						path {
							fill: #aeadad;
						}

						&:hover {
							path {
								fill: #ffb4c3;
								transition: all 0.3s ease;
							}
						}
					}
				}

				img {
					cursor: pointer;
					margin-left: 18px;
					position: absolute;
				}

				.wrapp-info {
					margin-bottom: 8px;
				}
				.wrapp-info:hover {
					color: $app-accent-color1;
					cursor: pointer;
				}
			}
		}
	}
}
.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
<style lang="scss">
.admin-subpage {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
}
</style>
