<script setup lang="ts">
import type {
	TFileDropzoneChange,
	TFileDropzoneRemove,
} from "@/components/shared/fileUpload/FileDropzone.vue";
import type { TFilePickerChange } from "@/components/shared/fileUpload/FilePicker.vue";

import { useCandidateStore } from "@/store/candidate";
import api from "@/api";
const candidateStore = useCandidateStore();

definePage({
	name: "Portfolio",
});

const form = computed(() => candidateStore.formUser.basic);

function onChangeAttributes(evt: Event, key: string) {
	const value = (evt.target as HTMLInputElement).value;
	form.value.setAttrs(key, value);
}

const fileList = ref<File[]>([]);
function onAddFiles(evt: TFileDropzoneChange) {
	addFiles(evt.files);
}
async function addFiles(evt?: File[] | null) {
	// files && fileList.value.push(...files);
	if (!evt || evt.length <= 0) {
		return;
	}

	// const params = {
	// 	file_title: evt.files[0].name ,
	// 	file_data: evt.files[0],
	// };

	for (const element of evt) {
		const fd = new FormData();
		fd.append("file_title", element.name);
		fd.append("file_data", element);
		try {
			const res = await api.appFilesAdd(fd);
			console.log(res.data.data); // file_id
		} catch {}
	}
	await appGetFiles();
}
async function onRemoveFiles(evt: TFileDropzoneRemove) {
	// fileList.value.splice(evt.index, 1);
	console.log(evt);
	try {
		const res = await api.appFilesRemove({ file_id: Number(evt.id) });
		console.log(res.data);
		await appGetFiles();
	} catch (e) {
		console.log(e);
	}
}
function onFilePickerChange(evt: TFilePickerChange) {
	evt.files && addFiles([...evt.files]);
}
async function appGetFiles() {
	try {
		const res = await api.appFilesGetUser({});
		console.log(res.data.data);
		fileList.value = res.data.data.map((f) => {
			return { ...f, name: f.file_title }; // Adding 'isActive' to each object
		});
		console.log(fileList.value);
	} catch (e) {
		console.log(e);
	}
}

onMounted(() => {
	appGetFiles();
});
</script>

<template lang="pug">
.page
	.form-wrapper
		modern-form
			template(#logo)
				.logo-placeholder
			template(#contentInner)
				form-comp.form-inputs
					.row
						.group.porfolio-link
							label Porfolio link
							//- field-comp(
							//- 	v-slot="{ field }",
							//- 	:value="form.getAttrs('cve_portfolio_link')",
							//- 	@input="onChangeAttributes($event, 'cve_portfolio_link')"
							//- )
							//- 	textarea(v-bind="field")
							textarea(
								:value="form.getAttrs('cve_portfolio_link')",
								placeholder="Porfolio link",
								@input="onChangeAttributes($event, 'cve_portfolio_link')"
							)
					.row
						.group.coverletter-text
							label Cover letter
							//- field-comp(
							//- 	v-slot="{ field }",
							//- 	:value="form.getAttrs('cve_portfolio_cover_letter')",
							//- 	@input="onChangeAttributes($event, 'cve_portfolio_cover_letter')"
							//- )
							//- 	textarea(v-bind="field")
							textarea(
								:value="form.getAttrs('cve_portfolio_cover_letter')",
								placeholder="Cover letter",
								@input="onChangeAttributes($event, 'cve_portfolio_cover_letter')"
							)
				.port-inputs
					label(title="maximum file size is 5MB") Add a recommendation and portfolio *
					.dropzone-wrap
						file-dropzone(
							:file-list="fileList",
							placeholder-text="''",
							:can-store-locally="false",
							@add="onAddFiles",
							@remove="onRemoveFiles"
						)
					file-picker(:multiple="true", @change="onFilePickerChange")
						template(#button)
							.add-more
								img(src="@/assets/image/actionIcons/add.svg")
								span Add new
			template(#action)
				slot
	.right-side
		img(src="@/assets/image/auth/reg/6.png")
		//- input-img(
		//- 	:old-image="candidateStore.registerImageSrc",
		//- 	@set-image="onSetImage",
		//- 	@delete="onDeleteImage"
		//- )
</template>

<style lang="scss" scoped>
.page {
	@include candidate-profile-page;
}
</style>
