<script setup lang="ts">
import { openEnjoyHiringModal } from "../../components/modals/modalDefinitions";
import exampleSvg from "@/assets/image/logo.svg";

definePage({
	name: "TestPage",
});

const mfSelected = ref<any>({ name: "A", label: "A" });
const mfOptions = reactive([
	{
		name: "A",
		label: "A",
	},
	{
		name: "B",
		label: "B",
	},
]);

// IMAGE example
const oldImage = ref<string | null>(
	"https://plus.unsplash.com/premium_photo-1676637000058-96549206fe71?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
);
const newImage = ref<string | null>(null);
function setImage(e: any) {
	console.log(e);
	//   e.file | e.base64
	newImage.value = e.base64;
}
function deleteImg() {
	console.log("delete img");
	if (newImage.value) {
		newImage.value = null;
	} else {
		oldImage.value = null;
	}
}
</script>

<template lang="pug">
.banner-section
	.column
		inputImg(
			:oldImage="oldImage",
			:aspectRatio="1 / 1",
			xBackground="#E44E75",
			@set-image="setImage",
			@delete="deleteImg"
		)
	.column
		.group-div
			label Modals
			button(@click="openEnjoyHiringModal") enjoy
		.group-div
			label Multiselect form example:
			multiselect-form(
				v-model="mfSelected",
				:options="mfOptions",
				label="label",
				value-prop="label"
			)
		.group-div
			.icon-test
				span Icon test: &nbsp;
				i-fa-file
	.column
		.group-div
			span Inline-svg example (for dynamic svg)
			inline-svg(:src="exampleSvg")
</template>

<style lang="scss" scoped>
.banner-section {
	display: flex;
	gap: 20px;
	padding: 10px;
	min-height: 300px;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media screen and (width >=768px) {
		flex-direction: row;
	}

	.column {
		display: flex;
		gap: 5px;
		flex-direction: column;
	}

	.group-div {
		border: 1px solid gray;
		padding: 5px;
	}

	svg {
		:deep() {
			@for $i from 1 through 9 {
				path:nth-child(#{$i}) {
					fill: rgb(178, 78, 98);
				}
			}
			path:nth-child(12) {
				fill: rgb(178, 78, 98);
			}
		}
	}
}
</style>
