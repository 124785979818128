import { defineStore } from "pinia";
import { useWindowSize } from "@vueuse/core";
import type { TRegisterEntity, TCompanyPlan } from "@/assets/js/types";
import { useRouter, useRoute } from "vue-router";
import { simpleDecrypt, simpleCrypt } from "@/assets/js/helpers";
import type { TAuthCompany, TCountryItem } from "@/api/types";

type TForm = Omit<TAuthCompany, "cnt_code"> & {
	cnt_code?: string;
	cmp_plan: TCompanyPlan;
};
class CRegisterCompany implements Omit<TForm, "google_token"> {
	cmp_name = "";
	cmp_address = "";
	usr_address = "";
	_countryObj: Partial<TCountryItem> | null = null;
	cmp_regnumber = "";
	usr_name = "";
	usr_email = "";
	usr_phone = "";
	cmp_plan: TCompanyPlan = "FREE";
	// protected  usr_position: "",
	//  protected usr_employmentlength: "",
	//  protected image: "",

	updateCountry(obj: TCountryItem | null) {
		this.countryObj = obj;
	}

	get countryObj(): Partial<TCountryItem> | null {
		return this._countryObj;
	}
	set countryObj(val: Partial<TCountryItem> | null) {
		this._countryObj = val;
	}
	get cnt_code(): string | undefined {
		return this.countryObj?.cnt_code;
	}

	toDb() {
		return {
			cmp_name: this.cmp_name,
			cmp_address: this.cmp_address,
			usr_address: this.usr_address,
			cnt_code: this.cnt_code,
			cmp_regnumber: this.cmp_regnumber,
			usr_name: this.usr_name,
			usr_email: this.usr_email,
			usr_phone: this.usr_phone,
			cmp_plan: this.cmp_plan,
		};
	}
}

export const useDefaultStore = defineStore("main", () => {
	const { width } = useWindowSize();
	const isMobileToggled = ref(false);
	const router = useRouter();
	const route = useRoute();
	const registerSteps = reactive<Record<TRegisterEntity, string[]>>({
		// This lists the router steps for register form
		agency: [
			"RegisterComp",
			"RegisterPackage",
			"RegisterCompany",
			"RegisterEmployees",
		],
		b2b: ["RegisterComp", "RegisterPackage", "b2b"],
		candidate: ["RegisterComp", "BasicInfo"], // Needed to init routes
	});
	const registerForm = reactive<{
		agencyType: TRegisterEntity | "";
		companyData: CRegisterCompany;
	}>({
		agencyType: "",
		companyData: new CRegisterCompany(),
	});
	const companyForm = reactive({});
	const tokenCaptcha = ref(""); // Used for backend
	const refreshToken = ref(false); // used to refresh the token when it has been used

	const isMobileSize = computed(() => width.value < 768);
	const isMobileView = computed(
		() => isMobileSize.value && isMobileToggled.value,
	);
	watch(isMobileSize, (val) => {
		if (!val) {
			isMobileToggled.value = false;
		}
	});

	async function changeWizardPage(incNum: 1 | -1): Promise<boolean> {
		// Reg form
		if (registerForm.agencyType) {
			const currentSteps = registerSteps[registerForm.agencyType];
			const currentRoute = router.currentRoute.value.name;
			if (!currentRoute) {
				console.error("No current route");
				return false;
			}

			// if (currentRoute === "RegisterComp" && incNum === 1) {
			// 	// This is the first page, move forward
			// }

			const indexStep = currentSteps.indexOf(currentRoute.toString());
			if (incNum === -1) {
				// Back
				if (indexStep === 0) {
					console.error("Invalid step direction B");
					return false;
				}

				await router.push({
					name: currentSteps[indexStep - 1] as any,
				});
			} else if (incNum === 1) {
				// Next
				if (indexStep === currentSteps.length - 1) {
					console.error("Invalid step direction N");
					return false;
				}

				await router.push({
					name: currentSteps[indexStep + 1] as any,
				});
			} else {
				console.warn("Invalid reg page");
			}
		} else {
			console.warn("No agency type", registerForm.agencyType);
			await router.replace({
				name: "RegisterComp",
			});
		}
		return true;
	}

	function decodePath(name: string) {
		return simpleDecrypt("abc", name);
	}
	function encodePath(name: string) {
		return simpleCrypt("abc", name);
	}
	async function onClickLoginPage() {
		// Used to return user to the previous page
		const currentPageName = String(route.name);

		// Exceptions - Auth pages
		let ppn: string | undefined = encodePath(currentPageName);
		if (route.matched.some((rt) => rt.name === "AuthPage")) {
			ppn = undefined;
		}

		await router.push({
			name: "LoginPage",
			query: {
				ppn,
			},
		});
	}

	function setCaptchaToken(evt: string) {
		tokenCaptcha.value = evt;
	}

	return {
		tokenCaptcha,
		refreshToken,
		registerForm,
		companyForm,
		isMobileToggled,
		changeWizardPage,
		isMobileView,
		onClickLoginPage,
		decodePath,
		setCaptchaToken,
		CRegisterCompany,
	};
});
