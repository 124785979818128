import { baseURL } from "@/api/config";
import { type RemovableRef, useStorage } from "@vueuse/core";
import { AxiosError } from "axios";
import { type Ref, type UnwrapRef } from "vue";
import { $t } from "../../i18n";
import { useToastStore } from "@/store/toast";
import soundMP3 from "@/assets/sonuds/notification2.mp3";
import soundMsg from "@/assets/sonuds/notification.wav";

// #region Bounce / Throttle
export function debounce(func, waitTimer, immediate) {
	let timeout = null;
	return function () {
		// eslint-disable-next-line unicorn/no-this-assignment
		const context = this;

		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		const args = arguments;
		// eslint-disable-next-line unicorn/no-this-assignment
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}
// #endregion

// #region Simple crypt / decrypt
export function simpleCrypt(salt: string, text: string) {
	const textToChars = (text: string) =>
		text.split("").map((c) => c.codePointAt(0));
	const byteHex = (n: any) => ("0" + Number(n).toString(16)).slice(-2);
	const applySaltToChar = (code: any) =>
		textToChars(salt).reduce((a: any, b: any) => a ^ b, code);

	return text
		.split("")
		.map((element) => textToChars(element))
		.map((element) => applySaltToChar(element))
		.map((element) => byteHex(element))
		.join("");
}
export function simpleDecrypt(salt: string, encoded: string) {
	const textToChars = (text: any) =>
		text.split("").map((c: any) => c.codePointAt(0));
	const applySaltToChar = (code: any) =>
		textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
	return encoded
		.match(/.{1,2}/g)
		.map((hex) => parseInt(hex, 16))
		.map((element) => applySaltToChar(element))
		.map((charCode) => String.fromCodePoint(charCode))
		.join("");
}
// #endregion

export function focusFirstElement(
	parentEl: HTMLElement,
	isFocus = true,
	isDebug = false,
) {
	// Don't invoke more than once (Before a component is destroyed)
	if (parentEl) {
		const inputListToIncludeFocusable = [
			"input:not(:disabled):not(.hidden)",
			"textarea:not(:disabled):not(.hidden)",
			"button:not(:disabled):not(.hidden)",
			"span.focusable",
			"div.focusable",
			".multiselect[tabindex]",
			"*[tabindex]:not(:disabled):not(.hidden)",
		].join(",");

		const nodeList = Array.from(
			parentEl.querySelectorAll(inputListToIncludeFocusable),
		) as HTMLElement[];
		if (nodeList?.length) {
			const addInputTabHandling = (nodeList: HTMLElement[]) => {
				const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
					// Only for first / last element
					oppoEl.focus();
					evt.preventDefault();
				};

				// First el
				nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && evt.shiftKey) {
						focusEl(evt, nodeList.at(-1) as any);
					}
				});

				// Last el
				(nodeList.at(-1) as any).addEventListener(
					"keydown",
					(evt: KeyboardEvent) => {
						if (evt.key === "Tab" && !evt.shiftKey) {
							focusEl(evt, nodeList[0]);
						}
					},
				);
			};

			if (isFocus) {
				// console.log("➕ Focusing first el", nodeList[0]);
				nodeList[0].focus();

				if (isDebug) {
					console.error("➕ Focusing first el", nodeList[0]);
				}
			}

			addInputTabHandling(nodeList);
		} else if (isDebug) {
			console.warn("No child element found for focus");
		}
	} else if (isDebug) {
		console.warn("No parent element found for focus");
	}
}

export function getCurrentDomain() {
	let url = new URL(location.href);
	try {
		url = new URL(baseURL);
	} catch {
		// ignored - could break on prod because link is not valid
	}
	return url;
}
function getUseStorage<T>(
	key: string,
	shouldParse = false,
	storage: Storage,
	defaultVal: T | null = null, // Undefined is broken (creates invalid value in store)
): Ref<T | UnwrapRef<T> | string | null | undefined> {
	const state = useStorage(key, defaultVal, storage);
	if (!shouldParse) return state;

	try {
		return ref(JSON.parse(state.value as string));
	} catch {
		console.error(`Error loading key`, key);
		return ref(defaultVal);
	}
}

export function getLocalStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, localStorage, defaultVal);
}

export function getSessionStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	// eslint-disable-next-line no-storage/no-browser-storage
	return getUseStorage(key, shouldParse, sessionStorage, defaultVal);
}

export function setLocalStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, localStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (localStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		localStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function setSessionStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, sessionStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (sessionStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		sessionStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function parseApiError(err: any): string {
	if (err && err instanceof AxiosError) {
		// Custom email error handler
		if (err.response?.data?.data) {
			const translKey = `app.api.global.${err.response?.data?.data}`;
			return $t(translKey);
		}

		return "";
	}
	console.log("::> Invalid error", err);

	return "";
}

// #region Data Manipulation
export function labelToName(str: string) {
	return str.toLowerCase().replaceAll(/(^\d)|[^\dA-Za-z]/g, "_");
}

export function aliasOutputKeys(
	definition: Record<string, string | string[]>,
	data: Record<string, string>,
): Record<string, string> {
	for (const [defKey, defVal] of Object.entries(definition)) {
		if (Array.isArray(defVal)) {
			for (const oneDefVal of defVal) {
				data[oneDefVal] = data[defKey];
			}
		} else {
			data[defVal] = data[defKey];
		}

		delete data[defKey];
	}
	return data;
}
// #endregion

export const InfoConsole = {
	/**
	 * Console log with colors
	 */
	l(msg: string, ...payload: any[]) {
		console.log(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console warn with colors
	 */
	w(msg: string, ...payload: any[]) {
		console.warn(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console error with colors
	 */
	e(msg: string, ...payload: any[]) {
		console.error(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
};

export function downloadFile(data: any, file_name: string) {
	if (!data) {
		useToastStore().openToastError(
			"An error occurred while downloading the file",
		);
		return;
	}
	const pdfBlob = new Blob([data]);
	// Generate a URL for the Blob
	const pdfUrl = URL.createObjectURL(pdfBlob);

	const downloadLink = document.createElement("a");
	downloadLink.href = pdfUrl;
	downloadLink.download = file_name; // The name of the file to be downloaded

	document.body.append(downloadLink);

	downloadLink.click();

	downloadLink.remove();

	// Release the object URL to free up memory
	URL.revokeObjectURL(pdfUrl);
}

export function downloadFeedbackFile(data: any, file_name = "file_name") {
	if (!data) {
		useToastStore().openToastError(
			"An error occurred while downloading the file",
		);
		return;
	}
	const aElement = document.createElement("a");
	aElement.setAttribute("download", file_name);
	const href = URL.createObjectURL(data);
	aElement.href = href;
	aElement.setAttribute("target", "_blank");
	aElement.click();
	URL.revokeObjectURL(href);
}
export function playSound(eType = "notify") {
	const sound = eType === "message" ? soundMsg : soundMP3;
	let audio = new Audio(sound);
	audio.play();
}

export function checkPhone(e: any) {
	// let keyCode = e.keyCode ? e.keyCode : e.which;
	// if (keyCode < 48 || keyCode > 57) {
	// 	console.log(e.keyCode);
	// 	e.preventDefault();
	// }
	const allowedKeys = [
		8,
		9,
		13,
		27,
		46, // Backspace, Tab, Enter, Escape, Delete
		35,
		36,
		37,
		38,
		39,
		40, // Home, End, Arrow keys
		48,
		49,
		50,
		51,
		52,
		53,
		54,
		55,
		56,
		57, // 0-9
		96,
		97,
		98,
		99,
		100,
		101,
		102,
		103,
		104,
		105, // Numpad 0-9
		107, // Numpad +
	];

	// Allow + at the start if not already present

	if (e.key === "+" && !e.target.value?.includes("+")) {
		return;
	}

	// Allow keys if they are in the allowed keys array
	if (allowedKeys.includes(e.keyCode)) {
		return;
	}

	// Prevent default for all other keys
	e.preventDefault();
}
