<script setup lang="ts">
import { getCategoriesArray } from "@/assets/js/constants";
import { openVerifyModal } from "@/components/modals/modalDefinitions";
import { type TStepItem } from "@/components/shared/wizard/WizardSteps.vue";
// import { useDefaultStore } from "@/store";
import { useCandidateStore } from "@/store/candidate";
import { useCandidateRouteStore } from "@/store/candidateRoute";
import { useToastStore } from "@/store/toast";
import { useTokenStore } from "@/store/token";
import { useRouter, useRoute } from "vue-router";

definePage({
	name: "UserPage",
	redirect: "/user/basic",
});
const emit = defineEmits(["back", "next"]);

// const store = useDefaultStore();
const candidateStore = useCandidateStore();
const candidateRouteStore = useCandidateRouteStore();
const router = useRouter();
const route = useRoute();
const isSaveLoading = ref(false);

const stepList = computed(() => candidateRouteStore.stepList);
const buttonLabel = computed<"Next" | "Register">(() =>
	candidateRouteStore.isLastButton ? "Register" : "Next",
);
const computedStepList = computed(() => {
	return route.name === "BasicInfo"
		? stepList.value.map((item) => ({ ...item, disabled: true }))
		: stepList.value.map((item) => {
				return item.name === "basic_info" ? { ...item, disabled: true } : item;
			});
});

const isUserVerified = computed(() => useTokenStore().isLoggedIn);

const onChangeStep = (step: TStepItem) =>
	candidateRouteStore.changeRouteStep(step);
async function onClickBack() {
	const isSuccess = await candidateRouteStore.changeBackStep();
	if (isSuccess) {
		emit("back");
	}
}
async function onClickNext() {
	const response = await candidateRouteStore.changeNextStep();
	if (response === "next") {
		emit("next");
	} else if (response === "final") {
		await saveUser();
	}
}
async function onClickSave() {
	isSaveLoading.value = true;
	await saveUser();
	isSaveLoading.value = false;
}

async function saveUser() {
	// EDIT USER

	// const isSuccess = await candidateStore.createUser();
	await candidateStore.editUser(); // We should update the user data because some data is on the basic form
	const isSuccess = await candidateStore.editCvData();
	if (isSuccess) {
		useToastStore().openToastSuccess("Thanks for completing registration.");
		void router.push("/settings/information");
	} else {
		useToastStore().openToastError(
			"There was an error registering, please try again later.",
		);
	}
}
async function onClickVerify() {
	const isSuccess = await candidateStore.createUser();
	if (isSuccess) {
		const email = candidateStore.formUser.basic.email;
		openVerifyModal({ email, type: "user" });
	} else {
		useToastStore().openToastError(
			"There was an error registering, please try again later.",
		);
	}
}
async function setCountries() {
	await candidateStore.fetchCountries();
	const defaultCountry = candidateStore.countryOptions.find(
		(co) => co.cnt_code.toLowerCase() === "rs",
	);
	if (defaultCountry) {
		candidateStore.formUser.basic.updateCountry(defaultCountry);
	} else {
		console.warn("No default countries", candidateStore.countryOptions);
	}
}
async function initData() {
	// if (useTokenStore().isLoggedIn) {
	// 	await candidateStore.fetchUserProfile();
	// }
	// await candidateStore.fetchSetupCvData();
	await setCountries();

	// TODO recheck - should we log keys here? Otherwise we can't save basic profile info
	candidateStore.formUser.basic.logTouchedKeys = true;
}

onMounted(() => {
	candidateRouteStore.stepList = getCategoriesArray();
	candidateRouteStore.setupActiveStep();
	void initData();
});
onUnmounted(() => {
	candidateRouteStore.resetActiveStep();
});
</script>

<template lang="pug">
#auth-user-page
	.content
		.user-steps
			wizard-steps(
				v-if="computedStepList",
				:is-radio-box="true",
				:steps="computedStepList",
				:is-interactable="true",
				:wrapper-styles="{ fontSize: '1rem' }",
				:separator-styles="{ height: '30px', width: '1px' }",
				:selected-steps="candidateRouteStore.activeSteps",
				@change-step="onChangeStep"
			)
		.mid-section
			router-view(v-slot="{ Component }")
				component(:is="Component")
					template(#default)
						back-next-btns(
							:can-show-back="candidateRouteStore.canShowBackRegister",
							:can-show-next="candidateRouteStore.canShowNextRegister",
							@back="onClickBack",
							@next="onClickNext"
						)
							template(#next-label)
								span {{ buttonLabel }}
						//- turn-stile(
							:resetToken="store.refreshToken",
							@token-change="store.setCaptchaToken"
							)
						.action-btn
							button.mt-2(
								v-if="candidateRouteStore.isFirstButton && !isUserVerified",
								@click="onClickVerify"
							)
								span Next
							button.mt-2(
								v-if="!candidateRouteStore.isLastButton && !candidateRouteStore.isFirstButton",
								:disabeled="isSaveLoading",
								@click="onClickSave"
							)
								span Register
</template>

<style lang="scss" scoped>
#auth-user-page {
	display: flex;
	gap: 20px 30px;
	// align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;
	padding: 20px;
	// padding: 0 20px 20px;
	// overflow: auto;
	background-color: $background-color;

	.content {
		display: flex;
		flex-direction: column;
		gap: 20px 10px; // Bigger gap added because of the scale / transform in wrapper-steps

		.user-steps {
			min-width: 280px;
		}

		.mid-section {
			z-index: 1;

			.page {
				flex-wrap: wrap;
			}

			:deep() {
				.modern-form {
					height: auto;
					// box-shadow: none;

					.logo-wrapper {
						margin: 0 40px;
					}
				}

				.multiselect-form {
					height: 34px;
					border: 1px solid $border-color1;
					border-radius: 20px;

					&.tags {
						height: auto;
						min-height: 34px;
					}
				}

				.back-next-btns {
					margin: 30px 40px;
				}

				.action-btn {
					margin: 0 40px 30px;
					display: flex;
					gap: 5px;
					align-items: center;
					justify-content: center;

					button {
						width: auto;
					}
				}
			}
		}
	}

	@media screen and (width >= 768px) {
		gap: 110px 30px; // Bigger gap added because of the scale / transform in wrapper-steps
	}

	@media screen and (width > 1024px) {
		.content {
			flex-direction: row;

			.user-steps {
				:deep() {
					.wrapper-steps {
						transform: scale(1.1) translateY(40px);
					}
				}
			}
		}

		.mid-section {
			.page {
				flex-wrap: nowrap;
			}
		}
	}
}
</style>
