// Updated 24-2

type TWebsocketOptions = {
	autoReconnect: boolean;
	delayReconnect: number;
	retries: number;
	protocols: string | string[];
	useLogs: boolean;
	cb: Partial<TWebsocketEventsPayload> | null;
};
type TWebsocketEventsPayload = {
	onOpen: (ws: CWebsocket, evt: WebSocketEventMap["open"]) => void;
	onMessageData: <T extends string | ArrayBuffer | Blob>(
		ws: CWebsocket,
		evt: WebSocketEventMap["message"],
		data: T,
	) => void;
	onError: (ws: CWebsocket, evt: WebSocketEventMap["error"]) => void;
	onClose: (ws: CWebsocket, evt: WebSocketEventMap["close"]) => void;
};
type TWebsocketEvents = {
	onOpen: (evt: WebSocketEventMap["open"]) => void;
	onMessage: (evt: WebSocketEventMap["message"]) => void;
	onError: (evt: WebSocketEventMap["error"]) => void;
	onClose: (evt: WebSocketEventMap["close"]) => void;
};

export const READY_STATES = {
	Connecting: 0,
	Open: 1,
	Closing: 2,
	Closed: 3,
} as const;

export class CWebsocket {
	private _instance!: WebSocket;
	private _reconnectTimeout: ReturnType<typeof setTimeout> | null = null;
	private readonly _logs: string[] = [];
	wsStatus: keyof typeof READY_STATES | null = null;
	/**
	 * @property cb - Callback events linked to websocket events
	 */
	readonly options: TWebsocketOptions = {
		autoReconnect: true,
		delayReconnect: 1000,
		retries: 3,
		protocols: [],
		useLogs: false,
		cb: null,
	} as const;

	/**
	 * Creates a websocket connection and manages it
	 */
	constructor(url: string | URL, options?: Partial<TWebsocketOptions>) {
		options && this.setOptions(options);
		this.setCurrentInstance(url, this.options.protocols);
	}

	private setCurrentInstance(url: string | URL, protocols?: string | string[]) {
		// Set correct protocol URL
		const newLink: URL = typeof url === "string" ? new URL(url) : url;
		newLink.protocol =
			newLink.protocol.startsWith("https") || newLink.protocol.startsWith("wss")
				? "wss"
				: "ws";

		// Close instance before starting a new one
		if (this.wsInstance && this.wsInstance.readyState !== 3) {
			this.wsInstance.close();
			this.removeCallbacks(); // No need for delay here for [close event]
		}
		this._instance = new WebSocket(newLink, protocols);
		this.setReadyStateFr();

		// Callbacks after instance is created
		this.initCallbacks();
	}
	private setReadyStateFr(addLog = true) {
		const rs = this._instance.readyState;
		this.wsStatus = Object.keys(READY_STATES).find(
			(key) => READY_STATES[key as keyof typeof READY_STATES] === rs,
		) as keyof typeof READY_STATES;
		addLog && this.addLog(this.wsStatus);
	}

	// #region Handlers
	private initCallbacks() {
		if (!this.options.cb) {
			console.error("No callbacks - start");
			return;
		}

		this.wsInstance.addEventListener("open", this.onOpenEvt);
		this.wsInstance.addEventListener("message", this.onSendEvt);
		this.wsInstance.addEventListener("error", this.onErrEvt);
		this.wsInstance.addEventListener("close", this.onCloseEvt);
	}
	private removeCallbacks() {
		if (!this.options.cb) {
			console.error("No callbacks - end");
			return;
		}

		this.wsInstance.removeEventListener("open", this.onOpenEvt);
		this.wsInstance.removeEventListener("message", this.onSendEvt);
		this.wsInstance.removeEventListener("error", this.onErrEvt);
		this.wsInstance.removeEventListener("close", this.onCloseEvt);
	}

	private readonly onOpenEvt = (
		evt: Parameters<TWebsocketEvents["onOpen"]>[0],
	) => {
		this.setReadyStateFr();
		this.options.cb?.onOpen && this.options.cb.onOpen(this, evt);
	};
	private readonly onSendEvt = (
		evt: Parameters<TWebsocketEvents["onMessage"]>[0],
	) => {
		let data: string | ArrayBuffer | Blob = evt.data;
		this.addLog(`Exchanged message - type: ${typeof data}`);
		if (this.options.cb?.onMessageData) {
			if (typeof data === "string") {
				this.addLog(data);
				try {
					data = JSON.parse(evt.data);
				} catch (err: any) {
					console.warn(":: Failed to parse data", evt.data, err.message);
				}
			}

			this.options.cb.onMessageData(this, evt, data);
		}
	};
	private readonly onErrEvt = (
		evt: Parameters<TWebsocketEvents["onError"]>[0],
	) => {
		this.addLog("Error");
		this.options.cb?.onError && this.options.cb.onError(this, evt);
		this.reconnectLogic("err");
	};
	private readonly onCloseEvt = (
		evt: Parameters<TWebsocketEvents["onClose"]>[0],
	) => {
		this.setReadyStateFr(false);
		const isManualClose = evt.code === 1006;
		this.addLog(isManualClose ? "Closed from user" : "Closed from server");
		this.options.cb?.onClose && this.options.cb.onClose(this, evt);
		this.removeCallbacks();

		!evt.wasClean && !isManualClose && this.reconnectLogic("close");
	};
	private reconnectLogic(type: "err" | "close") {
		this.addLog("Reconnect");
		if (this._reconnectTimeout) {
			clearTimeout(this._reconnectTimeout);
			this._reconnectTimeout = null;
		}

		if (this.options.autoReconnect) {
			if (this.options.retries > 0) {
				this.options.retries -= 1;

				this._reconnectTimeout = setTimeout(() => {
					console.log(
						`:: Reconnected ${type} - tries left: ${this.options.retries}`,
					);
					this.setCurrentInstance(
						this._instance.url,
						this._instance.protocol || [],
					);

					this._reconnectTimeout = null;
				}, this.options.delayReconnect);
			} else {
				console.log(":: Reconnect stopped, no more tries");
			}
		}
	}
	// #endregion

	// #region Logs
	addLog(val: string) {
		this.options.useLogs && this.logs.push(val);
	}
	clearLogs() {
		this.logs.length = 0;
	}
	get logs(): string[] {
		return this._logs;
	}

	// External / Native
	setOptions(opt: Partial<TWebsocketOptions>) {
		Object.assign(this.options, opt);
	}
	close(code?: number, reason?: string): void {
		this.wsInstance.close(code, reason);
	}
	send(
		data:
			| string
			| Record<string, any>
			| ArrayBufferLike
			| Blob
			| ArrayBufferView,
	): void {
		if (this.wsInstance.readyState === READY_STATES.Open) {
			let dataPayload:
				| string
				| ArrayBufferLike
				| Blob
				| ArrayBufferView
				| null = null;
			const isRegularObj = Boolean(
				data &&
					typeof data === "object" &&
					!(data instanceof ArrayBuffer) &&
					!(data instanceof Blob),
			);

			dataPayload = isRegularObj
				? JSON.stringify(data)
				: (data as NonNullable<typeof dataPayload>);

			this.wsInstance.send(dataPayload);
			// } else {
			// console.warn(":: WS not ready", this.wsStatus);
		}
	}

	get wsInstance(): WebSocket {
		return this._instance;
	}
}
