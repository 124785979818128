import type { TCvItem } from "@/api/types";
import {
	CFormItem,
	CTypeItems,
	type TCvItemAdd,
	type TCvItemAddPart,
	type TCvItemEdit,
} from "./formTypes";
import { TYPE_CATS } from "../constants";

/**
 * NOT USED!! OR INCORRECT
 */
export class CPortTypeItems extends CTypeItems {
	protected readonly items: CTypeItem[] = [];

	constructor(...items: TCvItem[]) {
		super();
		if (items?.length) {
			for (const item of items) {
				this.addItem(item);
			}
		} else {
			this.addItem();
		}
	}
	addItem(item?: TCvItem, shouldChangeActive = true): CTypeItem {
		const itemInstance = new CTypeItem(item);
		this.items.push(itemInstance);
		if (shouldChangeActive) {
			this.activeItemHash = itemInstance.uniqueId;
		}
		return itemInstance;
	}
	updateItem(obj: TCvItem, uniqueId: string) {
		const index = this.items.findIndex((item) => item.uniqueId === uniqueId);
		if (index !== -1) {
			this.items[index].updateVal(obj);
		}
	}
	getAllAdded(): TCvItemAdd | null {
		// TODO NEEDS Backend support
		// const tempArr: TCvItemAdd = {
		// 	cvc_id: TYPE_CATS.porfolio,
		// 	items: [],
		// };
		// for (const item of this.getItems) {
		// 	if (!item.id) {
		// 		const items = item.toDbAdd().items;
		// 		if (Object.values(items).some(Boolean)) {
		// 			// Only if all values exist, the object should be included
		// 			tempArr.items.push(items);
		// 		}
		// 	}
		// }
		// if (tempArr.items.length) {
		// 	return tempArr;
		// }
		return null;
	}
	getAllEditted() {
		const tempArr = [];
		for (const item of this.getItems) {
			if (item.id) {
				tempArr.push(item.toDb());
			}
		}
		return tempArr;
	}

	get getItems() {
		return this.items.filter(
			(item) => !this.pendingRemoval.includes(item.uniqueId),
		);
	}
}
class CTypeItem extends CFormItem {
	public: 0 | 1 = 0;
	porfolioLink = "";
	coverLetter = "";
	validations: Record<string, any> = {};

	constructor(obj?: TCvItem) {
		super();
		if (obj) {
			this.updateVal(obj);
		}
	}

	updateVal(obj: TCvItem) {
		this.id = obj.cve_id;
		this.public = obj.cve_public ? 1 : 0;
		this.porfolioLink = obj.cve_title;
		this.coverLetter = obj.cve_institution;
	}

	toDbAdd(): TCvItemAddPart {
		return {
			cvc_id: TYPE_CATS.porfolio,
			items: {
				cve_title: this.porfolioLink,
				cve_institution: this.coverLetter,
				// cve_attributes: {
				// 	cve_portfolio_link: this.porfolioLink,
				// 	cve_portfolio_cover_letter: this.coverLetter,
				// },
			},
		};
	}
	toDb(): TCvItemEdit {
		return {
			cvc_id: TYPE_CATS.porfolio,
			cve_id: this.id,
			cve_public: this.public ? 1 : 0,
			cve_title: this.porfolioLink,
			cve_institution: this.coverLetter,
			// cve_attributes: {
			// 	cve_field: this.fieldStudy,
			// 	cve_start_date: this.startDate,
			// 	cve_end_date: this.endDate,
			// 	cve_ongoing: this.ongoing,
			// 	// cve_description: this.desc,
			// },
		};
	}

	get isEmptyForm(): boolean {
		return !this.porfolioLink && !this.coverLetter;
	}
}
