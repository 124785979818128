<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import {
	openConfirmBlockUser,
	openUserInfo,
} from "../../components/modals/modalDefinitions";
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";
// import { useTokenStore } from "@/store/token";
import { useRouter } from "vue-router";

// import { useToastStore } from "@/store/toast";
// const toastStore = useToastStore();

definePage({
	name: "AdminCandidates",
});

// const tokenStore = useTokenStore();
type TRole = {
	rol_id: number;
	rol_name: string;
};
const search = ref("");
const date = ref<string>("");
const roles = ref<TRole[]>([]);
const role = ref<TRole>(null);
const showOptionsData = ref<any>({});
const manageCallApi = ref<boolean>(true);
const router = useRouter();

const columns = reactive<TTableColumn[]>([
	{
		id: "usr_name",
		label: $t("app.candidates.name"),
	},
	// {
	// 	id: "lastName",
	// 	label: $t("app.candidates.lastName"),
	// },
	{
		id: "usr_created",
		label: $t("app.candidates.regDate"),
	},
	{
		id: "cnt_name",
		label: $t("app.candidates.country"),
	},
	{
		id: "usr_email",
		label: $t("app.candidates.email"),
	},
	{
		id: "rol_name",
		label: $t("app.admin.userType"),
	},
	{
		id: "status",
		label: $t("app.candidates.status"),
	},

	{
		id: "action",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([]);
const load = ref(false); // functionality
function formatDate(date: string) {
	if (date) return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}

function showOptions(e: any) {
	showOptionsData.value = e;
}

function getMoreInfo(e: any) {
	openUserInfo(e, confirm);
	showOptionsData.value = {};
}

function getBlock(e: any) {
	openConfirmBlockUser(e, e.usr_deleted, confirm);
	showOptionsData.value = {};
}

async function confirm() {
	await getAdminUsers(true);
}

async function getAdminUsers(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	type TParam = {
		limit: number;
		offset: number;
		search?: string;
		date?: string;
		rol_id?: number;
	};
	const params: TParam = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value) {
		params.search = search.value;
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	if (role.value) {
		params.rol_id = role.value.rol_id;
	}
	try {
		const res = await api.getAdminUsers(params);

		rows.value.push(...res.data.data);
		manageCallApi.value = res.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

async function getRoles() {
	try {
		const res = await api.getRoles();
		if (res.data.data) {
			roles.value = res.data.data.filter(
				(obj) => obj.rol_id !== 6 && obj.rol_id !== 9,
			);
		}
	} catch (e) {
		console.log(e);
	}
}

function goToProfile(e: TTableRow) {
	void router.push({
		name: "PublicProfile",
		params: { id: e.usr_id },
	});
}
function deselectStatus() {
	role.value = null;
}
watch([search, date, role], async () => {
	await getAdminUsers(true);
});

onMounted(async () => {
	await getAdminUsers();
	await getRoles();
});
</script>

<template lang="pug">
.admin-subpage
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.admin.users") }}
			.wrapp-filters
				search-input(
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				.date
					.group
						.dropdown
							multiselect-form(
								v-model="role",
								:searchable="false",
								:create-option="true",
								:close-on-select="true",
								:append-to-body="true",
								placeholder="User type",
								label="rol_name",
								value-prop="rol_id",
								:options="roles",
								:can-deselect="true",
								@deselect="deselectStatus"
							)
					modern-date-picker(
						v-model="date",
						:enable-time-picker="false",
						style="width: 150px",
						:placeholder="$t('app.candidates.regDate')"
					)

		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getAdminUsers"
		)
			template(#[`table-body-cell`]="slotProps")
				td(v-if="slotProps.column.id == 'usr_created'")
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
				td(v-else-if="slotProps.column.id == 'status'")
					.statusUnavail(v-if="slotProps.row.usr_deleted")
						span {{ $t("app.candidates.blockedUser") }}

				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span(@click="showOptions(slotProps.row)")
						img(src="@/assets/image/actionIcons/more.svg")
					.show-reports-data(v-if="showOptionsData.usr_id === slotProps.row.usr_id")
						.close-btn(data-name="close-action", @click="showOptionsData = {}")
							svg.close-icon(
								width="16",
								height="16",
								viewBox="0 0 16 16",
								fill="none",
								xmlns="http://www.w3.org/2000/svg"
							)
								path(
									fill-rule="evenodd",
									clip-rule="evenodd",
									d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
									fill="#575758"
								)
						.wrapp-info(@click="getMoreInfo(slotProps.row)")
							span {{ $t("app.candidates.moreInfo") }}
						.wrapp-info(
							v-if="!slotProps.row.usr_deleted",
							@click="goToProfile(slotProps.row)"
						)
							span {{ $t("app.feedback.goToProfile") }}
						.wrapp-info(
							v-if="slotProps.row.rol_name !== 'super-admin' && !slotProps.row.usr_deleted",
							@click="getBlock(slotProps.row)"
						)
							span {{ $t("app.candidates.block") }}
							//- span(v-else) {{ $t("app.candidates.unblock") }}

				td(
					v-else,
					:class="{ bold: slotProps.column.id === 'firstName' || slotProps.column.id === 'lastName' }"
				) {{ slotProps.row[slotProps.column.id] }}
			template(#table-body-extra-row-content="slotProps")
				transition(name="show-row")
					td.info-row(
						v-show="showOptionsData.usr_id === slotProps.row.usr_id",
						:colspan="columns.length"
					)
		.loader-wrap-intern(v-else-if="load")
			.loader
		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/noCandidatesFund.png")
</template>

<style lang="scss" scoped>
.admin-subpage {
	font-family: "Open Sans", sans-serif;
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}

		.wrapp-filters {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 15px;

			.dropdown {
				padding: 0 10px;
				border-radius: $input-radius;
				outline: none;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 200px;
				align-items: center;
			}
			.date {
				padding: 0 10px 0 2px;
				gap: 20px;
				display: flex;
				align-items: center;
				// margin-top: 10px;
			}
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			max-width: 40px;
			margin-left: auto;
			display: flex;
			align-items: center;
			height: 40px !important;
			position: relative;
			padding-right: 22px;

			span {
				cursor: pointer;
			}

			img {
				margin-left: 18px;
			}

			.show-reports-data {
				width: 149px;
				// min-height: 86px;
				box-sizing: border-box;
				padding: 12px 15px 0;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 1px;
				border-radius: 20px;
				background: $background-color;
				border: 1px solid #dfdfe8;
				// background: #e44e75;

				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0.03em;
				text-align: left;

				& > .close-btn {
					margin: 0 0 auto auto;
					display: flex;
					align-self: center;
					padding: 3px;
					justify-content: flex-end;
					position: absolute;
					top: 5px;
					right: 5px;

					.close-icon {
						$size: 13px;

						height: $size;
						width: $size;
						cursor: pointer;

						path {
							fill: #aeadad;
						}

						&:hover {
							path {
								fill: #ffb4c3;
								transition: all 0.3s ease;
							}
						}
					}
				}

				img {
					cursor: pointer;
					margin-left: 18px;
					position: absolute;
				}

				.wrapp-info {
					margin-bottom: 15px;
				}
				.wrapp-info:hover {
					color: $app-accent-color1;
					cursor: pointer;
				}
			}
		}
	}
}
.show-row-enter-active {
	animation: show-row-anim 0.4s;
}

.show-row-leave-active {
	animation: show-row-anim 0.4s reverse;
}

@keyframes show-row-anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
<style lang="scss">
.admin-subpage {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
}
</style>
