import api from "@/api";
import { useCaptchaComposable } from "@/components/shared/recaptcha/recaptcha";
import type { TSetImageEvent } from "@/components/cropper/InputImg.vue";
import { defineStore } from "pinia";
import type {
	TCountryItem,
	TCvGet,
	TEditCVParams,
	TUserEdit,
} from "@/api/types";
import { TYPE_CATS } from "@/assets/js/constants";
import { CBasicType } from "@/assets/js/formTypes/basicInstance";
import { CEduTypeItems } from "@/assets/js/formTypes/eduInstance";
import { CExpTypeItems } from "@/assets/js/formTypes/expInstance";
import { CLangTypeItems } from "@/assets/js/formTypes/langInstance";
import { CSkillTypeItems } from "@/assets/js/formTypes/skillInstance";
import { CCSkillTypeItems } from "@/assets/js/formTypes/cSkillInstance";
// import { CHobbyTypeItems } from "@/assets/js/formTypes/hobbyInstance";
import { CPortTypeItems } from "@/assets/js/formTypes/portInstance";
import type { TCvItemAdd } from "@/assets/js/formTypes/formTypes";
import { useTokenStore } from "./token";
import { useDefaultStore } from ".";
// import { useToastStore } from "./toast";

export const useCandidateStore = defineStore("candidateForm", () => {
	const { recaptcha } = useCaptchaComposable();
	const store = useDefaultStore();
	const tokenStore = useTokenStore();

	const formUser = reactive({
		basic: new CBasicType(),
		edu: new CEduTypeItems(),
		exp: new CExpTypeItems(),
		lang: new CLangTypeItems(),
		skills: new CSkillTypeItems(),
		computerSkills: new CCSkillTypeItems(),
		// hobby: new CHobbyTypeItems(),
		port: new CPortTypeItems(),
	});

	const userAvatarImage = ref<TSetImageEvent | null>(null);
	const userAvatarPendingChange = ref(false);
	const countryOptions = ref<TCountryItem[]>([]);

	const registerImageSrc = computed(() => {
		if (userAvatarImage.value?.base64) {
			return userAvatarImage.value.base64;
		}
		return null;
	});
	const profileImageSrc = computed<string>(() => {
		if (userAvatarImage.value?.base64) {
			return userAvatarImage.value.base64;
		}
		return tokenStore.getImageUrl(formUser.basic.imgId) || "";
	});

	/**
	 * Call this methods after registration and after user logout
	 */
	function clearUserForm() {
		formUser.basic = new CBasicType();
		formUser.edu = new CEduTypeItems();
		formUser.exp = new CExpTypeItems();
		formUser.lang = new CLangTypeItems();
		formUser.skills = new CSkillTypeItems();
		formUser.computerSkills = new CCSkillTypeItems();
		// formUser.hobby = new CHobbyTypeItems();
		formUser.port = new CPortTypeItems();
	}

	// #region API
	async function createUser(): Promise<boolean> {
		const fd = formatPayloadCreate(formUser);
		const google_token = await recaptcha("registerUser");
		// const google_token = store.tokenCaptcha;
		fd.append("google_token", google_token);
		store.refreshToken = !store.refreshToken;
		try {
			const res = await api.authCandidateRegister(fd);
			// clearUserForm();
			console.warn(":: CREATED", res.data);
			return true;
		} catch (err: any) {
			console.warn("err", err.message);
			// if (err.response?.data?.name === "usr_phone") {
			// 	useToastStore().openToastError("Parameter 'phone' is required");
			// }
			return false;
		}
	}
	async function editUser(): Promise<boolean> {
		const fd = formatPayloadEdit();
		if (!fd) {
			return true;
		}
		const google_token = await recaptcha("editUser");
		// const google_token = store.tokenCaptcha;
		fd.append("google_token", google_token);
		store.refreshToken = !store.refreshToken;
		try {
			await api.postUserProfileEdit(fd);
			userAvatarPendingChange.value = false;
			return true;
		} catch (err: any) {
			console.warn("err", err.message);
			return false;
		}
	}
	async function fetchCountries() {
		try {
			const res = await api.getCountries();
			countryOptions.value = res.data.data;
		} catch (err: any) {
			console.warn(err.message);
		}
	}
	async function fetchUserProfile(logKeys = true) {
		try {
			const res = await api.getUserProfile();
			formUser.basic.updateVal(res.data.data);
			tokenStore.updateUserImageId(formUser.basic.imgId); // To refresh the image if changed while already logged in
			tokenStore.updateUserName(formUser.basic.fullName); // To refresh the name if changed while already logged in
			if (logKeys) {
				formUser.basic.clearTouchedKeys();
				formUser.basic.logTouchedKeys = true;
			}
		} catch (err: any) {
			console.warn(err.message);
		}
	}
	async function fetchSetupCvData() {
		try {
			const res = await api.getCV();
			const tempCvData = res.data.data;
			populateCvData(tempCvData);
		} catch (err: any) {
			console.warn("Err", err.message);
		}
	}
	async function editCvData(): Promise<boolean> {
		const getAllAdded = () => {
			const tempArr: TCvItemAdd[] = [];
			for (const formItem of Object.values(formUser)) {
				if ("getAllAdded" in formItem) {
					const item = formItem.getAllAdded();
					// console.log(item, "-------kkk");
					if (item) {
						tempArr.push(item);
					}
				}
			}

			return tempArr;
		};

		const getAllEditted = () => {
			const tempArr = [];
			for (const formItem of Object.values(formUser)) {
				if ("getAllEditted" in formItem) {
					tempArr.push(...formItem.getAllEditted());
				}
			}

			return tempArr;
		};
		const getAllDeleted = () => {
			const tempArr = [];
			for (const formItem of Object.values(formUser)) {
				if ("getRemovedItemsDb" in formItem) {
					tempArr.push(...formItem.getRemovedItemsDb());
				}
			}

			return tempArr;
		};

		const params: TEditCVParams = {};
		const added = getAllAdded();
		if (added.length) {
			params.cv_data_add = added;
		}
		const editted = getAllEditted();
		if (editted.length) {
			params.cv_data_edit = editted;
		}
		const deleted = getAllDeleted();
		if (deleted.length) {
			params.cv_data_delete = deleted;
		}

		if (Object.keys(params).length > 0) {
			try {
				await api.editCV(params);
				return true;
			} catch (err: any) {
				console.warn("Err", err.message);
				return false;
			}
		} else {
			return true;
		}
	}
	// #endregion

	// #region Data Format
	function populateCvData(dataArray: TCvGet[]) {
		for (const data of dataArray) {
			if (data.items) {
				const eduObj = data.cvc_id === TYPE_CATS.education;
				const expObj = data.cvc_id === TYPE_CATS.experience;
				const langObj = data.cvc_id === TYPE_CATS.languages;
				const skillObj = data.cvc_id === TYPE_CATS.skills;
				const cSkillObj = data.cvc_id === TYPE_CATS["computer-skills"];
				// const hobbyObj = data.cvc_id === TYPE_CATS.hobby;
				const portObj = data.cvc_id === TYPE_CATS.porfolio;

				if (eduObj) {
					formUser.edu = new CEduTypeItems(...data.items);
				} else if (expObj) {
					formUser.exp = new CExpTypeItems(...data.items);
				} else if (langObj) {
					formUser.lang = new CLangTypeItems(...data.items);
				} else if (skillObj) {
					formUser.skills = new CSkillTypeItems(...data.items);
				} else if (cSkillObj) {
					formUser.computerSkills = new CCSkillTypeItems(...data.items);
					// } else if (hobbyObj) {
					// 	formUser.hobby = new CHobbyTypeItems(...data.items);
				} else if (portObj) {
					formUser.port = new CPortTypeItems(...data.items);
				}
			}
		}
	}

	function formatPayloadEdit(): FormData | null {
		const payload: Partial<TUserEdit> = {
			...formUser.basic.toDbTouchedKeys(),
		};

		if (userAvatarPendingChange.value) {
			payload.image = userAvatarImage.value?.file || null;
		}

		if (Object.keys(payload).length) {
			const fd = generateFormDataFromPayload(payload, true);
			return fd;
		}
		return null;
	}
	function formatPayloadCreate(
		data: typeof formUser,
		canSendEmptyValues = false,
	): FormData {
		const basicObj = data.basic.toDb();
		// const eduObj = data.edu.getAllAdded();
		// const expObj = data.exp.getAllAdded();
		// const langObj = data.lang.getAllAdded();
		// const skillObj = data.skills.getAllAdded();
		// const cSkillObj = data.computerSkills.getAllAdded();
		const payload: TRegisterPayload = {
			...basicObj,
			image: userAvatarImage.value?.file,
			usr_employmentlength: 30,
			// cv_data: [
			// 	eduObj as any,
			// 	expObj as any,
			// 	langObj as any,
			// 	skillObj as any,
			// 	cSkillObj as any,
			// ],
		};

		// console.warn(":: Payload for register:", payload);
		return generateFormDataFromPayload(payload, canSendEmptyValues);
	}

	function generateFormDataFromPayload(
		payload: Record<string, any>,
		canSendEmptyValues: boolean,
	) {
		const fd = new FormData();
		for (const [kItem, kVal] of Object.entries(payload)) {
			if (typeof kVal === "number") {
				fd.append(kItem, `${kVal}`);
			} else if (typeof kVal === "boolean") {
				fd.append(kItem, kVal ? "1" : "0");
			} else {
				const allowedRaw = kVal instanceof File;
				if (typeof kVal === "string") {
					const valueTrimmed = kVal.trim();
					if (valueTrimmed || canSendEmptyValues) {
						// Don't send empty values
						fd.append(kItem, valueTrimmed);
					}
				} else if (allowedRaw) {
					fd.append(kItem, kVal);
				} else {
					if (kVal) {
						console.warn(":: Any Other Value ->> STRING", kVal);
						const strVal = JSON.stringify(kVal);
						fd.append(kItem, strVal);
					} else if (kItem === "image") {
						// This will reset image
						// fd.append(kItem, "");
						fd.append("delete_image", "1");
					} else {
						// Ignore no values
						console.warn(">> Not sending", kItem, kVal);
					}
				}
			}
		}

		return fd;
	}
	// #endregion

	function changeAvatarImage(payload: TSetImageEvent | null = null) {
		if (payload) {
			userAvatarImage.value = payload;
		} else {
			userAvatarImage.value = null;
			formUser.basic.imgId = 0;
		}
		userAvatarPendingChange.value = true;
	}

	return {
		formUser,
		registerImageSrc,
		profileImageSrc,
		countryOptions,
		changeAvatarImage,
		createUser,
		editUser,
		fetchCountries,
		fetchUserProfile,
		fetchSetupCvData,
		editCvData,
		clearUserForm,
	};
});

export type TRegisterPayload = {
	usr_name: string;
	usr_position: string;
	usr_email: string;
	cnt_code: string;
	usr_address: string;
	image?: File;
	// image?: string;
	google_token?: string;
	usr_socialnetworks?: string;
	usr_dateofbirth?: string;
	usr_employmentlength?: number;
	usr_phone: string;
	cv_data?: [
		{
			cvc_id: 2;
			items: [
				{
					cve_title: string;
					cve_institution: string;
					cve_public: 0 | 1;
					cve_attributes?: {
						cve_start_date: string;
						cve_end_date?: string;
						cve_ongoing?: 0 | 1;
						cve_field: string;
					};
				},
			];
		},
		{
			cvc_id: 3;
			items: [
				{
					cve_title: string;
					cve_institution: string;
					cve_public: 0 | 1;
					cve_attributes?: {
						cve_start_date: string;
						cve_end_date?: string;
						cve_ongoing?: 0 | 1;
						cve_description?: string;
						cve_location?: string;
						cve_location_type?: string;
					};
				},
			];
		},
		{
			cvc_id: 4;
			items: [
				{
					cve_title: string;
					cve_institution?: string;
				},
			];
		},
		{
			cvc_id: 5;
			items: [
				{
					cve_title: string;
					cve_institution?: string;
				},
			];
		},
		{
			cvc_id: 6;
			items: [
				{
					cve_title: string;
					cve_institution?: string;
				},
			];
		},
	];
};
