<script setup lang="ts">
import { type TClient, type TListing } from "@/assets/js/types";
import { $t } from "@/i18n";
import { DateInstance } from "@/assets/js/dateHelper";
import {
	type TTableColumn,
	type TTableRow,
} from "../../../components/shared/table/ModernTable.vue";
import { useTokenStore } from "@/store/token";
import jsPDF from "jspdf";
import api from "@/api";
import "@/assets/fonts/archivo-regular-normal.js";
import "@/assets/fonts/archivo-bold.js";

definePage({
	name: "ReportsPage",
	// path: "",
});

type TTypes = {
	id: number | string;
	label: string;
};
const tokenStore = useTokenStore();
const columnsListings = reactive<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.report.listingName"),
	},
	{
		id: "total",
		label: $t("app.report.applicants"),
	},
	{
		id: "hired",
		label: $t("app.report.employed"),
	},
	{
		id: "rejected",
		label: $t("app.report.rejected"),
	},
]);

const rowsListings = ref<TTableRow[]>([]);
const activeListings = ref<number>();
const applicantNumber = ref<number>();

const applicantNumberSecondList = ref<{ total: number; hired: number }>(null);

const columnsRejected = reactive<TTableColumn[]>([
	{
		id: "aps_name",
		label: $t("app.report.stage"),
	},
	{
		id: "count",
		label: $t("app.report.rejectedApplicants"),
	},
]);
const rowsRejected = ref<TTableRow[]>([]);

const columnsEducation = reactive<TTableColumn[]>([
	{
		id: "app_edulevel",
		label: $t("app.report.education"),
	},
	{
		id: "count",
		label: $t("app.report.applicants"),
	},
]);

const rowsEducation = ref<TTableRow[]>([]);

const manageCallApiRols = ref(false);

const types = ref<TTypes[]>([
	{ id: 1, label: $t("app.report.byClient") },
	{ id: 2, label: $t("app.report.byListing") },
]);
const selectedTypes = ref<TTypes>(null);

const clients = ref<TClient[]>([]);
const selectedClient = ref<TClient>(null);
const showCliMsg = ref(false);

const listings = ref<TListing[]>([]);
const selectedListing = ref<TListing>(null);

const dateFrom = ref<string>(null);
const dateTo = ref<string>(null);

async function getData() {
	// if (date.value) {
	// 	params.date = DateInstance.getFormattedDate(date.value, "YYYY-MM-DD");
	// }

	if (selectedTypes.value.id === 1) {
		await getReportsByClient();
	} else if (selectedTypes.value.id === 2) {
		await getReportsByListing();
	}
}

async function getReportsByClient() {
	const params = {
		cli_id: selectedClient.value.cli_id,
		from: DateInstance.getFormattedDate(dateFrom.value, "YYYY-MM-DD"),
		to: DateInstance.getFormattedDate(dateTo.value, "YYYY-MM-DD"),
	};

	try {
		const res = await api.getReportsByClient(params);
		console.log(res);
		applicantNumber.value = res.data.data.applicant_number?.count;
		activeListings.value = res.data.data.active;
		rowsListings.value = res.data.data.listing_info;
	} catch (e) {
		console.log(e);
	}
}

async function getReportsByListing() {
	const params = {
		cli_id: selectedClient.value.cli_id,
		job_id: selectedListing.value.job_id,
		from: DateInstance.getFormattedDate(dateFrom.value, "YYYY-MM-DD"),
		to: DateInstance.getFormattedDate(dateTo.value, "YYYY-MM-DD"),
	};

	try {
		const res = await api.getReportsByListing(params);
		console.log(res);
		applicantNumberSecondList.value = res.data.data.applicant_number;

		rowsRejected.value = res.data.data.appstatus_info;
		rowsEducation.value = res.data.data.education_info;
	} catch (e) {
		console.log(e);
	}
}

function writeDate(space = false) {
	return space
		? (dateFrom.value
				? DateInstance.getFormatDTLocale(dateFrom.value, "DD.MMMYYYY.")
				: "") +
				"-" +
				(dateTo.value
					? DateInstance.getFormatDTLocale(dateTo.value, "DD.MMMYYYY.")
					: "")
		: (dateFrom.value
				? DateInstance.getFormatDTLocale(dateFrom.value, "DD. MMM YYYY.")
				: "") +
				" - " +
				(dateTo.value
					? DateInstance.getFormatDTLocale(dateTo.value, "DD. MMM YYYY.")
					: "");
}

async function exportData() {
	console.log("set export");
	let doc = new jsPDF();

	// format: 'a4',
	//  putOnlyUsedFonts:true

	doc = new jsPDF({ orientation: "p", unit: "px", format: [595, 842] });
	doc.addFont("archivo-regular-normal.ttf", "Archivo", "normal");
	doc.addFont("archivo-bold.ttf", "Archivo", "bold");

	// eslint-disable-next-line unicorn/prefer-query-selector
	const el = document.getElementById("myreport") as HTMLElement;
	// const el2 = el.cloneNode(true) as HTMLElement;
	// el2.style.backgroundColor = "lightblue";
	await doc.html(el, {
		callback: function (pdf) {
			pdf.save(`${writeDate(true)}report.pdf`);
			// pdf.save("report.pdf");
		},
		x: 10,
		y: 0,
	});
}
async function getCmpClientAll() {
	try {
		const res = await api.getCmpClientAll();
		// console.log(res.data.data.data);
		clients.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

async function getJobsAll(e: number) {
	if (!tokenStore.userData?.cmp_id) {
		return;
	}
	try {
		const res = await api.getJobsAll({
			cli_id: e,
			cmp_id: Number(tokenStore.userData.cmp_id),
		});
		// console.log(res.data.data.data);
		listings.value = res.data.data;
		showCliMsg.value = listings.value.length === 0 ? true : false;
	} catch (e) {
		console.log(e);
	}
}

function setClients(e: TClient) {
	console.log(e);
	getJobsAll(e.cli_id);
}

const showGenerate = computed(() => {
	if (
		selectedTypes.value &&
		dateTo.value &&
		dateFrom.value &&
		((selectedTypes.value.id === 2 && selectedListing.value) ||
			selectedTypes.value.id === 1)
	) {
		return true;
	}
	return false;
});

onMounted(async () => {
	await getCmpClientAll();
});
</script>
<template lang="pug">
.reports-data-page
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.report.reports") }}
			button(@click="exportData")
				span {{ $t("app.report.export") }}
		.wrapp-filters
			.dropdown
				multiselect-form(
					v-model="selectedTypes",
					:options="types",
					label="label",
					value-prop="id",
					placeholder="Choose report"
				)
			.dropdown(v-if="selectedTypes")
				multiselect-form(
					v-model="selectedClient",
					:options="clients",
					label="cli_name",
					value-prop="cli_id",
					placeholder="Choose client",
					@select="setClients($event)"
				)
			.dropdown(v-if="selectedTypes && selectedTypes.id === 2")
				multiselect-form(
					v-model="selectedListing",
					:options="listings",
					label="job_title",
					value-prop="job_id",
					placeholder="Choose listing"
				)
			modern-date-picker(
				v-if="selectedTypes",
				v-model="dateFrom",
				placeholder="Date from",
				:enable-time-picker="false",
				:max-date="dateTo",
				style="width: 150px"
			)
			modern-date-picker(
				v-if="selectedTypes",
				v-model="dateTo",
				placeholder="Date to",
				:enable-time-picker="false",
				style="width: 150px",
				:min-date="dateFrom"
			)
			button(v-if="showGenerate", @click="getData")
				span {{ $t("app.report.generate") }}
		#myreport(v-if="showCliMsg") {{ $t("app.report.cliMsg") }}
		#myreport(v-if="showGenerate && !showCliMsg", style="font-family: Archivo")
			.wrapp-data-title
				.title {{ selectedListing ? selectedListing.job_title : selectedClient ? selectedClient.cli_name : " " }}
				.sub-title(v-if="selectedListing") {{ selectedListing ? selectedClient?.cli_name : " " }}
				.date(v-show="dateFrom || dateTo") {{ writeDate() }}
			.wrapp-numbers(v-if="selectedTypes && selectedTypes.id === 1")
				.numbers
					.numbersTxt {{ $t("app.report.numOfActiveListings") }}
					.number {{ activeListings ?? "-" }}
				.numbers
					.numbersTxt {{ $t("app.report.totalNumOfAplicants") }}
					.number {{ applicantNumber ?? "-" }}
			.wrapp-numbers(v-if="selectedTypes && selectedTypes.id === 2")
				.numbers
					.numbersTxt {{ $t("app.report.numberOfAplicants") }}
					.number {{ applicantNumberSecondList?.total ?? " - " }}
				.numbers
					.numbersTxt {{ $t("app.report.numberEmployedAplicants") }}
					.number {{ applicantNumberSecondList?.hired ?? " - " }}
			.wrapp-main
				.wrapp-table(v-if="selectedTypes && selectedTypes.id === 1")
					.main-title {{ $t("app.report.listings") }}
					modern-table(
						:rows="rowsListings",
						:columns="columnsListings",
						:has-border="false",
						:display-additional-row="false",
						:isInfiniteScroll="manageCallApiRols"
					)
						template(#[`table-body-cell`]="slotProps")
							td(:class="{ bold: slotProps.column.id === 'job_title' }") {{ slotProps.row[slotProps.column.id] }}
				.wrapp-table(v-if="selectedTypes && selectedTypes.id === 2")
					.main-title {{ $t("app.report.rejectedApplicantsStage") }}
					modern-table(
						:rows="rowsRejected",
						:columns="columnsRejected",
						:has-border="false",
						:display-additional-row="false",
						:isInfiniteScroll="manageCallApiRols"
					)
						template(#[`table-body-cell`]="slotProps")
							td(
								:class="{ bold: slotProps.column.id === 'aps_name', capitalize: slotProps.column.id === 'aps_name' }"
							) {{ slotProps.row[slotProps.column.id] }}
				.wrapp-table(v-if="selectedTypes && selectedTypes.id === 2")
					.main-title {{ $t("app.report.byEducation") }}
					modern-table(
						:rows="rowsEducation",
						:columns="columnsEducation",
						:has-border="false",
						:display-additional-row="false",
						:isInfiniteScroll="manageCallApiRols"
					)
						template(#[`table-body-cell`]="slotProps")
							td(
								:class="{ bold: slotProps.column.id === 'app_edulevel', capitalize: slotProps.column.id === 'app_edulevel' }"
							) {{ slotProps.row[slotProps.column.id] }}
</template>
<style lang="scss">
.reports-data-page {
	font-family: Archivo, sans-serif;
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;

	.main {
		width: 100%;
		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			align-items: center;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}

		.wrapp-filters {
			display: flex;
			align-items: center;
			// justify-content: center;
			flex-wrap: wrap;
			.dropdown {
				// margin: 0 10px;
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
		}
		.wrapp-filters > div,
		.wrapp-filters > button {
			margin: 10px 0;
			margin-right: 10px;
		}
	}
}
#myreport {
	background: $background-color;
	width: 575px;
	// min-height: 1000px;

	.wrapp-data-title {
		margin-top: 20px; // default is 30px but filters have 10px
		margin-bottom: 30px;
		.title {
			font-size: 37px;
			font-weight: 700;
			line-height: 40px;
			text-align: left;
		}
		.sub-title {
			font-size: 16px;
			font-weight: 700;
			line-height: 18px;
			text-align: left;
		}
		.date {
			font-size: 16px;
			font-weight: 400;
			line-height: 18px;
			text-align: left;
		}
	}
	.wrapp-numbers {
		display: flex;
		// margin-bottom: 30px;
		.numbers {
			display: flex;
			// align-items: center !important;
			margin-right: 60px;

			.numbersTxt {
				// font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 700;
				line-height: 18px;
				text-align: left;
				padding: 8px 0;
				margin-right: 10px;
			}
			.number {
				width: 50px;
				height: 34px;
				border-radius: 5px;
				background: $status-color-unlisted;
				display: flex;
				align-items: center;
				justify-content: center;
				// FONTS
				color: $background-color;
				// font-family: "Open Sans", sans-serif;
				font-size: 13px;
				font-weight: 700;
				line-height: 18px;
				text-align: center;
			}
		}
	}
	.wrapp-main {
		width: 100%;
		height: fit-content;
		.wrapp-table {
			width: 100%;
			// padding-top: 30px;
			padding-top: 50px;

			.main-title {
				// font-family: Open Sans;
				font-size: 13px;
				font-weight: 700;
				line-height: 18px;
				text-align: left;
				height: 25px;
			}
		}
	}
	.bold {
		font-weight: bold;
	}
	.capitalize {
		text-transform: capitalize;
	}
	td {
		padding-left: 6px !important;
	}
	td:first-of-type,
	th:first-of-type {
		padding-left: 15px !important;
	}
}
</style>
