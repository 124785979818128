<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../../components/shared/table/ModernTable.vue";
// import {
// 	// openConfirmBlockUser,
// 	openUserInfo,
// } from "../../../components/modals/modalDefinitions";
import api from "@/api";
import type { TCountryItem } from "@/api/types";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";

definePage({
	name: "CandidatesPage",
});

const router = useRouter();

const search = ref("");
const countries = ref<TCountryItem[] | []>([]);
const selectedCountry = ref<TCountryItem | null>(null);

const columns = reactive<TTableColumn[]>([
	{
		id: "usr_name",
		label: $t("app.candidates.name"),
	},
	// {
	// 	id: "lastName",
	// 	label: $t("app.candidates.lastName"),
	// },
	{
		id: "usr_position",
		label: $t("app.candidates.position"),
	},
	{
		id: "cnt_code",
		label: $t("app.candidates.country"),
	},
	{
		id: "usr_employmentlength",
		label: $t("app.settings.stayTime"),
	},
	{
		id: "action",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([]);
const load = ref(false);

const showOptionsData = ref<any>({});

// functionality
function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}

// function showOptions(e: any) {
// 	console.log(e);
// 	showOptionsData.value = e;
// }
function getMoreInfo(e: any) {
	showOptionsData.value = {};
	void router.push({
		name: "PublicProfile",
		params: { id: e.usr_id },
	});
}

async function getUsers(reset = false) {
	load.value = true;
	if (reset) {
		rows.value = [];
		selectedCountry.value = null;
		search.value = "";
		// manageCallApi.value = true;
	}
	type TParam = {
		offset?: number;
		limit?: number;
		search?: string;
		cnt_code?: string;
	};
	const params: TParam = {
		// limit: 20,
		// offset: rows.value.length,
	};

	if (search.value) {
		params.search = search.value;
	}
	if (selectedCountry.value) {
		params.cnt_code = selectedCountry.value.cnt_code;
	}

	try {
		const res = await api.getPotentialCandidates(params);
		rows.value = res.data.data;
		// if (res.data.data.length <= 0) {
		// 	manageCallApi.value = false;
		// }
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}
function setCountryName(code: string) {
	const cntObj = countries.value.find(
		(item: TCountryItem) => item.cnt_code.toLowerCase() === code.toLowerCase(),
	);
	return cntObj?.cnt_name;
}

watch([search, selectedCountry], async () => {
	// filter users
	console.log("sss");
	await getUsers();
});

function deselectCountries() {
	selectedCountry.value = null;
}
onMounted(async () => {
	await getUsers();
	await getCountries();
});
</script>

<template lang="pug">
.page.candidates
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.candidates.title") }}
			.wrapp-filters
				search-input(
					style="min-width: 300px; max-width: 300px",
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				.dropdown
					multiselect-form(
						v-model="selectedCountry",
						:options="countries",
						label="cnt_name",
						value-prop="cnt_code",
						placeholder="All countries",
						:can-deselect="true",
						@deselect="deselectCountries"
					)
				button(@click="getUsers(true)")
					span {{ $t("app.candidates.refresh") }}
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="false",
			style="max-height: 700px"
		)
			//- :isInfiniteScroll="manageCallApi", @scroll-more="getUsers"
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'regDate'",
					@click="getMoreInfo(slotProps.row)"
				)
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
				td(
					v-else-if="slotProps.column.id == 'status'",
					@click="getMoreInfo(slotProps.row)"
				)
					.statusUnavail(v-if="!slotProps.row[slotProps.column.id]")
						span {{ $t("app.candidates.blockedUser") }}
				td(
					v-else-if="slotProps.column.id == 'cnt_code'",
					@click="getMoreInfo(slotProps.row)"
				) {{ setCountryName(slotProps.row[slotProps.column.id]) }}

				td(
					v-else,
					:class="{ bold: slotProps.column.id === 'usr_name' || slotProps.column.id === 'usr_position' }",
					@click="getMoreInfo(slotProps.row)"
				) {{ slotProps.row[slotProps.column.id] }}
		.loader-wrap-intern(v-else-if="load")
			.loader
		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/noCandidatesFund.png")
</template>

<style lang="scss" scoped>
.page {
	// padding: 20px;
	// height: 100%;
	// width: 100%;
	font-family: "Open Sans", sans-serif;
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			align-items: center;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			display: flex;
			// align-items: center;
			margin-bottom: 10px;
			.dropdown {
				margin: 0 10px;
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			max-width: 40px;
			margin-left: auto;
			display: flex;
			align-items: center;
			height: 40px !important;
			position: relative;
			padding-right: 22px;

			span {
				cursor: pointer;
			}

			img {
				margin-left: 18px;
			}

			.show-reports-data {
				width: 149px;
				min-height: 50px;
				box-sizing: border-box;
				padding: 12px 15px 0;
				position: absolute;
				z-index: 1;
				top: 0;
				right: 1px;
				border-radius: 20px;
				background: $background-color;
				border: 1px solid #dfdfe8;
				// background: #e44e75;

				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: 0.03em;
				text-align: left;

				& > .close-btn {
					margin: 0 0 auto auto;
					display: flex;
					align-self: center;
					padding: 3px;
					justify-content: flex-end;
					position: absolute;
					top: 5px;
					right: 5px;

					.close-icon {
						$size: 13px;

						height: $size;
						width: $size;
						cursor: pointer;

						path {
							fill: #aeadad;
						}

						&:hover {
							path {
								fill: #ffb4c3;
								transition: all 0.3s ease;
							}
						}
					}
				}

				img {
					cursor: pointer;
					margin-left: 18px;
					position: absolute;
				}

				.wrapp-info {
					margin-bottom: 15px;
				}
				.wrapp-info:hover {
					color: $app-accent-color1;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
<style lang="scss">
.candidates {
	.main {
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
}
</style>
