import { useReCaptcha } from "vue-recaptcha-v3";

export const useCaptchaComposable = () => {
	const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

	/**
	 * @param action Key to use for labeling actions for backend
	 */
	const recaptcha = async (action: string): Promise<string> => {
		await recaptchaLoaded();
		const token = await executeRecaptcha(action);
		return token;
	};

	return {
		recaptcha,
	};
};
