<script setup lang="ts">
import { useCandidateStore } from "@/store/candidate";
const candidateStore = useCandidateStore();

definePage({
	name: "ProfileHobby",
});

const form = computed(() => candidateStore.formUser.basic);

function onChangeAttributes(evt: Event, key: string) {
	const value = (evt.target as HTMLInputElement).value;
	form.value.setAttrs(key, value);
}
</script>

<template lang="pug">
.page
	.form-wrapper
		modern-form
			template(#logo)
				.logo-placeholder
			template(#contentInner)
				form-comp.form-inputs
					.row
						.group
							label Your hobbies and skills
							textarea.hobby(
								:value="form.getAttrs('cve_hobbies')",
								@input="onChangeAttributes($event, 'cve_hobbies')"
							)
					.row
						.group
							label Youtube link
							input(
								type="text",
								:value="form.getAttrs('profile_youtube_link')",
								@input="onChangeAttributes($event, 'profile_youtube_link')"
							)
			template(#action)
				slot
	.right-side
		img(src="@/assets/image/auth/reg/4.png")
		//- input-img(
		//- 	:old-image="candidateStore.profileImageSrc",
		//- 	@set-image="onSetImage",
		//- 	@delete="onDeleteImage"
		//- )
</template>

<style lang="scss" scoped>
.page {
	@include candidate-profile-page;

	textarea.hobby {
		width: 100%;
		height: 350px;
	}
}
</style>
