<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../../components/shared/table/ModernTable.vue";
// import {
// 	// openConfirmBlockUser,
// 	openUserInfo,
// } from "../../../components/modals/modalDefinitions";
import api from "@/api";
import type { TCountryItem } from "@/api/types";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";
import { openConfirmModal } from "@/components/modals/modalDefinitions";
import { useTokenStore } from "@/store/token";

definePage({
	name: "CompanyCandidatesPage",
});

const router = useRouter();
const store = useTokenStore();
const search = ref("");
const countries = ref<TCountryItem[] | []>([]);
const manageCallApi = ref<boolean>(true);
const load = ref(false);

// const statuses = ref(["request", "reject", "accept"]);
// const status = ref("");

const columns = reactive<TTableColumn[]>([
	{
		id: "usr_name",
		label: $t("app.candidates.name"),
	},
	{
		id: "usr_position",
		label: $t("app.candidates.position"),
	},
	{
		id: "usr_phone",
		label: $t("app.candidates.phone"),
	},
	{
		id: "usr_mngrequested",
		label: $t("app.candidates.requested"),
	},

	{
		id: "usr_mngapproved",
		label: $t("app.candidates.approved"),
	},
	{
		id: "request_for",
		label: $t("app.candidates.requestFor"), // represent // termination
	},
]);
if (store.can("mng-add") && store.can("mng-rmv")) {
	columns.push({
		id: "action",
		label: "",
	});
}

const rows = ref<TTableRow[]>([]);

const showOptionsData = ref<any>({});

// functionality
function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}

function getMoreInfo(e: any) {
	manageCallApi.value = false;
	showOptionsData.value = {};
	void router.push({
		name: "ManageUserProfile",
		params: { id: e.usr_id },
	});
}

async function getUsers(reset = false) {
	load.value = true;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	type TParam = {
		offset: number;
		limit: number;
		search?: string;
	};
	const params: TParam = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value) {
		params.search = search.value;
	}

	try {
		const res = await api.getCompanyCandidates(params);
		rows.value.push(...res.data.data.data);
		manageCallApi.value = res.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function openModalAccept(e: TTableRow) {
	openConfirmModal(
		{
			name: `Are you sure you want to accept ${e.usr_name} request?`,
			data: e,
		},
		confirmAccept,
		"Accept request ?",
	);
}

async function confirmAccept(e: any) {
	console.log(e);
	try {
		const res = await api.confirmUserRequest({
			cmp_id: store.userData?.cmp_id,
			usr_id: e.usr_id,
			reply: "accept",
		});
		console.log(res);
		await getUsers(true);
	} catch (e) {
		console.log(e);
	}
}

function openModalReject(e: TTableRow) {
	openConfirmModal(
		{
			name: `Are you sure you want to reject ${e.usr_name} request?`,
			data: e,
		},
		confirmReject,
		"Reject request ?",
	);
}

async function confirmReject(e: any) {
	console.log(e);
	try {
		const res = await api.confirmUserRequest({
			cmp_id: store.userData?.cmp_id,
			usr_id: e.usr_id,
			reply: "reject",
		});
		await getUsers(true);
		console.log(res);
	} catch (e) {
		console.log(e);
	}
}

function openModalCancel(e: any) {
	openConfirmModal(
		{
			name: `Are you sure you want to cancel cooperation with ${e.usr_name}?`,
			data: e,
		},
		confirmCancel,
		"Terminate cooperation ?",
	);
}

async function confirmCancel(e: any) {
	console.log(e);
	try {
		const res = await api.deleteUserManaged({
			cmp_id: store.userData?.cmp_id,
			usr_id: e.usr_id,
		});
		console.log(res);
		await getUsers(true);
	} catch (e: any) {
		console.log(e);
	}
}

watch([search], async () => {
	// filter users
	await getUsers(true);
});
onMounted(async () => {
	await getUsers();
	await getCountries();
});
</script>

<template lang="pug">
.page.candidates
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.candidates.companyCandidates") }}
			.wrapp-filters
				search-input(
					style="min-width: 300px; max-width: 300px",
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getUsers"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'usr_mngrequested'",
					@click="getMoreInfo(slotProps.row)"
				)
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
				td(
					v-else-if="slotProps.column.id == 'usr_mngapproved'",
					@click="getMoreInfo(slotProps.row)"
				)
					.dateTime(v-if="slotProps.row[slotProps.column.id]")
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
					.orange(v-else) {{ $t("app.candidates.waitingApproval") }}
				td(v-else-if="slotProps.column.id === 'request_for'")
					span.purple(
						v-if="slotProps.row.usr_mngapproved && slotProps.row.usr_mngrequested"
					) {{ $t("app.candidates.termination") }}
					span.purple(
						v-else-if="!slotProps.row.usr_mngapproved && slotProps.row.usr_mngrequested"
					) {{ $t("app.candidates.represent") }}

				td.action(v-else-if="slotProps.column.id == 'action'")
					.wrapp-buttons(v-if="slotProps.row.usr_mngapproved === null")
						button.alt.btn-s(@click="openModalAccept(slotProps.row)")
							span {{ $t("app.companies.accept") }}
						button.btn-s(@click="openModalReject(slotProps.row)")
							span {{ $t("app.companies.reject") }}
					.wrapp-buttons(v-else-if="slotProps.row.usr_mngapproved")
						button.btn-s(@click="openModalCancel(slotProps.row)")
							span {{ $t("app.candidates.terminate") }}
				td(
					v-else,
					:class="{ bold: slotProps.column.id === 'usr_name' || slotProps.column.id === 'usr_position' }",
					@click="getMoreInfo(slotProps.row)"
				) {{ slotProps.row[slotProps.column.id] }}
		.loader-wrap-intern(v-else-if="load")
			.loader
		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/noCandidatesFund.png")
</template>

<style lang="scss" scoped>
.page {
	// padding: 20px;
	// height: 100%;
	// width: 100%;
	font-family: "Open Sans", sans-serif;
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;
		position: relative;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			align-items: center;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			margin-bottom: 10px;
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			height: 40px !important;
			position: relative;
			// padding-right: 12px;
			width: 180px;

			.wrapp-buttons {
				margin-left: auto;
				width: 190px;
				display: flex;
				justify-content: space-between;
			}
		}
	}
	.purple {
		color: $text-color-purple;
		font-weight: 700;
	}
	.orange {
		color: $text-color-tirkiz;
		font-weight: 700;
	}
}
</style>
<style lang="scss">
.candidates {
	.main {
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			height: 29px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
}
</style>
