<script setup lang="ts">
import type { TCompanyPlan } from "@/assets/js/types";
import { useDefaultStore } from "@/store";

definePage({
	name: "RegisterPackage",
});

const emit = defineEmits(["back", "next"]);

const store = useDefaultStore();
const selectedPackage = computed(() => store.registerForm.companyData.cmp_plan);

function onClickBox(type: TCompanyPlan) {
	store.registerForm.companyData.cmp_plan = type;
}
async function onClickBack() {
	await store.changeWizardPage(-1);
	emit("back");
}
async function onClickNext() {
	await store.changeWizardPage(1);
	emit("next");
}
</script>

<template lang="pug">
.register-package
	modern-form
		template(#content)
			.form-title Registration
			.selection
				.box(
					:class="{ selected: selectedPackage === 'FREE' }",
					@click="onClickBox('FREE')"
				)
					.title Freemium
					.text
						li Lorem ipsum dolor, sit amet consectetur
						li Lorem ipsum dolor, sit amet consectetur
						li Lorem ipsum dolor, sit amet consectetur
					.price
						span Price: 0 USD
					.modern-rbox
						input(type="radio", :checked="selectedPackage === 'FREE'")
				.box(
					:class="{ selected: selectedPackage === 'P1' }",
					@click="onClickBox('P1')"
				)
					.title Premium
					.text
						li Lorem ipsum dolor, sit amet consectetur
						li Lorem ipsum dolor, sit amet consectetur
						li Lorem ipsum dolor, sit amet consectetur
					.price
						span Price: 10 USD
					.modern-rbox
						input(type="radio", :checked="selectedPackage === 'P1'")
			.action
				back-next-btns(@back="onClickBack", @next="onClickNext")
</template>

<style lang="scss" scoped>
.register-package {
	display: flex;
	width: auto;

	.modern-form {
		gap: 20px;
	}

	.form-title {
		// font-weight: bold;
		font-size: 1.3rem;
		margin: 0 20px;
	}

	.selection {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 20px;
		gap: 20px;

		.box {
			width: 320px;
			height: 300px;
			border: 1px solid $border-color1;
			border-radius: $border-radius;
			padding: 20px;
			transition: 0.3s border-color ease;
			cursor: pointer;

			.title {
				font-weight: bold;
				font-size: 1rem;
				transition: 0.3s color ease;
			}

			.text {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin: 30px 10px 0 5px;

				li {
					list-style-type: disc;
				}
			}

			.price {
				margin: 20px 0 0 auto;
				text-align: right;
				font-weight: bold;
				transition: 0.3s color ease;
			}

			.modern-rbox {
				position: absolute;
				right: 20px;
				top: 20px;
				pointer-events: none;
			}

			&.selected {
				border-color: $app-accent-color1;

				.title {
					color: $app-accent-color1;
				}

				.price {
					color: $app-accent-color1;
				}
			}
		}
	}

	.action {
		display: flex;
		justify-content: center;
		margin: 0 0 30px;
	}
}
</style>
