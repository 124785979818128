<script setup lang="ts">
import { useRoute } from "vue-router";
import { useTokenStore } from "@/store/token";
import { $t } from "@/i18n";

definePage({
	name: "ChatBotPage",
	path: "/manage/chat-bot-page/:id?",
});

const token = useTokenStore();
const route = useRoute();
const expectedIds = [1, 2, 3, 4];

const paramId = computed(() => {
	return Number(route.params.id) || null;
});

// const linkCode = computed(() => {
// 	// if (route.params.id === "1")
// 	// return "78ba391d600940aaa738adac64ee2f66"; // Customs and Traditions
// 	if (route.params.id === "2")
// 		return "1b4d52c50c784d6980cdfd8626b82b30"; // jobInterviewCoach
// 	else if (route.params.id === "3")
// 		return "08d4c16f03a34eb1ae746aa06ec6ac99"; // Job Description Generator
// 	else if (route.params.id === "4")
// 		return "fa2f177f4dc440d986f23635d6ba4bd5"; // Ethical Dilemma
// 	else return "78ba391d600940aaa738adac64ee2f66"; // Customs and Traditions
// });
const writeTitle = computed(() => {
	// if (paramId.value === 1) {
	// 	return $t("app.bot.CustomsTraditions");
	// } else
	switch (paramId.value) {
		case 2: {
			return $t("app.bot.jobInterviewCoach");
		}
		case 3: {
			return $t("app.bot.jobDescriptionsAssistant");
		}
		case 4: {
			return $t("app.bot.ethicalConsultant");
		}
		default: {
			return $t("app.bot.CustomsTraditions");
		}
	}
});
function backgroundColor() {
	if (token.isCandidate) {
		return "#F0D6F7";
	} else if (token.isUserCompany || token.isUserPresentCompany) {
		return "#D6F7F0";
	} else if (token.isUserB2B) {
		return "#D6EDF7";
	}
}

// chatbot settings
// const chatTypes = ref({
// 	1: "78ba391d600940aaa738adac64ee2f66", // Customs and Traditions
// 	2: "1b4d52c50c784d6980cdfd8626b82b30", // jobInterviewCoach
// 	3: "08d4c16f03a34eb1ae746aa06ec6ac99", // Job Description Generator
// 	4: "fa2f177f4dc440d986f23635d6ba4bd5", // Ethical
// });

watch(
	() => route.params.id,
	(val) => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	},
);
onMounted(() => {
	window.scrollTo({ top: 0, behavior: "smooth" });
});
</script>
<template lang="pug">
.chat-bot-page
	.wrapp-chat-bot
		//- Job Interview Coach
		iframe(
			v-if="paramId === 2",
			src="https://app.manoloremiddi.com/widget/1b4d52c50c784d6980cdfd8626b82b30",
			width="100%",
			height="810px",
			frameborder="0",
			allow="clipboard-read; clipboard-write"
		)
		//- Job Description Generator
		iframe(
			v-else-if="paramId === 3",
			src="https://app.manoloremiddi.com/widget/08d4c16f03a34eb1ae746aa06ec6ac99",
			width="100%",
			height="810px",
			frameborder="0",
			allow="clipboard-read; clipboard-write"
		)
		//- Ethical Dilemma
		iframe(
			v-else-if="paramId === 4",
			src="https://app.manoloremiddi.com/widget/fa2f177f4dc440d986f23635d6ba4bd5",
			width="100%",
			height="810px",
			frameborder="0",
			allow="clipboard-read; clipboard-write"
		)
		//- Customs and Traditions
		iframe(
			v-else,
			src="https://app.manoloremiddi.com/widget/78ba391d600940aaa738adac64ee2f66",
			width="100%",
			height="810px",
			frameborder="0",
			allow="clipboard-read; clipboard-write"
		)

	.wrapp-info
		.info(:style="{ backgroundColor: backgroundColor() }")
			.title {{ writeTitle }}
			.txt(v-if="paramId") {{ expectedIds.includes(Number(route.params.id)) ? $t("app.bot.txt" + route.params.id) : $t("app.bot.txt" + 1) }}
</template>
<style lang="scss">
.chat-bot-page {
	max-width: 1224px;

	// border: $border;

	// padding: 0 30px 30px;
	margin-bottom: 14px;
	// min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: transparent !important;

	.wrapp-chat-bot {
		background: $background-color;
		padding: 0;
		border-radius: 10px;
		width: 70%;
	}
	.wrapp-info {
		padding: 0 0 30px 10px;
		width: 30%;
		.info {
			border-radius: 10px;
			padding: 30px;
			.title {
				font-size: 16px;
				font-weight: 600;
				line-height: 19.36px;
				text-align: left;
				margin-bottom: 12px;
			}
			.txt {
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				text-align: left;
			}
		}
	}
}
</style>
