<script setup lang="ts">
import api from "@/api";
import { useToastStore } from "@/store/toast";
// import { useTokenStore } from "@/store/token";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import { DateInstance } from "@/assets/js/dateHelper";
import { useRouter } from "vue-router";
import { downloadFeedbackFile } from "@/assets/js/helpers";
import { openConfirmModal } from "@/components/modals/modalDefinitions";

definePage({
	name: "AdminFeedbackPage",
});

const toastStore = useToastStore();
// const tokenStore = useTokenStore();
const router = useRouter();
const showOptionsData = ref<TTableRow>({});
const scroll = ref(true);
const search = ref("");
const date = ref(null);

const columns = reactive<TTableColumn[]>([
	{
		id: "usr_name",
		label: "Name",
	},
	{
		id: "usr_email",
		label: "Email",
	},
	{
		id: "fdb_date",
		label: "Date",
	},
	{
		id: "file",
		label: "File",
	},
	{
		id: "action",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([]);
const load = ref(false);

async function getData(reset = false) {
	load.value = true;
	type TParams = {
		limit: number;
		offset: number;
		search?: string;
		created?: string;
	};
	scroll.value = false;

	if (reset) {
		rows.value = [];
		scroll.value = true;
	}

	const params: TParams = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value.length > 0) {
		params.search = search.value;
	}
	if (date.value) {
		params.created = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	try {
		const res = await api.getFeedback(params);

		rows.value.push(...res.data.data);
		scroll.value = res.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

function formatDate(date: string) {
	if (date) return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}
function formatTime(date: string) {
	return DateInstance.getFormatDTLocale(date, "HH:mm");
}
async function getFile(e: TTableRow) {
	if (!e) {
		return;
	}
	// https://204b123.mars-t.mars-hosting.com/api_v1/users/images-get/

	try {
		const res = await api.getFeedbackFile({
			fdb_id: e.fdb_id,
		});

		// downloadFile(res.data, e?.file_title ?? "file");
		downloadFeedbackFile(res.data, "feedback_file");
	} catch (e) {
		console.log(e.response.data);
		// download(e.response.data, file.file_title);
	}
}
function goToProfile(e: TTableRow) {
	void router.push({
		name: "PublicProfile",
		params: { id: e.usr_id },
	});
}
async function deleteRow(e: TTableRow) {
	openConfirmModal(
		{ name: e.usr_email ?? e.fdb_email + ": " + e.fdb_message, data: e },
		confirmDelete,
		"Remove feedback?",
	);
}

async function confirmDelete(e: TTableRow) {
	try {
		await api.removeFeedback({ fdb_id: e.fdb_id });

		await getData(true);
		toastStore.openToastSuccess("You have successfully deleted feedback");
	} catch (e) {
		console.log(e);
		toastStore.openToastError("Error");
	}
}

watch([search, date], async () => {
	await getData(true);
});

onMounted(async () => {
	await getData();
});
</script>

<template lang="pug">
.feedback-page-admin
	.up
		.wrapp-title
			.line
			.title {{ $t("app.feedback.feedbacks") }}
		.wrapp-filters
			search-input(
				placeholder="Type something...",
				:search-value="search",
				@update:search-value="search = $event"
			)
			//- input(v-model="date", type="date", placeholder="Date created")
			.date
				modern-date-picker(
					v-model="date",
					placeholder="Created date",
					:enable-time-picker="false",
					style="width: 150px"
				)
	.main
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="true",
			:isInfiniteScroll="scroll",
			style="max-height: 700px",
			@scroll-more="getData"
		)
			template(#[`table-body-cell`]="slotProps")
				td(v-if="slotProps.column.id == 'fdb_date'")
					.date {{ formatDate(slotProps.row[slotProps.column.id]) }}
					.time {{ formatTime(slotProps.row[slotProps.column.id]) }}
				td(v-else-if="slotProps.column.id === 'file'")
					img(
						v-if="slotProps.row.has_file",
						src="@/assets/image/zip.svg",
						@click="getFile(slotProps.row)"
					)
				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span(@click="showOptionsData = slotProps.row")
						img(src="@/assets/image/actionIcons/more.svg")
					.show-reports-data(v-if="showOptionsData.fdb_id === slotProps.row.fdb_id")
						.close-btn(data-name="close-action", @click="showOptionsData = {}")
							svg.close-icon(
								width="16",
								height="16",
								viewBox="0 0 16 16",
								fill="none",
								xmlns="http://www.w3.org/2000/svg"
							)
								path(
									fill-rule="evenodd",
									clip-rule="evenodd",
									d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
									fill="#575758"
								)
						.wrapp-info(
							v-if="slotProps.row.usr_id",
							@click="goToProfile(slotProps.row)"
						)
							span {{ $t("app.feedback.goToProfile") }}
						.wrapp-info(@click="deleteRow(slotProps.row)")
							span {{ $t("app.feedback.delete") }}
				td(v-else-if="slotProps.column.id === 'usr_name'") {{ slotProps.row.usr_name ?? slotProps.row.fdb_name }}
				td(v-else-if="slotProps.column.id === 'usr_email'") {{ slotProps.row.usr_email ?? slotProps.row.fdb_email }}
			template(#table-body-extra-row-content="slotProps")
				transition(name="show-row")
					td.info-row(v-show="true", :colspan="columns.length") 
						.info {{ slotProps.row.fdb_message }}
		.loader-wrap-intern(v-else-if="load")
			.loader
</template>

<style lang="scss" scoped>
.feedback-page-admin {
	max-width: 1224px;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	// flex-wrap: wrap;
	// justify-content: space-between;
	border-radius: 10px;
	// border: $border;
	background-color: $bg-color1;

	.up {
		align-self: flex-start;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 30px;

		.wrapp-title {
			align-self: flex-start;
			width: fit-content;

			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: $margin-title-up;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				display: inline;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-filters {
			display: flex;
			div > :last-child {
				margin-left: 10px;
			}
		}
	}

	td {
		// font-family: Archivo, sans-serif;
		font-size: 13px;
		font-weight: 700;
		line-height: 15px;
		letter-spacing: 0;
		text-align: left;
		cursor: pointer;
		.date {
			// font-family: Archivo, sans-serif;
			font-size: 13px;
			font-weight: 600;
			line-height: 15px;
			text-align: left;
		}
		.time {
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
			text-align: left;
		}
	}
	td:first-of-type {
		padding-left: 5px;
	}
	.info-row {
		box-sizing: border-box;
		max-width: 100%;
		padding: 10px 20px 20px !important;
		// font-family: "Open Sans", sans-serif;
		font-size: 13px;
		font-weight: 400;
		line-height: 15px;
		text-align: left;
		.info {
			// border-top: $border;
		}
	}

	.action {
		max-width: 40px;
		margin-left: auto;
		display: flex;
		align-items: center;
		height: 40px !important;
		position: relative;
		padding-right: 12px;

		span {
			cursor: pointer;
			width: 100%;
		}

		img {
			cursor: pointer;
			margin: auto;
		}

		.show-reports-data {
			width: 149px;
			min-height: 04px;
			box-sizing: border-box;
			padding: 12px 15px 0;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 1px;
			border-radius: 20px;
			background: $background-color;
			border: 1px solid #dfdfe8;
			// background: #e44e75;

			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0.03em;
			text-align: left;

			& > .close-btn {
				margin: 0 0 auto auto;
				display: flex;
				align-self: center;
				padding: 3px;
				justify-content: flex-end;
				position: absolute;
				top: 5px;
				right: 5px;

				.close-icon {
					$size: 13px;

					height: $size;
					width: $size;
					cursor: pointer;

					path {
						fill: #aeadad;
					}

					&:hover {
						path {
							fill: #ffb4c3;
							transition: all 0.3s ease;
						}
					}
				}
			}

			img {
				cursor: pointer;
				margin-left: 18px;
				position: absolute;
			}

			.wrapp-info {
				margin-bottom: 15px;
			}
			.wrapp-info:hover {
				color: $app-accent-color1;
				cursor: pointer;
			}
		}
	}
}

@media only screen and (max-width: 1250px) {
	.feedback-page {
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}
}
</style>
