<script setup lang="ts">
import { $t } from "@/i18n";
import {
	openModalReport,
	openMyApplicationModal,
} from "@/components/modals/modalDefinitions";
import type { TListing } from "@/assets/js/types";
import { useTokenStore } from "@/store/token";
import { useDefaultStore } from "@/store";
import { useMessageStore } from "@/store/message";

import { useRoute, useRouter } from "vue-router";
import api from "@/api";

const route = useRoute();
const router = useRouter();

definePage({
	name: "JobPreview",
	path: "/manage/job-preview/:id",
});

const tokenStore = useTokenStore();
const message = useMessageStore();

const data = ref<TListing | any>(null);
// ({
// job_title: "Test Job", // s 255 R
// cnt_code: "sr", // s

// job_shortinfo: "", // s
// job_expires: "2024-11-11", // d R
// job_active: "2024-1-11", // d R
// job_externallink: "httpsmmmm", // s
// job_reqportfolio: false,
// job_reqvideo: false, //
// job_reqrecommendations: false, //
// job_description: "Test description ", // s 6000
// job_reqadditional: "Testttt  additional", // s
// usr_id_responsible: 49, // i

// cmp_id: 4, // i
// cli_id: 2, // i R

// job_status: 'Draft' , // "Draft","Published","Paused","Unlisted","Finished","Cancelled"
// });

function reportListing() {
	openModalReport({ data: data.value });
}

function sendMessage(listing: TListing) {
	// console.log(
	// 	" kada se vidi sta ce da se radi ... treba dorada za listing .. bila rec treba da se vidi sta i kako ",
	// );

	message.setSendMsgTo({
		usr_id: listing.usr_id_responsible,
		usr_name: listing.job_title,
		job_id: listing.job_id,
	});
	void router.push({
		name: "ChatMain",
		params: { id: listing.usr_id_responsible },
	});
}

async function apply() {
	if (!tokenStore.userData) {
		await useDefaultStore().onClickLoginPage();

		return;
	}
	if (
		// tokenStore.userData.rol_name !== "candidate" &&
		// tokenStore.userData.rol_name !== "managed-user"
		tokenStore.userData?.rol_id !== 1 &&
		tokenStore.userData?.rol_id !== 8
	) {
		return;
	}
	openMyApplicationModal({ data: data.value }, onConfirm);
}
function onConfirm(e: any) {
	data.value.applied = 1;
}
async function getSingleJob() {
	const params = {
		view: "public",
		job_id: route.params.id,
	};
	try {
		const res = await api.getSingleJob(params);
		data.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function goBack() {
	// emit('returnOnList', false)
	router.back();
}

onMounted(async () => {
	await getSingleJob();
});
</script>
<template lang="pug">
.job-preview
	.main
		.up
			.wrapp-title
				.line
				.w-t(@click="goBack")
					.arrow-left
					.title(v-if="data") {{ data?.job_title }}
						//- {{ $t("app.joblisting.jobPreview") }}
			.wrapp-action
				.btn(v-if="tokenStore.isLoggedIn", @click="sendMessage(data)")
					span {{ $t("app.joblisting.sendMessage") }}
				button.btn(
					v-if="tokenStore.isCandidate",
					:disabled="data?.applied",
					@click="apply"
				)
					span {{ data?.applied ? $t("app.joblisting.applied") : $t("app.joblisting.apply") }}
		listing-data(
			v-if="data",
			:data="data",
			:show-report="true",
			:setWide="true",
			@report="reportListing"
		)
</template>
<style lang="scss" scoped>
.job-preview {
	max-width: 1224px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-radius: 11px;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-action {
			display: flex;
			align-items: center;
			padding-bottom: 10px;
			.btn {
				// margin-top: 7px;
				margin-left: 20px;
			}
		}
	}
}
</style>
<style lang="scss">
.job-preview {
	.content {
		.input-group {
			.group {
				.txt {
					ol,
					ul,
					menu {
						list-style: initial;
						margin: initial;
						padding-left: 20px;
					}
					ol {
						list-style: decimal;
					}
					// li{
					// 	margin-left: 20px;
					// }
				}
			}
		}
	}
}
</style>
