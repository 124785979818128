<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import { openConfirmModal } from "../../components/modals/modalDefinitions";
import { DateInstance } from "@/assets/js/dateHelper";
import { useToastStore } from "@/store/toast";
import api from "@/api";

definePage({
	name: "AdminAds",
});

type TStatus = {
	id: string;
	name: string;
};
const toastStore = useToastStore();
const search = ref<string>("");
// const date = ref<string>("");
const status = ref<TStatus>({ id: "all", name: "All" });
const edit = ref<any>(null);
const scroll = ref(true);

const statuses = ref([
	{ id: "all", name: "All" },
	{ id: "unpublished", name: "Draft/unpublished" },
	{ id: "published", name: "Published" },
	{ id: "deleted", name: "Deleted" },
]);

const columns = reactive<TTableColumn[]>([
	{
		id: "ads_name",
		label: $t("app.ads.name"),
	},
	{
		id: "ads_from",
		label: $t("app.ads.activeFrom"),
	},
	{
		id: "ads_to",
		label: $t("app.ads.activeTo"),
	},
	{
		id: "ads_published",
		label: $t("app.ads.published"),
	},
	{
		id: "status",
		label: $t("app.ads.status"),
	},
	{
		id: "action",
		label: "",
	},
]);
const load = ref(false);
const rows = ref<TTableRow[]>([
	// {
	// 	name: "asd",
	// 	creDate: "2023-08-14 09:28:04",
	// 	expDate: "2024-08-14 09:28:04",
	// },
	// {
	// 	name: "bbb",
	// 	creDate: "2023-08-14 09:28:04",
	// 	expDate: "2024-08-14 09:28:04",
	// },
	// {
	// 	name: "aaa value3",
	// 	creDate: "2023-08-14 09:28:04",
	// 	expDate: "2024-08-14 09:28:04",
	// },
	// {
	// 	name: "aaa value4",
	// 	creDate: "2023-08-14 09:28:04",
	// 	expDate: "2024-08-14 09:28:04",
	// },
	// {
	// 	name: "aaa value5",
	// 	creDate: "2023-08-14 09:28:04",
	// 	expDate: "2024-08-14 09:28:04",
	// },
]);

function formatDate(date: string) {
	// return dayjs.utc(date).local().format("DD.MM.YYYY.");
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}

function formatTime(date: string) {
	// return dayjs.utc(date).local().format("HH:mm");
	if (date) {
		return DateInstance.getFormatDTLocale(date, "HH:mm");
	}
}

function getData() {
	console.log("set data here");
}

function setPause(e: any) {
	openConfirmModal(
		{ name: e.ads_name, data: e },
		confirmSetPause,
		e.ads_published === null
			? "Do you want to publish this ad?"
			: "Do you want to stop this ad?",
	);
}

async function confirmSetPause(e: any) {
	const params = {
		ads_id: e.ads_id,
		ads_published: e.ads_published === null,
	};
	await editAdminAds(params);
}

async function editAdminAds(params: any) {
	try {
		await api.editAdminAds(params);

		toastStore.openToastSuccess(
			params.ads_published === null
				? "Successfully published."
				: "Successfully stop",
		);
		await refreshList();
	} catch {
		toastStore.openToastError("Error.");
	}
}

function setEdit(e: any) {
	edit.value = Object.assign({}, e);

	// prop === "ads_from"  prop === "ads_to"
	edit.value.ads_from = DateInstance.getFormatDTLocale(
		edit.value.ads_from,
		"YYYY-MM-DD HH:mm",
	);
	edit.value.ads_to = DateInstance.getFormatDTLocale(
		edit.value.ads_to,
		"YYYY-MM-DD HH:mm",
	);
}

function setDelete(e: TTableRow) {
	// removeAdminAds;
	// openAdminDeleteAdsModal
	openConfirmModal(
		{ name: e.ads_name, data: e },
		confirmRemove,
		$t("app.ads.deleteAd") + "?",
	);
}

async function confirmRemove(e: TTableRow) {
	try {
		await api.removeAdminAds({ ads_id: e.ads_id });

		await refreshList();
	} catch (e) {
		console.log(e);
	}
}

function setRestore(e: TTableRow) {
	openConfirmModal(
		{ name: e.ads_name, data: e },
		confirmRestore,
		$t("app.ads.restoreAd") + "?",
	);
}

async function confirmRestore(e: TTableRow) {
	const params = {
		ads_id: e.ads_id,
		ads_deleted: false,
	};
	await editAdminAds(params);
}

async function getAdminAds(reset = false) {
	load.value = true;
	scroll.value = false;
	type TParams = {
		limit: number;
		offset: number;
		search?: string;
		filter?: string;
	};
	if (reset) {
		scroll.value = true;
		rows.value = [];
	}
	const params: TParams = {
		limit: 20,
		offset: rows.value.length,
	};
	if (search.value) {
		params.search = search.value;
	}
	if (status.value) {
		params.filter = status.value.id;
	}

	try {
		const res = await api.getAdminAds(params);

		rows.value.push(...res.data.data.data);
		scroll.value = res.data.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}
async function refreshList() {
	await getAdminAds(true);
}

watch([search, status], async () => {
	await getAdminAds(true);
});

onMounted(async () => {
	await getAdminAds();
});
</script>

<template lang="pug">
.ads-page
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.ads.title") }}
			.wrapp-filters
				search-input(
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				//- .date
					modern-date-picker(
						v-model="date",
						:enable-time-picker="false",
						style="width: 150px"
					)
				.dropdown
					multiselect-form(
						v-model="status",
						:close-on-select="true",
						:append-to-body="true",
						label="name",
						value-prop="id",
						:options="statuses"
					)
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="scroll",
			style="max-height: 700px",
			@scroll-more="getData"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'ads_from' || slotProps.column.id == 'ads_to' || slotProps.column.id == 'ads_published'"
				)
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
						//- br
						span.time {{ formatTime(slotProps.row[slotProps.column.id]) }}
				td(v-if="slotProps.column.id == 'status'")
					span {{ slotProps.row.ads_deleted ? "deleted" : slotProps.row.ads_published ? "published" : "draft" }}
				td.action(
					v-if="slotProps.column.id == 'action' && slotProps.row.ads_deleted === null",
					style="width: 120px"
				)
					span(@click="setPause(slotProps.row)")
						img(
							v-if="slotProps.row.ads_published",
							src="@/assets/image/actionIcons/pause.svg",
							title="stop"
						)
						.arrow-right(v-else, title="publish")
					span(@click="setEdit(slotProps.row)")
						img(src="@/assets/image/actionIcons/edit.svg", title="edit")
					span(@click="setDelete(slotProps.row)")
						img(src="@/assets/image/actionIcons/delete.svg", title="delete")
					//- span(@click="setPause(slotProps.row)")
						img(src="@/assets/image/actionIcons/more.svg")
				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span(@click="setRestore(slotProps.row)")
		.loader-wrap-intern(v-else-if="load")
			.loader

	.wrappSide
		.wrapp-title
			.line
			.title(v-if="edit") {{ $t("app.ads.titleEdit") }}
				.close-btn(data-name="close-action", @click="edit = null")
					svg.close-icon(
						width="16",
						height="16",
						viewBox="0 0 16 16",
						fill="none",
						xmlns="http://www.w3.org/2000/svg"
					)
						path(
							fill-rule="evenodd",
							clip-rule="evenodd",
							d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
							fill="#575758"
						)
			.title(v-else) {{ $t("app.ads.titleAdd") }}
		ads-manage(:edit="edit", @cancel="edit = null", @refresh="refreshList")

	//- .wrappSide
		span.lorem +++++ Lorem Ipsum
</template>

<style lang="scss" scoped>
.ads-page {
	// all with side menu 1489
	max-width: 1224px;
	width: 100%;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		max-width: 784px;
		// margin-top: 30px;

		.up {
			display: flex;
		}

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			.dropdown {
				padding: 1px 11px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				background-color: $background-color;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 175px;
				max-width: 320px;
				align-items: center;
				// text-overflow: ellipsis;
				// margin-top: 10px;
			}
			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}
	}

	.wrappSide {
		width: 100%;
		max-width: 319px;
		// background-color: #f6a712;

		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-top: 18px;
			.line {
				display: none;
			}

			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				width: 255px;
				display: flex;
				justify-content: space-between;

				& > .close-btn {
					margin: auto 12px auto auto;
					display: flex;
					align-self: center;
					padding: 3px;

					.close-icon {
						$size: 13px;

						height: $size;
						width: $size;
						cursor: pointer;

						path {
							fill: #aeadad;
						}

						&:hover {
							path {
								fill: #ffb4c3;
								transition: all 0.3s ease;
							}
						}
					}
				}
			}
		}
	}

	.action {
		margin-left: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 40px !important;

		span {
			cursor: pointer;
		}

		img {
			cursor: pointer;
		}
	}
	.dateTime {
		display: flex;
		height: 40px;
		flex-direction: column;
		justify-content: center;

		span {
			font-size: 13px;
			font-weight: 600;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
		}

		.time {
			font-size: 11px;
			font-weight: 400;
			line-height: 13px;
			letter-spacing: 0;
			text-align: left;
		}
	}
}
.arrow-right {
	width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-left: 12px solid $app-accent-color1;
	display: inline-block;
	cursor: pointer;
}

@media only screen and (max-width: 1459px) {
	.ads-page {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		.wrappSide {
			width: 100%;
			max-width: 817px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.wrapp-title {
				margin-top: 0;
				width: fit-content !important;

				.line {
					display: block;
					// background: $gradient-line3;
					// height: 5px;
					height: 0;
					width: 100%;
					// margin-bottom: 14px;
					box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
					border-radius: 0 0 10px 10px;
				}
				.title {
					width: fit-content;
					margin-top: $margin-title-up;
				}
			}
		}
		// .wrappSide>.wrapp-side-community{
		// 	margin-right: 29px;
		// }
	}
}
</style>

<style lang="scss" scoped>
.ads-page {
	td {
		// font-family: Archivo, sans-serif;
		font-size: 13px;
		font-weight: 700;
		line-height: 15px;
		letter-spacing: 0;
		text-align: left;
		padding-left: 5px;
		padding-right: 5px;
	}
}
</style>
