<script setup lang="ts">
import { $t } from "@/i18n";
import api from "@/api";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import { DateInstance } from "@/assets/js/dateHelper";
import { useTokenStore } from "@/store/token";

definePage({
	name: "CompanyHome",
});

// interface IListType {
// 	id: number;
// 	label: string;
// }

const tokenStore = useTokenStore();
// list
const showOptionsData = ref<TTableRow | any>({});
const date = ref<string | null>(null);
const search = ref<string>("");
const filterStatus = ref<any>(null);
const allFilters = reactive([
	{ label: "Cancelled" },
	{ label: "Finished" },
	{ label: "Unlisted" },
	{ label: "Paused" },
	{ label: "Published" },
	{ label: "Draft" },
]);

// table

const columns = reactive<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.companies.listingName"),
	},
	{
		id: "job_expires",
		label: $t("app.companies.expirationDate"),
	},
	{
		id: "job_status",
		label: $t("app.companies.status"),
	},
	// {
	// 	id: "action",
	// 	label: "",
	// },
]);

const rows = ref<TTableRow[]>([
	{
		job_id: 2,
		job_title: "List",
		job_status: "cancelled",
		job_expires: "2023-08-14 09:28:04",
		// country: "Serbia",
		// email: "aaaa@aaa.com",
		// type: "Agency",
		// membership: "Freemium",
		// phone: "011/0000-000",
		// address: "Bulevar Mihajla Pupina 13",
	},
]);

async function getJobs() {
	interface IParams {
		view: "company" | "public";
		cmp_id?: number; // optional ako je view company uzece se iz jwt-a
		// filter - (po statusu),
		// ako je view comapny onda moze biti cancelled, finished, unlisted, paused, published, draft,
		// ako je view public onda moze da bude published i paused
		filter?:
			| "Cancelled"
			| "Finished"
			| "Unlisted"
			| "Paused"
			| "Published"
			| "Draft";
		limit?: number;
		offset?: number;
		search?: string;
		date?: string;
	}

	const params: IParams = {
		view: "company",
	};

	if (search.value !== "") {
		params.search = search.value;
	}
	if (filterStatus.value) {
		params.filter = filterStatus.value.label.toLowerCase();
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}

	try {
		const res = await api.getJobs(params);
		// console.log(res.data);
		if (res) {
			rows.value = res.data.data.data;
		}
	} catch (e) {
		console.log(e);
	}
}

function formatDate(date: string) {
	return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}

function showOneListing(e: any) {
	console.log(e);
	console.log("showOneListing");
	showOptionsData.value = e;
}

// zvati apije kada ih dobijem
function pauseListing(e: any) {
	console.log(e);
	showOptionsData.value = {};
}
function finishListing(e: any) {
	console.log(e);
	// DORADI
	// openChangePlan(e);
	showOptionsData.value = {};
}

watch([filterStatus, search, date], async () => {
	await getJobs();
});

// end list

function deselectStatus() {
	filterStatus.value = null;
}

onMounted(async () => {
	if (tokenStore.can("job-get")) await getJobs();
});
</script>

<template lang="pug">
.company-listing
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.companies.homePage") }}
			.wrapp-action
				//- .btn(@click="openModal") 
				//- 	span Add new listing
		div
			CalendarComponent
		div(v-if="tokenStore.can('job-get') && false")
			.up-bottom-section
				.wrapp-filters
					search-input(
						placeholder="Type something...",
						:search-value="search",
						@update:search-value="search = $event"
					)
					//- input(v-model="date", type="date", placeholder="Date created")
					.date
						modern-date-picker(
							v-model="date",
							:enable-time-picker="false",
							style="width: 150px"
						)
					.dropdown
						multiselect-form(
							v-model="filterStatus",
							:options="allFilters",
							label="label",
							value-prop="label",
							placeholder="Filter",
							:can-deselect="true",
							@deselect="deselectStatus"
						)
			modern-table(
				:rows="rows",
				:columns="columns",
				:has-border="false",
				:display-additional-row="true",
				:isInfiniteScroll="false",
				@scroll-more="getJobs"
			)
				template(#[`table-body-cell`]="slotProps")
					td(v-if="slotProps.column.id == 'job_expires'")
						.dateTime
							span {{ formatDate(slotProps.row[slotProps.column.id]) }}

					td.status(v-else-if="slotProps.column.id == 'job_status'")
						//- cancelled, finished, unlisted, paused, published, draft,
						.table-status(
							:class="{ approved: slotProps.row.job_status === 'Published', pending: slotProps.row.job_status === 'Paused', finished: slotProps.row.job_status === 'Finished', cancelled: slotProps.row.job_status === 'Cancelled', unlisted: slotProps.row.job_status === 'Unlisted', draft: slotProps.row.job_status === 'Draft' }",
							style="min-width: 140px; width: 140px; min-height: 29px"
						) {{ slotProps.row.job_status }}
					td.action(
						v-else-if="slotProps.column.id == 'action'",
						style="width: 120px"
					)
						span(@click="showOptionsData = slotProps.row")
							img(src="@/assets/image/actionIcons/more.svg")
						.show-reports-data(
							v-if="false && showOptionsData.job_id === slotProps.row.job_id"
						)
							.close-btn(data-name="close-action", @click="showOptionsData = {}")
								svg.close-icon(
									width="16",
									height="16",
									viewBox="0 0 16 16",
									fill="none",
									xmlns="http://www.w3.org/2000/svg"
								)
									path(
										fill-rule="evenodd",
										clip-rule="evenodd",
										d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
										fill="#575758"
									)
							.wrapp-info(@click="pauseListing(slotProps.row)")
								span {{ $t("app.companies.pauseListing") }}
							.wrapp-info(@click="finishListing(slotProps.row)")
								span {{ $t("app.companies.finishListing") }}
					td(
						v-else,
						:class="{ bold: slotProps.column.id === 'job_title' }",
						@click="showOneListing(slotProps.row)"
					) {{ slotProps.row[slotProps.column.id] }}
				template(#table-body-extra-row-content="slotProps")
					transition(name="show-row")
						td.info-row(
							v-show="false && showOptionsData.job_id === slotProps.row.job_id",
							:colspan="columns.length"
						)
</template>

<style lang="scss" scoped>
.company-listing {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.wrapp-filters {
			width: calc(100% - 350px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;

			.dropdown {
				padding: 1px 11px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}

			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}
		.wrapp-action {
			display: flex;
			align-items: center;
			.btn {
				margin-top: 7px;
			}
		}

		// table
		.up-bottom-section {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 20px;
			.wrapp-filters {
				width: auto;
			}
		}
	}
}

.action {
	max-width: 40px;
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 40px !important;
	position: relative;
	padding-right: 22px;

	span {
		cursor: pointer;
	}

	img {
		cursor: pointer;
		margin-left: 18px;
	}

	.show-reports-data {
		width: 149px;
		min-height: 04px;
		box-sizing: border-box;
		padding: 12px 15px 0;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 1px;
		border-radius: 20px;
		background: $background-color;
		border: 1px solid #dfdfe8;
		// background: #e44e75;

		font-size: 15px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.03em;
		text-align: left;

		& > .close-btn {
			margin: 0 0 auto auto;
			display: flex;
			align-self: center;
			padding: 3px;
			justify-content: flex-end;
			position: absolute;
			top: 5px;
			right: 5px;

			.close-icon {
				$size: 13px;

				height: $size;
				width: $size;
				cursor: pointer;

				path {
					fill: #aeadad;
				}

				&:hover {
					path {
						fill: #ffb4c3;
						transition: all 0.3s ease;
					}
				}
			}
		}

		img {
			cursor: pointer;
			margin-left: 18px;
			position: absolute;
		}

		.wrapp-info {
			margin-bottom: 15px;
		}
		.wrapp-info:hover {
			color: $app-accent-color1;
			cursor: pointer;
		}
	}
}
</style>
<style lang="scss">
.company-listing {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
	.search-wrapper {
		// margin-right: 20px;
		margin-top: 10px;
		width: 20%;
		min-width: 150px !important;
		input {
			min-width: 150px !important;
			max-width: 100% !important;
			width: 100% !important;
		}
	}
	input[type="date"],
	.dropdown {
		margin-top: 10px;
	}
}
</style>
