<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../../components/shared/table/ModernTable.vue";
import api from "@/api";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";
import { openConfirmModal } from "../../../components/modals/modalDefinitions";

definePage({
	name: "InvitationsList",
});

const router = useRouter();
const columns = reactive<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.companies.listingName"),
	},
	{
		id: "cmp_name",
		label: $t("app.joblisting.tableHeaders.company"),
	},
	{
		id: "ain_accepted",
		label: $t("app.companies.accepted"),
	},
	{
		id: "ain_removed",
		label: $t("app.companies.rejected"),
	},
	{
		id: "app_id",
		label: "No.",
	},
	{
		id: "action",
		label: "",
	},
]);
const rows = ref<TTableRow[]>([]);
const manageCallApi = ref<boolean>(true);

// FILTERS
interface IListType {
	id: number;
	label: string;
}
const date = ref<string>("");
const search = ref<string>("");

const selectedType = ref<IListType | null>(null);
// const typeList = reactive([
// 	{ id: 2, label: "AGENCY" },
// 	{ id: 3, label: "B2B" },
// ]);

const selectedMembership = ref<IListType | null>(null);
// const membershipList = reactive([
// 	{ id: 2, label: "FREE" },
// 	{ id: 3, label: "P1" },
// 	{ id: 3, label: "P2" },
// 	{ id: 3, label: "P3" },
// ]);

// deselect

onMounted(async () => {
	await getData();
});
// functionality
function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}
watch([selectedType, selectedMembership, date, search], async () => {
	await getData(true);
});

async function getData(reset = false) {
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	interface IParams {
		limit: number;
		offset: number;
		search?: string;
	}
	const params: IParams = {
		limit: 20,
		offset: rows.value.length,
	};
	if (search.value && search.value.trim().length > 0) {
		params.search = search.value;
	}

	try {
		const res = await api.getAppInvites(params);
		console.log(res.data.data);
		manageCallApi.value = res.data.data.length <= 0 ? false : true;
		// rows.value = res.data.data;
		rows.value.push(...res.data.data.data);
	} catch (err: any) {
		console.warn(err.message);
	}
}

function showOneListing(e: any) {
	// console.log(e);
	// showCompanyData.value = e;
	void router.push({
		name: "ListingPreviewBtB",
		params: { id: e.job_id },
	});
}

function openModalAccept(e: TTableRow) {
	openConfirmModal(
		{
			name: `Accept the request for participation in the application, listing ${e.job_title} ?`,
			data: e,
		},
		confirmAccept,
		"Accept request ?",
	);
}

async function confirmAccept(e: any) {
	console.log(e);
	try {
		const res = await api.invitesAccept({
			app_id: e.app_id,
		});
		console.log(res);
		await getData(true);
	} catch (e) {
		console.log(e);
	}
}

function openModalReject(e: TTableRow) {
	openConfirmModal(
		{
			name: `Reject the request for participation in the application, listing ${e.job_title}?`,
			data: e,
		},
		confirmReject,
		"Reject request ?",
	);
}

async function confirmReject(e: any) {
	console.log(e);
	try {
		const res = await api.invitesReject({
			// usr_id
			app_id: e.app_id,
		});
		await getData(true);
		console.log(res);
	} catch (e) {
		console.log(e);
	}
}
</script>

<template lang="pug">
.page
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.companies.invitationsList") }}
			.wrapp-filters
				search-input(
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				//- .date
					modern-date-picker(
					    v-model="date",
					    :enable-time-picker="false",
					    style="width: 150px"
					)
				//- .dropdown
					multiselect-form(
					    v-model="selectedType",
					    :options="typeList",
					    label="label",
					    value-prop="label",
					    placeholder="All type"
					)
				//- .dropdown
					multiselect-form(
					    v-model="selectedMembership",
					    :options="membershipList",
					    label="label",
					    value-prop="label",
					    placeholder="All members"
					)
		modern-table(
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getData"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id === 'ain_accepted' || slotProps.column.id === 'ain_removed'"
				)
					.dateTime
					span {{ formatDate(slotProps.row[slotProps.column.id]) }}
				td.action(v-else-if="slotProps.column.id === 'action'")
					.wrapp-buttons(
						v-if="slotProps.row.ain_accepted === null && slotProps.row.ain_removed === null"
					)
						button.alt.btn-s(@click="openModalAccept(slotProps.row)")
							span {{ $t("app.companies.accept") }}
						button.btn-s(@click="openModalReject(slotProps.row)")
							span {{ $t("app.companies.reject") }}
					.wrapp-buttons(
						v-else-if="slotProps.row.ain_accepted && slotProps.row.ain_removed === null"
					)
						button.alt.btn-s(
							style="width: 100%",
							@click="showOneListing(slotProps.row)"
						)
							span {{ $t("app.companies.viewApplication") }}
				td(v-else, :class="{ bold: slotProps.column.id === 'job_title' }") {{ slotProps.row[slotProps.column.id] }}
</template>

<style lang="scss" scoped>
.page {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
		}
		.wrapp-title {
			width: fit-content;
			white-space: nowrap;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 12px;

			.dropdown {
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
			}
		}

		.statusUnavail {
			width: 92px;
			height: 29px;
			border-radius: 84px;
			border: 1px solid $button-color-blue;
			background: $button-background-blue;
			color: $button-color-blue;
			display: flex;
			justify-content: center;
			align-items: center;

			// font-family: Open Sans;
			font-size: 11px;
			font-weight: 700;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			user-select: none;
		}
		.action {
			width: 230px;
			margin-left: auto;
			// display: flex;
			align-items: center;
			height: 40px !important;
			padding-right: 22px;
			.wrapp-buttons {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}
}
</style>
<style lang="scss">
.page {
	.main {
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
	.search-wrapper {
		// margin-right: 20px;
		width: 20%;
		min-width: 150px !important;
		input {
			min-width: 150px !important;
			max-width: 100% !important;
			width: 100% !important;
		}
	}
}
</style>
