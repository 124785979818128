import { useRoute, useRouter } from "vue-router";
import api from "@/api";

export const useApplicationMessages = defineStore("applicationMessages", () => {
	type TRoom = {
		usr_id: number | null;
		usr_name: string;
		img_id: number | null;
		chat_type: string;
		unread: number;
	};
	const route = useRoute();
	const router = useRouter();

	const rooms = ref<TRoom[]>([]);
	const messages = ref<any[]>([]);
	const selectedRoom = ref<TRoom>(null);
	const isInfiniteScroll = ref(true);

	// functions

	async function getRooms() {
		const params = {
			app_id: route.params.id,
			// limit: 20,
		};
		try {
			const res = await api.getRooms(params);
			// console.log(res.data);
			rooms.value = res.data.data;

			// messages.value.unshift(...res.data.data.reverse());
			// isInfiniteScroll.value = false;
		} catch (e) {
			console.log(e);
		}
	}

	async function getMessages(reset = false, view = "old") {
		isInfiniteScroll.value = false;
		// console.log("dobavi poruke");
		if (!selectedRoom.value) {
			return;
		}
		if (reset) {
			messages.value = [];
			isInfiniteScroll.value = true;
		}
		type TParams = {
			app_id: number;
			// msg_id_last_seen: number | null;
			last_msg_id: number | null;
			usr_id_context: number | null; // b2b-b2b or b2b-aplicant
			room: string;
			view: "new" | "old" | string;
		};
		const params: TParams = {
			app_id: Number(route.params.id),
			last_msg_id:
				view === "old"
					? messages.value.length <= 0
						? null
						: messages.value.at(-1).msg_id
					: messages.value.length <= 0
						? null
						: messages.value.at(0).msg_id,
			usr_id_context: selectedRoom.value.usr_id,
			room: selectedRoom.value.chat_type,
			view: view,
		};

		// console.log(params, "pozovi api ili sockete");
		// messages.value.push(...res.data.data);
		try {
			const res = await api.getMessages(params);
			// console.log(res.data.data);
			// messages.value = res.data.data;
			// messages.value.unshift(...res.data.data.reverse());
			if (view === "old") {
				messages.value.push(...res.data.data.reverse());
			} else if (view === "new") {
				messages.value.unshift(...res.data.data);
				if (res.data.data[0]) await setSeen(res.data.data[0].msg_id);
			}

			if (res.data.data.length > 0) {
				isInfiniteScroll.value = true;
			}
		} catch (e) {
			console.log(e);
		}
	}

	async function selectMessages(e: TRoom) {
		// console.log("set new chat msg", e);
		selectedRoom.value = e;
		await getMessages(true);
		// check this step
		const id = route.params.id;
		router.replace({
			name: "ChatApplication",
			params: { id: id, room: e.chat_type, usr_id: e.usr_id },
		});
		if (e.unread === 1 && messages.value.length > 0) {
			console.log(messages.value[0].msg_id);
			await setSeen(messages.value[0].msg_id);
		}
	}
	async function setSeen(id: number) {
		try {
			const res = await api.appMessagesSeen({ msg_id: id });
			if (res.data?.res === "OK") {
				setLocallySeen();
			}
		} catch (e) {
			console.log(e);
		}
	}
	function setLocallySeen() {
		const index = rooms.value.findIndex(
			(obj) =>
				obj.usr_id === selectedRoom.value?.usr_id &&
				obj.chat_type === selectedRoom.value?.chat_type,
		);
		if (index || index === 0) rooms.value[index].unread = 0;
	}
	function setSelectedRoom(e: TRoom | null) {
		selectedRoom.value = e;
	}

	async function cbInWS(message: any) {
		// const index =  rooms.value.findIndex(
		// 	(obj) => obj.chat_type === message?.room,
		// );
		const index = rooms.value.findIndex(
			(obj) =>
				(message?.room !== "b2b" &&
					message?.room !== "b2b-applicant" &&
					obj.chat_type === message?.room) ||
				(obj.chat_type === message?.room &&
					obj.usr_id === message?.usr_id_context),
		);
		if (selectedRoom?.value.chat_type === message?.room || index === -1) {
			await getMessages(false, "new");
		} else if ((index || index === 0) && index !== -1) {
			rooms.value[index].unread = 1;
		}
		await getMessages(false, "new");
	}

	return {
		// data
		rooms,
		messages,
		isInfiniteScroll,
		selectedRoom,
		// function
		getRooms,
		getMessages,
		selectMessages,
		setSelectedRoom,
		cbInWS,
	};
});
