<template lang="pug">
.cv-page
	.wrapp-cv
		cvTemplate(v-if="cv1", :cv1="cv1")
	.wrapp-funciton
		button(:disabled="load", @click="download")
			span {{ $t("app.cv.download") }}
	.loader-wrap(v-if="load")
		.loader
</template>

<script setup lang="ts">
import jsPDF from "jspdf";
import api from "@/api";
import "@/assets/fonts/archivo-regular-normal.js";
import "@/assets/fonts/archivo-bold.js";

definePage({
	name: "CvPage",
});

// const pageTitle = ref("CV");
const load = ref(false);
async function download() {
	load.value = true;
	let doc = new jsPDF();

	doc = new jsPDF({ orientation: "p", unit: "px", format: [710, 1000] });
	doc.addFont("archivo-regular-normal.ttf", "Archivo", "normal");
	doc.addFont("archivo-bold.ttf", "Archivo", "bold");

	// eslint-disable-next-line unicorn/prefer-query-selector
	const el = document.getElementById("cv-template") as HTMLElement;

	await doc.html(el, {
		callback: function (pdf) {
			pdf.save("CurriculumVitae.pdf");
		},
		x: 0,
		y: 0,
	});
	load.value = false;
}

const cv1 = ref<any>();
async function getCV() {
	try {
		const help = await api.getCV();
		if (help.data.res == "OK") {
			cv1.value = help.data.data;
			// TODO !!Obavezeno promeniti 'as any' u pravilan tip, kada se sredi integracija APIja
			// cv1.value = help.data.data as TCvGet;
		}
		console.log(help.data);
	} catch (e) {
		console.log(e);
	}
}
onMounted(() => {
	void getCV();
});
</script>

<style lang="scss" scoped>
.cv-page {
	position: relative;
	max-width: 1224px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	border-radius: 10px;
	padding: 10px;
	// justify-content: space-between;
	background-color: $bg-color1;

	.wrapp-cv {
		border: $border;
	}
	.wrapp-funciton {
		margin-left: 20px;
	}

	$loading-background: rgba(153, 153, 153, 0.354);
	.loader-wrap {
		position: absolute;
		display: grid;
		width: 100%;
		top: 0;
		left: 0;
		height: 100%;
		place-items: center;
		user-select: none;
		pointer-events: none;
		z-index: 2000;
		background-color: $loading-background;

		.loader {
			width: 48px;
			height: 48px;
			border: 5px solid $app-accent-color1;
			border-bottom-color: transparent;
			border-radius: 50%;
			display: inline-block;
			box-sizing: border-box;
			animation: rotation 1s linear infinite;
		}

		@keyframes rotation {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}
}
</style>
